import * as types from '../actions/types';
import { updateObject } from '../shared/utility';

const initialState = {
  paymentReceipts: {
    paymentReceipts: [],
    current_page: 1,
    per_page: 0,
    total: 0,
    from: 0,
  },
  paymentReceipt: {},
  loading: false,
  error: {},
  changing: 0,
};

const fetchPaymentReceiptStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchPaymentReceiptSuccess = (state, action) => {
  return updateObject(state, {
    paymentReceipts: action.paymentReceipts,
    loading: false
  })
}

const fetchPaymentReceiptFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const showPaymentReceiptStart = (state, action) => {
  return updateObject(state, { 
    paymentReceipt: {},
    loading: true 
  })
}

const showPaymentReceiptSuccess = (state, action) => {
  return updateObject(state, {
    paymentReceipt: action.paymentReceipt,
    loading: false
  })
}

const showPaymentReceiptFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_HISTORY_START: return fetchPaymentReceiptStart(state, action)
    case types.FETCH_PAYMENT_HISTORY_SUCCESS: return fetchPaymentReceiptSuccess(state, action)
    case types.FETCH_PAYMENT_HISTORY_FAIL: return fetchPaymentReceiptFail(state, action)

    case types.SHOW_PAYMENT_HISTORY_START: return showPaymentReceiptStart(state, action)
    case types.SHOW_PAYMENT_HISTORY_SUCCESS: return showPaymentReceiptSuccess(state, action)
    case types.SHOW_PAYMENT_HISTORY_FAIL: return showPaymentReceiptFail(state, action)
    default: return state
  }
}

export default reducer