import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Card, 
    CardContent, 
    CardHeader, 
    Grid, 
    MenuItem, 
    TextField,
    Button,
    Typography,
    Switch,
    InputLabel,
} from '@material-ui/core'
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom'
import Select from 'react-select';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from "yup";

import { AlamatDomisili, Pereferal } from './components'

// Redux
import { connect } from 'react-redux'
import { getProvince, getCities, getDistrict } from '../../../actions/province'
import { addForm } from '../../../actions/form'
import { Skeleton } from '@material-ui/lab';
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    textInput: {
        width: '100%'
    },
    card: {
        marginTop: theme.spacing(2),
        overflow: 'visible'
    },
    searchRoot: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
        padding: 10,
	},
	divider: {
        height: 28,
        margin: 4,
    },
    select: {
        minHeight: 40,
    },
    typography: {
        fontFamily: 'Nunito',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

const Form = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm();
    const { 
        getProvince, 
        getCities, 
        getDistrict, 
        addForm,
        province : { 
            province, 
            city, 
            district, 
            loadingProvince, 
            loadingCity, 
            loadingDistrict 
        } 
    } = props

    const [formState, setFormState] = useState({
        isValid: false,
        values: props.location.state,
        errors: {}
    });

    const [biayaTotal, setBiayaTotal] = useState(0)
    const [biayaDaftar, setBiayaDaftar] = useState(0)
    const [biayaPC, setBiayaPC] = useState(0)

    const handleChangeJenis = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));

        if (event.target.value === 'AOG') {
            setBiayaTotal(3700000);
            setBiayaDaftar(3700000)
        }else{
            setBiayaTotal(500000);
            setBiayaDaftar(500000)
        }

    }

    const handleChangePC = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));

        if (event.target.value === 'belum') {
            setBiayaTotal(biayaTotal + 750000);
            setBiayaPC(750000)
        }else{
            if(biayaTotal !== 3700000){
                setBiayaTotal(biayaTotal - 750000);
                setBiayaPC(0)
            }else{
                setBiayaTotal(biayaTotal);
                setBiayaPC(biayaPC)
            }
        }

    }

    const handleChange = event => {
        event.persist();
    
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: 
                event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
        }));
    };

    const handleChangeBiaya = event => {
        // console.log(event.target.value);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          }
        }));
    };
    
    const onSubmit = e => {
        console.log(formState.values, biayaTotal)
        addForm(formState.values, biayaTotal, history)
    }

    const onProvinceChange = (event) => {
        setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [event.name]: event.value.name
            }
        }));
        getCities(event.value.id)
    }

    const onCityChange = event => {
        setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [event.name]: event.value.name
            }
        }));
        getDistrict(event.value.id)
    }

    const onSelectSearchChange = event => {
        setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [event.name]: event.value
            }
        }));
    }

    const optionsLoading = [{ 'value' : 'loading', 'label' : 'Loading'}];
    const provinceOptions = [];
    if(province != null){
        for (let i = 0; i < province.length; i++) {
            provinceOptions.push({'value' : province[i], 'label' : province[i].name, 'name' : 'provinsi_ktp'});
        }
    }

    const cityOptions = [];
    if(city != null){
        for (let i = 0; i < city.length; i++) {
            cityOptions.push({'value' : city[i], 'label' : city[i].name, 'name' : 'kota_ktp'});
        }
    }

    const districtOptions = [];
    if(district != null){
        for (let i = 0; i < district.length; i++) {
            districtOptions.push({'value' : district[i], 'label' : district[i].name, 'name' : 'kecamatan_ktp'});
        }
    }

    useEffect(() => {
        getProvince()
    }, [getProvince, loadingProvince])

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card className={classes.card}>
                    <CardHeader 
                        title="Biodata Pendaftar"
                    />
                    <CardContent>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Pilih Jenis Pendaftaran"
                                    name="jenis_pendaftaran"
                                    className={classes.textInput}
                                    defaultValue={formState.values.jenis_pendaftaran || ''}
                                    onChange={handleChangeJenis}
                                    variant="filled"
                                    helperText={
                                        errors.jenis_pendaftaran && errors.jenis_pendaftaran.message
                                    }
                                    error={errors.jenis_pendaftaran && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key="AOG" value="AOG">
                                        Agent Of Gold
                                    </MenuItem>
                                    <MenuItem key="MOG" value="MOG">
                                        Member Of Gold
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Nama Lengkap"
                                    className={classes.textInput}
                                    name="nama_lengkap"
                                    defaultValue={formState.values.nama_lengkap || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.nama_lengkap && errors.nama_lengkap.message
                                    }
                                    error={errors.nama_lengkap && true}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Tempat Lahir"
                                    className={classes.textInput}
                                    name="tempat_lahir"
                                    defaultValue={formState.values.tempat_lahir || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.tempat_lahir && errors.tempat_lahir.message
                                    }
                                    error={errors.tempat_lahir && true}
                                    inputRef={register}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    id="date"
                                    label="Tanggal Lahir"
                                    type="date"
                                    name="tanggal_lahir"
                                    // defaultValue="2017-05-24"
                                    inputRef={register}
                                    onChange={handleChange}
                                    className={classes.textInput}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Pilih Jenis Kelamin"
                                    name="jenis_kelamin"
                                    className={classes.textInput}
                                    defaultValue={formState.values.jenis_kelamin || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.jenis_kelamin && errors.jenis_kelamin.message
                                    }
                                    error={errors.jenis_kelamin && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key="Laki-laki" value="Laki-laki">
                                        Laki-laki
                                    </MenuItem>
                                    <MenuItem key="Perempuan" value="Perempuan">
                                        Perempuan
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Nomor KTP"
                                    className={classes.textInput}
                                    name="no_ktp"
                                    defaultValue={formState.values.no_ktp || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.no_ktp && errors.no_ktp.message
                                    }
                                    error={errors.no_ktp && true}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Nomor ID AOG/MOG"
                                    className={classes.textInput}
                                    name="no_id"
                                    defaultValue={formState.values.no_id || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.no_id && errors.no_id.message
                                    }
                                    error={errors.no_id && true}
                                    inputRef={register}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Email AOG/MOG"
                                    className={classes.textInput}
                                    name="email"
                                    defaultValue={formState.values.email || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.email && errors.email.message
                                    }
                                    error={errors.email && true}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <InputLabel>No Whatsapp AOG/MOG</InputLabel>
                                <NumberFormat
                                    {...props}
                                    className={classes.divider}
                                    defaultValue={formState.values.no_wa || ''}
                                    name="no_wa"
                                    format="+62 (###) ####-#####"
                                    customInput={TextField}
                                    type="text"
                                    allowEmptyFormatting
                                    placeholder="No Whatsapp"
                                    // thousandSeparator
                                    getInputRef={register}
                                    onValueChange={({ value: v }) => handleChangeBiaya({ target : { name : 'no_wa', value: parseInt(v)} })}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Nomor Rekening"
                                    className={classes.textInput}
                                    name="nomor_rekening"
                                    defaultValue={formState.values.nomor_rekening || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.nomor_rekening && errors.nomor_rekening.message
                                    }
                                    error={errors.nomor_rekening && true}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Nama Bank"
                                    className={classes.textInput}
                                    name="nama_bank"
                                    defaultValue={formState.values.nama_bank || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.nama_bank && errors.nama_bank.message
                                    }
                                    error={errors.nama_bank && true}
                                    inputRef={register}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                           
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Pendidikan Terakhir"
                                    name="pendidikan_terakhir"
                                    className={classes.textInput}
                                    defaultValue={formState.values.pendidikan_terakhir || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.pendidikan_terakhir && errors.pendidikan_terakhir.message
                                    }
                                    error={errors.pendidikan_terakhir && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key='SD' value="SD">
                                        SD
                                    </MenuItem>
                                    <MenuItem key='SLTP' value="SLTP">
                                        SLTP/SMP
                                    </MenuItem>
                                    <MenuItem key='SMA' value="SMA">
                                        SMA/SMK/Sederajat
                                    </MenuItem>
                                    <MenuItem key='D1' value="D1">
                                        D1
                                    </MenuItem>
                                    <MenuItem key='D2' value="D2">
                                        D2
                                    </MenuItem>
                                    <MenuItem key='D3' value="D3">
                                        D3
                                    </MenuItem>
                                    <MenuItem key='S1' value="S1">
                                        S1
                                    </MenuItem>
                                    <MenuItem key='S2' value="S2">
                                        S2
                                    </MenuItem>
                                    <MenuItem key='S3' value="S3">
                                        S3
                                    </MenuItem>
                                    <MenuItem key='Tidak Sekolah' value="Tidak Sekolah">
                                        Tidak Sekolah
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Status Pernikahan"
                                    name="status_pernikahan"
                                    className={classes.textInput}
                                    defaultValue={formState.values.status_pernikahan || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.status_pernikahan && errors.status_pernikahan.message
                                    }
                                    error={errors.status_pernikahan && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key='Menikah' value="Menikah">
                                        Menikah
                                    </MenuItem>
                                    <MenuItem key='Lajang' value="Lajang">
                                        Belum Menikah
                                    </MenuItem>
                                    <MenuItem key='Duda' value="Duda">
                                        Cerai Hidup
                                    </MenuItem>
                                    <MenuItem key='Janda' value="Janda">
                                        Cerai Mati
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Pekerjaan"
                                    name="pekerjaan"
                                    className={classes.textInput}
                                    defaultValue={formState.values.pekerjaan || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.pekerjaan && errors.pekerjaan.message
                                    }
                                    error={errors.pekerjaan && true}
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Status Kepemilikan Rumah"
                                    name="status_kepemilikan_rumah"
                                    className={classes.textInput}
                                    defaultValue={formState.values.status_kepemilikan_rumah || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.status_kepemilikan_rumah && errors.status_kepemilikan_rumah.message
                                    }
                                    error={errors.status_kepemilikan_rumah && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key="milik_sendiri" value="milik_sendiri">
                                        Milik Sendiri
                                    </MenuItem>
                                    <MenuItem key="sewa" value="sewa">
                                        Sewa
                                    </MenuItem>
                                    <MenuItem key="menumpang" value="menumpang">
                                        Menumpang
                                    </MenuItem>
                                    <MenuItem key="ikut_orang_tua" value="menumpang">
                                        Ikut Orang Tua
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Kepemilikan NPWP"
                                    name="kepemilikan_npwp"
                                    className={classes.textInput}
                                    defaultValue={formState.values.kepemilikan_npwp || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.kepemilikan_npwp && errors.kepemilikan_npwp.message
                                    }
                                    error={errors.kepemilikan_npwp && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key='Ya' value="Ya">
                                        Ya
                                    </MenuItem>
                                    <MenuItem key='Tidak' value="Tidak">
                                        Tidak
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            {formState.values.kepemilikan_npwp && (
                                <>
                                    {formState.values.kepemilikan_npwp === "Ya" && (
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <TextField 
                                                label="Nomor NPWP"
                                                name="no_npwp"
                                                className={classes.textInput}
                                                defaultValue={formState.values.no_npwp || ''}
                                                onChange={handleChange}
                                                variant="filled"
                                                helperText={
                                                    errors.no_npwp && errors.no_npwp.message
                                                }
                                                error={errors.no_npwp && true}
                                                inputRef={register}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                        
                        <Grid
                            container
                            spacing={2}
                        >
                            {formState.values.jenis_pendaftaran === "AOG" && (
                                <>
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <TextField 
                                        label="Apakah Sudah Mengikuti Private Class ?"
                                        name="apakah_sudah_mengikuti_private_class"
                                        className={classes.textInput}
                                        defaultValue={formState.values.apakah_sudah_mengikuti_private_class || ''}
                                        onChange={handleChangePC}
                                        variant="filled"
                                        helperText={
                                            errors.apakah_sudah_mengikuti_private_class && errors.apakah_sudah_mengikuti_private_class.message
                                        }
                                        error={errors.apakah_sudah_mengikuti_private_class && true}
                                        inputRef={register}
                                        select
                                    >
                                        <MenuItem key='sudah' value="sudah">
                                            Sudah
                                        </MenuItem>
                                        <MenuItem key='belum' value="belum">
                                            Belum
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                {formState.values.apakah_sudah_mengikuti_private_class === "sudah" && (
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <TextField 
                                            label="Nomor Alumni"
                                            name="id_alumni"
                                            className={classes.textInput}
                                            defaultValue={formState.values.id_alumni || ''}
                                            onChange={handleChange}
                                            variant="filled"
                                            helperText={
                                                errors.id_alumni && errors.id_alumni.message
                                            }
                                            error={errors.id_alumni && true}
                                            inputRef={register}
                                        />
                                    </Grid>
                                )}
                                </>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Apakah Anggota Koperasi EOA Club ?"
                                    name="apakah_anggota_koperasi"
                                    className={classes.textInput}
                                    defaultValue={formState.values.apakah_anggota_koperasi || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.apakah_anggota_koperasi && errors.apakah_anggota_koperasi.message
                                    }
                                    error={errors.apakah_anggota_koperasi && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key='Ya' value="Ya">
                                        Ya
                                    </MenuItem>
                                    <MenuItem key='Tidak' value="Tidak">
                                        Tidak
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Agama"
                                    name="agama"
                                    className={classes.textInput}
                                    defaultValue={formState.values.agama || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.agama && errors.agama.message
                                    }
                                    error={errors.agama && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem key='Islam' value="Islam">
                                        Islam
                                    </MenuItem>
                                    <MenuItem key='Katholik' value="Katholik">
                                        Katholik
                                    </MenuItem>
                                    <MenuItem key='Protestan' value="Protestan">
                                        Protestan
                                    </MenuItem>
                                    <MenuItem key='Hindu' value="Hindu">
                                        Hindu
                                    </MenuItem>
                                    <MenuItem key='Buddha' value="Buddha">
                                        Buddha
                                    </MenuItem>
                                    <MenuItem key='Lainnya' value="Lainnya">
                                        Lainnya
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardHeader title="Alamat KTP" />
                    <CardContent>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <TextField 
                                    label="Alamat Lengkap (Sesuai KTP)"
                                    className={classes.textInput}
                                    name="alamat_ktp"
                                    defaultValue={formState.values.alamat_ktp || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.alamat_ktp && errors.alamat_ktp.message
                                    }
                                    error={errors.alamat_ktp && true}
                                    inputRef={register}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                {!loadingProvince || province != null ? (
                                    <>
                                        {/* <InputLabel>Pilih Provinsi</InputLabel> */}
                                        <Select 
                                            className={classes.select} 
                                            options={provinceOptions} 
                                            onChange={onProvinceChange} 
                                            placeholder="Pilih Provinsi"
                                        />
                                    </>
                                ):(
                                    <Skeleton variant="rect"></Skeleton>
                                )}
                            </Grid>
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                {!loadingCity || city != null ? (
                                    <Select 
                                        className={classes.select} 
                                        options={cityOptions} 
                                        onChange={onCityChange} 
                                        placeholder="Pilih Kota/Kabupaten"
                                    />
                                ):(
                                    <Select 
                                        disabled
                                        className={classes.select} 
                                        options={optionsLoading} 
                                        onChange={onCityChange} 
                                        placeholder="Pilih Kota/Kabupaten"
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid 
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                {!loadingDistrict || district != null ? (
                                    <Select 
                                        className={classes.select} 
                                        options={districtOptions} 
                                        onChange={onSelectSearchChange} 
                                        placeholder="Pilih Kecamatan"
                                    />
                                ):(
                                    <Select 
                                        disabled
                                        className={classes.select} 
                                        options={optionsLoading} 
                                        onChange={onSelectSearchChange} 
                                        placeholder="Pilih Kecamatan"
                                    />
                                )}
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Kode Pos"
                                    className={classes.textInput}
                                    name="kode_pos_ktp"
                                    defaultValue={formState.values.kode_pos_ktp || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.kode_pos_ktp && errors.kode_pos_ktp.message
                                    }
                                    error={errors.alamat && true}
                                    inputRef={register}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={2}>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <TextField 
                                    label="Negara"
                                    className={classes.textInput}
                                    name="negara_ktp"
                                    defaultValue={formState.values.negara_ktp || ''}
                                    onChange={handleChange}
                                    variant="filled"
                                    helperText={
                                        errors.negara_ktp && errors.negara_ktp.message
                                    }
                                    error={errors.alamat && true}
                                    inputRef={register}
                                    select
                                >
                                    <MenuItem value="indonesia">
                                        Indonesia
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid> */}
                    </CardContent>
                </Card>

                <Typography className={classes.card}>
                    Apakah Alamat sekarang sama dengan alamat KTP?
                    <Switch
                        checked={formState.values.checked}
                        onChange={handleChange}
                        name="checked"
                        color="primary"
                    />
                </Typography>
                {!formState.values.checked && (
                    <AlamatDomisili 
                        formState={formState}
                        setFormState={setFormState}
                        handleChange={handleChange}
                        errors={errors}
                        register={register}
                    />
                )}
                
                <Pereferal 
                    register={register}
                    formState={formState}
                    errors={errors}
                    onSelectSearchChange={onSelectSearchChange}
                    handleChangeBiaya={handleChangeBiaya}
                    handleChange={handleChange}
                />
                {/* <Card className={classes.card}>
                    <CardHeader title="Total Yang Harus Dibayar" />
                    <CardContent>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <Paper component="form" className={classes.searchRoot}>
                                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                        <Typography variant="subtitle2">Rp</Typography>
                                    </IconButton>
                                    <Divider className={classes.divider} orientation="vertical" />
                                    <NumberFormat
                                        {...props}
                                        value={biayaTotal}
                                        name="biaya_total"
                                        disabled
                                        customInput={TextField}
                                        type="text"
                                        thousandSeparator
                                        getInputRef={register}
                                        onValueChange={({ value: v }) => handleChangeBiaya({ target : { name : 'biaya_total', value: parseInt(v)} })}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card> */}

                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                    >
                        <Typography variant="h3" className={classes.typography}>
                            Rincian Pembayaran
                        </Typography>
                        <Grid
                            container
                            justify="space-between"
                        >
                            <Grid item>
                                <Typography className={classes.typography}>
                                    Biaya Pendaftaran
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.typography}>
                                    <NumberFormat value={biayaDaftar} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justify="space-between"
                        >
                            <Grid item>
                                <Typography className={classes.typography}>
                                    Biaya Private Class
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.typography}>
                                    <NumberFormat value={biayaPC} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid
                            container
                            justify="space-between"
                        >
                            <Grid item>
                                <Typography className={classes.typography}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.typography}>
                                    <NumberFormat value={biayaTotal} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Button variant="contained" color="primary" type="submit" fullWidth>Simpan</Button>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    province: state.province
})

export default connect(mapStateToProps, { getProvince, getCities, getDistrict, addForm })(Form)