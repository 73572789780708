import * as types from './types';
import axios from '../axios-orders';
import { setAlert } from './alert';

export const fetchStatisticMembershipStart = () => {
  return {
    type: types.GET_STATISTIC_MEMBER_START
  }
}

export const fetchStatisticMembershipSuccess = (counts) => {
  return {
    type: types.GET_STATISTIC_MEMBER,
    countStatistic: counts
  }
}

export const fetchStatisticMembershipFail = (error) => {
  return {
    type: types.GET_STATISTIC_MEMBER_FAIL,
    error: error
  }
}

// V3
export const fetchStatisticV3MembershipStart = () => {
  return {
    type: types.GET_STATISTIC_V3_MEMBER_START
  }
}

export const fetchStatisticV3MembershipSuccess = (counts) => {
  return {
    type: types.GET_STATISTIC_V3_MEMBER,
    countStatisticV3: counts
  }
}

export const fetchStatisticV3MembershipFail = (error) => {
  return {
    type: types.GET_STATISTIC_V3_MEMBER_FAIL,
    error: error
  }
}

// Grafik Monthly
export const fetchGrafikMonthlyStatisticMembershipStart = () => {
  return {
    type: types.GET_STATISTIC_GRAFIK_MONTHLY_START
  }
}

export const fetchGrafikMonthlyStatisticMembershipSuccess = (counts) => {
  return {
    type: types.GET_STATISTIC_GRAFIK_MONTHLY_SUCCESS,
    grafikMonthlyStatistic: counts
  }
}

export const fetchGrafikMonthlyStatisticMembershipFail = (error) => {
  return {
    type: types.GET_STATISTIC_GRAFIK_MONTHLY_FAIL,
    error: error
  }
}

// Grafik Yearly
export const fetchGrafikYearlyStatisticMembershipStart = () => {
  return {
    type: types.GET_STATISTIC_GRAFIK_YEARLY_START
  }
}

export const fetchGrafikYearlyStatisticMembershipSuccess = (counts) => {
  return {
    type: types.GET_STATISTIC_GRAFIK_YEARLY_SUCCESS,
    grafikYearlyStatistic: counts
  }
}

export const fetchGrafikYearlyStatisticMembershipFail = (error) => {
  return {
    type: types.GET_STATISTIC_GRAFIK_YEARLY_FAIL,
    error: error
  }
}

// Get Input Target Registration Active
export const fetchInputTargetActiveStart = () => {
  return {
    type: types.GET_INPUT_TARGET_ACTIVE_START
  }
}

export const fetchInputTargetActiveSuccess = (counts) => {
  return {
    type: types.GET_INPUT_TARGET_ACTIVE_SUCCESS,
    inputTargetActive: counts
  }
}

export const fetchInputTargetActiveFail = (error) => {
  return {
    type: types.GET_INPUT_TARGET_ACTIVE_FAIL,
    error: error
  }
}

// Get Input Target Upgrade Active
export const fetchInputTargetUpgradeActiveStart = () => {
  return {
    type: types.GET_INPUT_TARGET_UPGRADE_ACTIVE_START
  }
}

export const fetchInputTargetUpgradeActiveSuccess = (counts) => {
  return {
    type: types.GET_INPUT_TARGET_UPGRADE_ACTIVE_SUCCESS,
    inputTargetUpgradeActive: counts
  }
}

export const fetchInputTargetUpgradeActiveFail = (error) => {
  return {
    type: types.GET_INPUT_TARGET_UPGRADE_ACTIVE_FAIL,
    error: error
  }
}

// Get Statistic PPA
export const fetchStatisticPPAStart = () => {
  return {
    type: types.GET_STATISTIC_PPA_START
  }
}

export const fetchStatisticPPASuccess = (counts) => {
  return {
    type: types.GET_STATISTIC_PPA_SUCCESS,
    statisticPPA: counts
  }
}

export const fetchStatisticPPAFail = (error) => {
  return {
    type: types.GET_STATISTIC_PPA_FAIL,
    error: error
  }
}

export const fetchStatisticPPA = () => {
  //   let param = 'start_date='+formSearch.start_date+'&end_date='+formSearch.end_date;
  
    return dispatch => {
      dispatch(fetchStatisticPPAStart())
      axios.get('user/count_status_ppa_aog_mog?', {
        headers: {
          'Content-Type' : 'application/json',
          Accept : 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
        .then(res => {
          dispatch(fetchStatisticPPASuccess(res.data))
        })
        .catch(err => {
          dispatch(fetchStatisticPPAFail(err))
        })
    }
  }

export const fetchStatisticMembership = () => {
//   let param = 'start_date='+formSearch.start_date+'&end_date='+formSearch.end_date;

  return dispatch => {
    dispatch(fetchStatisticMembershipStart())
    axios.get('user/dashboard_v2?', {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchStatisticMembershipSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchStatisticMembershipFail(err))
      })
  }
}

export const fetchStatisticV3Membership = (month, id_branch) => {
  let param = 'month='+month+'&id_branch='+id_branch;
  return dispatch => {
    dispatch(fetchStatisticV3MembershipStart())
    axios.get('user/dashboard_v3?' + param, {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchStatisticV3MembershipSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchStatisticV3MembershipFail(err))
      })
  }
}

export const fetchGrafikMonthlyStatisticMembership = (month, year, id_branch) => {
  let param = 'month='+month+'&year='+year+'&id_branch='+id_branch;
  return dispatch => {
    dispatch(fetchGrafikMonthlyStatisticMembershipStart())
    axios.get('user/grafik_aog_mog_perhari?' + param, {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchGrafikMonthlyStatisticMembershipSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGrafikMonthlyStatisticMembershipFail(err))
      })
  }
}

export const fetchGrafikYearlyStatisticMembership = ( year, id_branch) => {
  let param = 'year='+year+'&id_branch='+id_branch;
  return dispatch => {
    dispatch(fetchGrafikYearlyStatisticMembershipStart())
    axios.get('user/grafik_aog_mog_perbulan?' + param, {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchGrafikYearlyStatisticMembershipSuccess(res.data))
        console.log(res.data)
      })
      .catch(err => {
        dispatch(fetchGrafikYearlyStatisticMembershipFail(err))
      })
  }
}

export const fetchInputTargetActive = (setFormState, year) => {
  let param = 'name='+year
  return dispatch => {
    dispatch(fetchInputTargetActiveStart())
    axios.get('user/target/aktif?' + param, {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchInputTargetActiveSuccess(res.data))
        setFormState({
          values: {
            AOG_1: res.data.AOG_01,
            AOG_2: res.data.AOG_02,
            AOG_3: res.data.AOG_03,
            AOG_4: res.data.AOG_04,
            AOG_5: res.data.AOG_05,
            AOG_6: res.data.AOG_06,
            AOG_7: res.data.AOG_07,
            AOG_8: res.data.AOG_08,
            AOG_9: res.data.AOG_09,
            AOG_10: res.data.AOG_10,
            AOG_11: res.data.AOG_11,
            AOG_12: res.data.AOG_12,
            MOG_1: res.data.MOG_01,
            MOG_2: res.data.MOG_02,
            MOG_3: res.data.MOG_03,
            MOG_4: res.data.MOG_04,
            MOG_5: res.data.MOG_05,
            MOG_6: res.data.MOG_06,
            MOG_7: res.data.MOG_07,
            MOG_8: res.data.MOG_08,
            MOG_9: res.data.MOG_09,
            MOG_10: res.data.MOG_10,
            MOG_11: res.data.MOG_11,
            MOG_12: res.data.MOG_12,
          }
        })
      })
      .catch(err => {
        dispatch(fetchInputTargetActiveFail(err))
      })
  }
}

export const fetchInputTargetUpgradeActive = (setFormStateUpgrade, year) => {
  let param = 'name='+year
  return dispatch => {
    dispatch(fetchInputTargetUpgradeActiveStart())
    axios.get('user/target_upgrade/aktif?'+param, {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchInputTargetUpgradeActiveSuccess(res.data))
        setFormStateUpgrade({
          values: {
            AOG_1: res.data.AOG_01,
            AOG_2: res.data.AOG_02,
            AOG_3: res.data.AOG_03,
            AOG_4: res.data.AOG_04,
            AOG_5: res.data.AOG_05,
            AOG_6: res.data.AOG_06,
            AOG_7: res.data.AOG_07,
            AOG_8: res.data.AOG_08,
            AOG_9: res.data.AOG_09,
            AOG_10: res.data.AOG_10,
            AOG_11: res.data.AOG_11,
            AOG_12: res.data.AOG_12,
            MOG_1: res.data.MOG_01,
            MOG_2: res.data.MOG_02,
            MOG_3: res.data.MOG_03,
            MOG_4: res.data.MOG_04,
            MOG_5: res.data.MOG_05,
            MOG_6: res.data.MOG_06,
            MOG_7: res.data.MOG_07,
            MOG_8: res.data.MOG_08,
            MOG_9: res.data.MOG_09,
            MOG_10: res.data.MOG_10,
            MOG_11: res.data.MOG_11,
            MOG_12: res.data.MOG_12,
          }
        })
      })
      .catch(err => {
        dispatch(fetchInputTargetUpgradeActiveFail(err))
      })
  }
}

export const addingInputTargetActive = (formData, setFormState, year) => {
  // let param = 'year='+year+'&id_branch='+id_branch;
  return dispatch => {
    const storeData = {
      name : year,
      AOG_01: formData.AOG_1,
      AOG_02: formData.AOG_2,
      AOG_03: formData.AOG_3,
      AOG_04: formData.AOG_4,
      AOG_05: formData.AOG_5,
      AOG_06: formData.AOG_6,
      AOG_07: formData.AOG_7,
      AOG_08: formData.AOG_8,
      AOG_09: formData.AOG_9,
      AOG_10: formData.AOG_10,
      AOG_11: formData.AOG_11,
      AOG_12: formData.AOG_12,
      MOG_01: formData.MOG_1,
      MOG_02: formData.MOG_2,
      MOG_03: formData.MOG_3,
      MOG_04: formData.MOG_4,
      MOG_05: formData.MOG_5,
      MOG_06: formData.MOG_6,
      MOG_07: formData.MOG_7,
      MOG_08: formData.MOG_8,
      MOG_09: formData.MOG_9,
      MOG_10: formData.MOG_10,
      MOG_11: formData.MOG_11,
      MOG_12: formData.MOG_12,
    }
    dispatch(fetchInputTargetActiveStart())
    axios.post('user/target', storeData, {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchInputTargetActiveSuccess(res.data))
        dispatch(fetchInputTargetActive(setFormState))
        dispatch(setAlert('Target Updated', 'success'))
      })
      .catch(err => {
        dispatch(fetchInputTargetActiveFail(err))
        dispatch(setAlert('Something went error', 'error'))
      })
  }
}

export const addingInputTargetUpgradeActive = (formData, setFormStateUpgrade, year) => {
  // let param = 'year='+year+'&id_branch='+id_branch;
  return dispatch => {
    const storeData = {
      name: year,
      AOG_01: formData.AOG_1,
      AOG_02: formData.AOG_2,
      AOG_03: formData.AOG_3,
      AOG_04: formData.AOG_4,
      AOG_05: formData.AOG_5,
      AOG_06: formData.AOG_6,
      AOG_07: formData.AOG_7,
      AOG_08: formData.AOG_8,
      AOG_09: formData.AOG_9,
      AOG_10: formData.AOG_10,
      AOG_11: formData.AOG_11,
      AOG_12: formData.AOG_12,
      MOG_01: formData.MOG_1,
      MOG_02: formData.MOG_2,
      MOG_03: formData.MOG_3,
      MOG_04: formData.MOG_4,
      MOG_05: formData.MOG_5,
      MOG_06: formData.MOG_6,
      MOG_07: formData.MOG_7,
      MOG_08: formData.MOG_8,
      MOG_09: formData.MOG_9,
      MOG_10: formData.MOG_10,
      MOG_11: formData.MOG_11,
      MOG_12: formData.MOG_12,
    }
    dispatch(fetchInputTargetUpgradeActiveStart())
    axios.post('user/target_upgrade', storeData, {
      headers: {
        'Content-Type' : 'application/json',
        Accept : 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchInputTargetUpgradeActiveSuccess(res.data))
        dispatch(fetchInputTargetUpgradeActive(setFormStateUpgrade))
        dispatch(setAlert('Target Updated', 'success'))
      })
      .catch(err => {
        dispatch(fetchInputTargetUpgradeActiveFail(err))
        dispatch(setAlert('Something went error', 'error'))
      })
  }
}

// Get Branch Statistic
export const fetchStatisticBranchStart = () => {
  return {
    type: types.GET_STATISTIC_BRANCH_START
  }
}

export const fetchStatisticBranchSuccess = (counts) => {
  return {
    type: types.GET_STATISTIC_BRANCH_SUCCESS,
    statisticBranch: counts
  }
}

export const fetchStatisticBranchFail = (error) => {
  return {
    type: types.GET_STATISTIC_BRANCH_FAIL,
    error: error
  }
}

export const fetchStatisticBranch = (page, search) => {
    let param = 'kata_kunci='+search+'&page='+page;
  
    return dispatch => {
      dispatch(fetchStatisticBranchStart())
      axios.get('user/search_branch_active?' + param, {
        headers: {
          'Content-Type' : 'application/json',
          Accept : 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
        }
      })
        .then(res => {
          dispatch(fetchStatisticBranchSuccess(res.data))
        })
        .catch(err => {
          dispatch(fetchStatisticBranchFail(err))
        })
    }
  }