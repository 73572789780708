import { Grid, makeStyles, Paper, Table, TableBody, TableSortLabel, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import RegisterAogMog from './RegisterAogMog/RegisterAogMog';
import TablePaginationsArrayActions from '../../../../../../components/UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute',
    // zIndex: 0
  },
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  searchSelectRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    borderRadius: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const columns = [
  { id: 'tanggal_pendaftaran', label: 'Tanggal Pendaftaran' },
  { id: 'nama_lengkap', label: 'Nama Lengkap' },
  { id: 'jenis_pendaftaran', label: 'Jenis Pendaftaran' },
  { id: 'cabang_pembina', label: 'Cabang Pembina' },
  { id: 'nama_pereferal', label: 'Nama Pereferal' },
  { id: 'total_biaya', label: 'Total Biaya' },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align='left'
            padding='default'
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ShowPaymentReceipt = props => {
  const { paymentReceiptShow } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  function isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  

  let tableBody = '';
  if(!isEmpty(paymentReceiptShow)){
    console.log(paymentReceiptShow.register_aog_mog.length)
    const countRows = paymentReceiptShow.register_aog_mog.length;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage);
    tableBody = (   
      <Fragment>
        <TableBody>
          {/* {paymentReceiptShow.register_aog_mog.map((registerAogMog, index) => (
            <RegisterAogMog
              registerAogMog={registerAogMog}
              key={registerAogMog.id}
              index={index}
            />
          )).reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
          {stableSort(paymentReceiptShow.register_aog_mog, getComparator(order, orderBy))
            .map((registerAogMog, index) => (
              <RegisterAogMog
                registerAogMog={registerAogMog}
                key={registerAogMog.id}
                index={index}
              />
            )).reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          }
          {/* } */}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={5}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationsArrayActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }
  
  return (
    <Fragment>
      {/* <div className={classes.root}> */}
      <div className={classes.bgColor}></div>
      
      <Grid container spacing={2}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            {/* <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead> */}
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={paymentReceiptShow.register_aog_mog.length}
            />
            {tableBody}
          </Table>
        </TableContainer>
      </Grid>
      {/* </div> */}
    </Fragment>
  );
};

export default ShowPaymentReceipt;