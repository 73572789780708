/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  Box,
} from "@material-ui/core";
import { Pagination, Autocomplete } from "@material-ui/lab";

import AddIcon from "@material-ui/icons/Add";

import { getBranch } from "../../actions/branch";
import { getListGenerateID, requestGenerateId, assignId } from "../../actions";
import Loading from "../../components/UI/Loading/Loading";
import { Table, DialogGenerateID, DialogPilihCabang } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  containField: {
    marginTop: theme.spacing(4),
  },
  btnBranch: {
    backgroundColor: "#CDD8FF",
    color: "#0D298F",
    marginRight: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: "#CDD8FF",
      color: "#0D298F",
    },
  },
  boxField: {
    padding: theme.spacing(0, 1),
  },
  field: {
    marginTop: theme.spacing(1),
    "& .MuiFilledInput-root": {
      backgroundColor: "#fff",
    },
  },
  containTable: {
    marginTop: theme.spacing(4),
    backgroundColor: "#fff",
    padding: theme.spacing(5),
  },
  tableTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  boxTable: {
    marginTop: theme.spacing(2),
  },
  boxPagination: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const GenerateID = ({
  loading,
  getBranch,
  branch,
  generateID,
  getListGenerateID,
  requestGenerateId,
  assignId,
}) => {
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [request, setRequest] = useState(50);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("asc");
  const [branchID, setBranchID] = useState(null);
  const [inputBranch, setInputBranch] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCabang, setOpenDialogCabang] = useState(false);

  const handlePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getBranch();
  }, [getBranch]);

  useEffect(() => {
    setSelected([]);
    setPage(1);
    getListGenerateID(type, status, page, request, sort, branchID, search);
  }, [type, getListGenerateID, status, request, sort, branchID, search]);

  useEffect(() => {
    setSelected([]);
    getListGenerateID(type, status, page, request, sort, branchID);
  }, [page]);

  return loading ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <div>
        <Typography variant="h4" onClick={() => console.log(selected)}>
          Generate ID AOG MOG
        </Typography>
      </div>
      <Grid container className={classes.containField}>
        <Grid item md={3} className={classes.boxField}>
          <Typography variant="body1">Tipe</Typography>
          <TextField
            select
            fullWidth
            variant="filled"
            className={classes.field}
            defaultValue="default"
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="default" disabled>
              Pilih Tipe
            </MenuItem>
            <MenuItem value="aog">AOG</MenuItem>
            <MenuItem value="mog">MOG</MenuItem>
          </TextField>
        </Grid>
        <Grid item md={3} className={classes.boxField}>
          <Typography variant="body1">Status</Typography>
          <TextField
            select
            fullWidth
            variant="filled"
            className={classes.field}
            defaultValue="default"
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="default" disabled>
              Pilih Status
            </MenuItem>
            <MenuItem value={0}>Tersedia</MenuItem>
            <MenuItem value={1}>Sudah Digunakan</MenuItem>
          </TextField>
        </Grid>
        <Grid item md={3} className={classes.boxField}>
          <Typography variant="body1">Jumlah Permintaan</Typography>
          <TextField
            fullWidth
            variant="filled"
            className={classes.field}
            placeholder="Masukkan Angka"
            onChange={(e) => setRequest(e.target.value)}
          />
        </Grid>
        <Grid item md={3} className={classes.boxField}>
          <Typography variant="body1">Cabang</Typography>
          <Autocomplete
            value={branchID}
            onChange={(event, newValue) => {
              setBranchID(newValue);
            }}
            inputValue={inputBranch}
            onInputChange={(event, newInputValue) => {
              setInputBranch(newInputValue);
            }}
            options={branch.branch}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                placeholder="Pilih Cabang"
                className={classes.field}
              />
            )}
          />
        </Grid>
        <Grid item md={3} className={classes.boxField}>
          <Box paddingTop={1}>
            <Typography variant="body1">Search ID AOG MOG</Typography>
            <TextField
              fullWidth
              variant="filled"
              className={classes.field}
              placeholder="Cari ID AOG MOG"
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </Box>
        </Grid>
      </Grid>
      <div className={classes.containTable}>
        <div className={classes.tableTitle}>
          <Typography variant="h4">{`List Generate (Total = ${generateID.list.dataLength})`}</Typography>
          <div>
            <Button
              className={classes.btnBranch}
              variant="contained"
              color="primary"
              onClick={() => setOpenDialogCabang(true)}
              disabled={selected.length === 0}
            >
              Pilih Cabang
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenDialog(true)}
            >
              ID Aog Mog
            </Button>
          </div>
        </div>
        <div className={classes.boxTable}>
          <Table
            data={generateID}
            selected={selected}
            setSelected={setSelected}
            sort={sort}
            setSort={setSort}
          />
        </div>
        <div className={classes.boxPagination}>
          <Pagination
            color="primary"
            count={generateID.list.lastPage}
            page={page}
            onChange={handlePage}
          />
        </div>
      </div>
      <DialogGenerateID
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        action={requestGenerateId}
        setSelected={setSelected}
      />
      <DialogPilihCabang
        branch={branch.branch}
        open={openDialogCabang}
        onClose={() => setOpenDialogCabang(false)}
        action={assignId}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branch: state.branch,
    generateID: state.generateID,
  };
};

export default connect(mapStateToProps, {
  getBranch,
  getListGenerateID,
  requestGenerateId,
  assignId,
})(GenerateID);
