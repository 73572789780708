
import axios from 'axios'
import * as types from '../actions/types';
import { setAlert } from './alert'
import { 
    POST_FORM_END,
    POST_FORM_START,
    GET_FORM,
    POST_UPLOAD_BUKTI_START,
    POST_UPLOAD_BUKTI_END,
    GET_LIMIT
} from './types'


export const addForm = (formData, startDate, biayaTotal, history) => async dispatch => {
    dispatch({
        type: POST_FORM_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/register_aog_mog`

    var myData = null

    if(!formData.checked){
        myData = {
            alamat_domisili: formData.alamat_domisili,
            alamat_ktp: formData.alamat_ktp,
            apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
            apakah_sudah_mengikuti_private_class: formData.apakah_sudah_mengikuti_private_class,
            biaya_total: biayaTotal,
            cabang_pembina: formData.cabang_pembina,
            email: formData.email,
            id_alumni: formData.id_alumni,
            jenis_kelamin: formData.jenis_kelamin,
            jenis_pendaftaran: formData.jenis_pendaftaran,
            kecamatan_domisili: formData.kecamatan_domisili,
            kecamatan_ktp: formData.kecamatan_ktp,
            kelurahan_ktp: formData.kelurahan_ktp,
            kelurahan_domisili: formData.kelurahan_domisili,
            kepemilikan_npwp: formData.kepemilikan_npwp,
            kode_pos_domisili: formData.kode_pos_domisili,
            kode_pos_ktp: formData.kode_pos_ktp,
            kota_domisili: formData.kota_domisili,
            kota_ktp: formData.kota_ktp,
            nama_bank: formData.nama_bank,
            nama_bank_pereferal: formData.nama_bank_pereferal,
            nama_lengkap: formData.nama_lengkap,
            nama_pereferal: formData.nama_pereferal,
            negara_domisili: 'Indonesia',
            negara_ktp: 'Indonesia',
            no_ba: formData.no_ba,
            no_id: formData.no_id,
            no_id_pereferal: formData.no_id_pereferal,
            no_ktp: formData.no_ktp,
            no_npwp: formData.no_npwp,
            no_rek_pereferal: formData.no_rek_pereferal,
            no_wa: formData.no_wa,
            no_wa_pereferal: formData.no_wa_pereferal,
            nomor_rekening: formData.nomor_rekening,
            pekerjaan: formData.pekerjaan,
            pendidikan_terakhir: formData.pendidikan_terakhir,
            pengiriman_paket: formData.pengiriman_paket,
            provinsi_domisili: formData.provinsi_domisili,
            provinsi_ktp: formData.provinsi_ktp,
            status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
            status_pereferal: formData.status_pereferal,
            status_pernikahan: formData.status_pernikahan,
            tanggal_lahir: startDate.submit.submit,
            tempat_lahir: formData.tempat_lahir,
            agama: formData.agama
        }
    }else{
        myData = {
            alamat_domisili: formData.alamat_ktp,
            alamat_ktp: formData.alamat_ktp,
            apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
            apakah_sudah_mengikuti_private_class: formData.apakah_sudah_mengikuti_private_class,
            biaya_total: biayaTotal,
            cabang_pembina: formData.cabang_pembina,
            email: formData.email,
            id_alumni: formData.id_alumni,
            jenis_kelamin: formData.jenis_kelamin,
            jenis_pendaftaran: formData.jenis_pendaftaran,
            kecamatan_domisili: formData.kecamatan_ktp,
            kecamatan_ktp: formData.kecamatan_ktp,
            kelurahan_ktp: formData.kelurahan_ktp,
            kelurahan_domisili: formData.kelurahan_ktp,
            kepemilikan_npwp: formData.kepemilikan_npwp,
            kode_pos_domisili: formData.kode_pos_ktp,
            kode_pos_ktp: formData.kode_pos_ktp,
            kota_domisili: formData.kota_ktp,
            kota_ktp: formData.kota_ktp,
            nama_bank: formData.nama_bank,
            nama_bank_pereferal: formData.nama_bank_pereferal,
            nama_lengkap: formData.nama_lengkap,
            nama_pereferal: formData.nama_pereferal,
            negara_domisili: 'Indonesia',
            negara_ktp: 'Indonesia',
            no_ba: formData.no_ba,
            no_id: formData.no_id,
            no_id_pereferal: formData.no_id_pereferal,
            no_ktp: formData.no_ktp,
            no_npwp: formData.no_npwp,
            no_rek_pereferal: formData.no_rek_pereferal,
            no_wa: formData.no_wa,
            no_wa_pereferal: formData.no_wa_pereferal,
            nomor_rekening: formData.nomor_rekening,
            pekerjaan: formData.pekerjaan,
            pendidikan_terakhir: formData.pendidikan_terakhir,
            pengiriman_paket: formData.pengiriman_paket,
            provinsi_domisili: formData.provinsi_ktp,
            provinsi_ktp: formData.provinsi_ktp,
            status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
            status_pereferal: formData.status_pereferal,
            status_pernikahan: formData.status_pernikahan,
            tanggal_lahir: startDate.submit.submit,
            tempat_lahir: formData.tempat_lahir,
            agama: formData.agama
        }
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: POST_FORM_END,
            payload: res.data
        })

        dispatch(setAlert("AOG/MOG Added", "success"))
        history.push(`/registration-form`);
    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        dispatch({
            type: POST_FORM_END,
            payload: error
        })
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getForm = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/register_aog_mog/index`
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: GET_FORM,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_FORM,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const uploadBukti = (base64, item, amount, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/register_aog_mog/upload_bukti_pembayaran`

    const myData = {
        image: base64,
        id_form : item,
        amount : amount
    }

    dispatch({
        type: POST_UPLOAD_BUKTI_START,
    })

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: POST_UPLOAD_BUKTI_END,
            payload: res.data
        })
        dispatch(getForm())

        dispatch(setAlert("Berhasil Upload Bukti Bayar", "success"))
        history.push(`/registration-form`);
    } catch (error) {
        dispatch(setAlert(error.response.data.message, "error"))
        dispatch({
            type: POST_UPLOAD_BUKTI_END,
            payload: error
        })
        // console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getLimitUploadBukti = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/payment_receipt/cek_status`
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: GET_LIMIT,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_LIMIT,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}
export const showFormStart = () => {
    return {
        type: types.SHOW_FORM_START
    }
}

export const showFormSuccess = (formDetail) => {
    return {
        type: types.SHOW_FORM_SUCCESS,
        formDetail: formDetail
    }
}

export const showFormFail = (error) => {
    return {
        type: types.SHOW_FORM_FAIL,
        error: error
    }
}

export const showForm = (id, token) => {
    return dispatch => {
        dispatch(showFormStart())
        axios.get(`${process.env.REACT_APP_BASE_URL}/user/register_aog_mog/` + id, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => {
            dispatch(showFormSuccess(res.data))
        })
        .catch(err => {
            dispatch(showFormFail(err))
        })
    }
}