import {
    POST_FORM_START, 
    POST_FORM_END, 
    GET_FORM,
    POST_UPLOAD_BUKTI_START,
    POST_UPLOAD_BUKTI_END,
    GET_LIMIT,
} from '../actions/types'
import { updateObject } from '../shared/utility';
import * as types from '../actions/types';

const initialState = {
    form: null,
    formList: null,
    countUpload: { count: 0 },
    formDetail: {},
    buktiBayar: null,
    loadingFormList: true,
    loadingForm: false,
    loadingUpload: false,
    loadingCount: true,
    error: {}
}

const showFormStart = (state, action) => {
    return updateObject(state, { loading: true })
}
  
const showFormSuccess = (state, action) => {
    return updateObject(state, {
        formDetail: action.formDetail,
        loading: false
    })
}
  
const showFormFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case POST_FORM_START:
            return {
                ...state,
                loadingForm: true
            }
        case POST_FORM_END:
            return {
                ...state,
                form: payload,
                loadingForm: false
            }
        case GET_FORM:
            return {
                ...state,
                formList: payload,
                loadingFormList: false
            }
        case POST_UPLOAD_BUKTI_START:
            return {
                ...state,
                loadingUpload: true
            }
        case POST_UPLOAD_BUKTI_END:
            return {
                ...state,
                buktiBayar: payload,
                loadingUpload: false
            }
        case GET_LIMIT:
            return {
                ...state,
                countUpload: payload,
                loadingCount: false
            }
        case types.SHOW_FORM_START: return showFormStart(state, action)
        case types.SHOW_FORM_SUCCESS: return showFormSuccess(state, action)
        case types.SHOW_FORM_FAIL: return showFormFail(state, action)
        default:
            return state
    }
}

export default reducer;