import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

import { Info, Message } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    marginTop: theme.spacing(4),
  },
  containerCard: {
    padding: theme.spacing(2),
  },
}));

const SettingWA = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h4">Setting WhatsApp</Typography>
      </div>
      <Grid container className={classes.content}>
        <Grid item md={8} className={classes.containerCard}>
          <Message />
        </Grid>
        <Grid item md={4} className={classes.containerCard}>
          <Info />
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(null, {})(SettingWA);
