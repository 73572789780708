import React, { Fragment, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, makeStyles, Select, Typography } from '@material-ui/core';

import moment from 'moment';
import * as actions from '../../actions/index';
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { connect } from 'react-redux';

import { ListAogMog } from './components';

const schema = yup.object().shape({
})

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpace: {
    padding: '10px 32px'
    // backgroundColor: '#EEEEEE'
  },
  btnDownload: {
    width: '100%',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
  }
}));

const AogMog = props => {
  const classes = useStyles();
  const token = sessionStorage.getItem('access_token');
  const { onExportAogMog } = props;

  const [formSearch, setFormSearch] = useState({
    start_date: moment(Date()).format('YYYY-MM-DD'),
    end_date: moment(Date()).format('YYYY-MM-DD'),
    status: 1,
  })

  // const minDate = moment().subtract(1, 'M').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const handleDateChange = event => {
    setStartDate(moment(event));
    setEndDate(moment(event).add(30, 'd'));
    setMaxDate(moment(event).add(30, 'd'))
    setFormSearch((formSearch) => ({
      ...formSearch,
      start_date: moment(event).format('YYYY-MM-DD'),
      end_date: moment(event).add(30, 'd').format('YYYY-MM-DD')
    }))
  }

  const [ maxDate, setMaxDate ] = useState(moment(startDate).add(30, 'd'));
  const [endDate, setEndDate] = useState(moment(startDate).format('YYYY-MM-DD'));
  const handleEndDateChange = event => {
    setEndDate(moment(event));
    setFormSearch((formSearch) => ({
      ...formSearch,
      end_date: moment(event).format('YYYY-MM-DD')
    }))
  }

  const handleSearch = event => {
    const target = event.target.name;
    event.persist()
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }))
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    // console.log(formSearch)
    onExportAogMog(token, formSearch)
  }

  return (
    <Fragment>
      <div className={classes.contentSpace}>
        {/* {loading} */}
        <div className={classes.row}>
          <Grid container >
            <Grid item lg={8} md={8} sm={12} xs={12} >
              <Typography variant="h4">Laporan Aog Mog</Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            justify='space-between'
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">Filter</Typography>
                <br />
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={12} xs={12}>  
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        variant="dialog"
                        openTo="year"
                        format="dd/MM/yyyy"
                        // minDate={minDate}
                        label="Start Date"
                        helperText="Start from year selection"
                        disableFuture
                        value={startDate}
                        onChange={handleDateChange}
                        fullWidth
                        name="date_from"
                        inputRef={register}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        // disabled
                        variant="dialog"
                        openTo="year"
                        maxDate={maxDate}
                        format="dd/MM/yyyy"
                        // views={["year", "month"]}
                        label="End Date"
                        helperText="End from year selection"
                        disableFuture
                        value={endDate}
                        onChange={handleEndDateChange}
                        fullWidth
                        name="date_to"
                        inputRef={register}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
                         Download
                        </Button>
                        {/* {btnDownload} */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormControl
                      // error={errors.status && true}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                      <Select
                        native
                        value={formSearch.status}
                        onChange={handleSearch}
                        label="Pilih Status"
                        inputProps={{
                          name: 'status',
                          id: 'outlined-age-native-simple'
                        }}
                        name="status"
                      >
                        <option aria-label="None" value="" />
                        <option key={0} value=''>Lihat Semua</option>
                        <option key={1} value={0}>Menunggu Konfirmasi</option>
                        <option key={2} value={1}>Terkonfirmasi</option>
                        <option key={3} value={2}>Tidak Disetujui</option>
                      </Select>
                      {/* <FormHelperText>{errors.status && errors.status.message}</FormHelperText> */}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
        <hr />

        <ListAogMog formSearch={formSearch}/>

      </div>

    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    aogMog: state.aogMogRegis.aogMogRegis,
    loading: state.aogMogRegis.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onExportAogMog: (token, formSearch) => dispatch(actions.exportAogMogRegis(token, formSearch))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AogMog);