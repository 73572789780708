import * as types from './types';
import axios from '../axios-orders';
import { setAlert } from './alert';

export const fetchPaymentReceiptStart = () => {
  return {
    type: types.FETCH_PAYMENT_UPGRADE_RECEIPT_START
  }
}

export const fetchPaymentReceiptSuccess = (paymentReceiptsUpgrade, current_page, per_page, total, from) => {
  return {
    type: types.FETCH_PAYMENT_UPGRADE_RECEIPT_SUCCESS,
    paymentReceiptsUpgrade: {
      paymentReceiptsUpgrade,
      current_page,
      per_page,
      total,
      from,
    }
  }
}

export const fetchPaymentReceiptFail = (error) => {
  return {
    type: types.FETCH_PAYMENT_UPGRADE_RECEIPT_FAIL,
    error: error
  }
}

export const fetchPaymentReceiptUpgrade = (page, token, formSearch) => {
  let param = '&status='+formSearch.status;

  return dispatch => {
    dispatch(fetchPaymentReceiptStart())
    axios.get('user/upgrade_payment_receipt/search?page=' + page + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        const fetchedPaymentReceipts = []
        for (const key in res.data.data) {
          fetchedPaymentReceipts.push({
            ...res.data.data[key]
          })
        }
        dispatch(fetchPaymentReceiptSuccess(fetchedPaymentReceipts, res.data.current_page, res.data.per_page, res.data.total, res.data.from))
      })
      .catch(err => {
        dispatch(fetchPaymentReceiptFail(err))
      })
  }
}

export const showPaymentReceiptStart = () => {
  return {
    type: types.SHOW_PAYMENT_UPGRADE_RECEIPT_START
  }
}

export const showPaymentReceiptSuccess = (paymentReceiptUpgrade) => {
  return {
    type: types.SHOW_PAYMENT_UPGRADE_RECEIPT_SUCCESS,
    paymentReceiptUpgrade: paymentReceiptUpgrade
  }
}

export const showPaymentReceiptFail = (error) => {
  return {
    type: types.SHOW_PAYMENT_UPGRADE_RECEIPT_FAIL,
    error: error
  }
}

export const showPaymentReceiptUpgrade = (id, token) => {
  return dispatch => {
    dispatch(showPaymentReceiptStart());
    axios.get('user/upgrade_payment_receipt/' + id, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log()
        dispatch(showPaymentReceiptSuccess(res.data))
      })
      .catch(err => {
        dispatch(showPaymentReceiptFail(err))
      })
  }
}

export const updatePaymentReceiptStart = () => {
  return {
    type: types.UPDATE_PAYMENT_UPGRADE_RECEIPT_START
  }
}

export const updatePaymentReceiptSuccess = () => {
  return {
    type: types.UPDATE_PAYMENT_UPGRADE_RECEIPT_SUCCESS,
  }
}

export const updatePaymentReceiptFail = (error) => {
  return {
    type: types.UPDATE_PAYMENT_UPGRADE_RECEIPT_FAIL,
    error: error
  }
}

export const updatePaymentReceiptUpgrade = (id, storeData, token) => {
  return dispatch => {
    dispatch(updatePaymentReceiptStart())
    axios.patch('user/history_upgrade_payment_receipt/'+ id, storeData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        console.log(response)
        dispatch(setAlert('Sukses Update', 'success'))
        dispatch(updatePaymentReceiptSuccess())
      })
      .catch(error => {
        dispatch(updatePaymentReceiptFail(error))
      })

  }
}