/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const validationSchema = yup.object().shape({
  total: yup.number().required("wajib diisi").typeError("wajib diisi"),
  type: yup.string().required("wajib diisi"),
});

const DialogGenerateID = ({ open, onClose, action, setSelected }) => {
  const [generateList, setGenerateList] = useState([]);

  const { handleSubmit, control, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setSelected([]);
    action(data, setGenerateList);
  };

  const submit = () => {
    setGenerateList([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Form ID Aog Mog Baru</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Typography>Jumlah Permintaan</Typography>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            inputRef={register}
            name="total"
            placeholder="Isikan jumlah permintaan"
            error={!!errors.total}
            helperText={errors.total?.message}
          />
          <Box marginTop={3} />
          <Typography>Pilih tipe</Typography>
          <Controller
            control={control}
            name="type"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                select
                fullWidth
                variant="outlined"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.type}
                helperText={errors.type?.message}
              >
                <MenuItem value="aog">AOG</MenuItem>
                <MenuItem value="mog">MOG</MenuItem>
              </TextField>
            )}
          />
          <Box marginTop={3} />
          {generateList.length !== 0 && (
            <>
              <Typography>Hasil Generate</Typography>
              <Table>
                <TableBody>
                  {generateList.map((value, index) => (
                    <TableRow key={value.generate_id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{value.generate_id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={generateList.length !== 0}
          >
            Generate
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={submit}
            disabled={generateList.length === 0}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogGenerateID;
