import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Box,
  Button,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

import { getMessageWa } from "../../../../../actions";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#FF9300",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF9300",
    },
  },
  boxLoading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
  },
}));

const MessageContent = ({
  defaultValue,
  action,
  status,
  type,
  action_on,
  getMessageWa,
  settingWa,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, setValue, control } = useForm({
    shouldUnregister: false,
  });

  const onSubmit = (value) => {
    const formData = {
      status: status,
      type: type,
      action_on: action_on,
      message: value.message,
      auto_send: value.auto_send ? 1 : 0,
    };
    action(formData);
  };

  useEffect(() => {
    setValue("message", defaultValue.message);
    setValue("auto_send", defaultValue.auto_send);
  }, [defaultValue, setValue]);

  useEffect(() => {
    if (defaultValue.message === "") {
      getMessageWa(action_on, type, status);
    }
  }, [action_on, defaultValue.message, getMessageWa, status, type]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography>Pesan</Typography>
      {settingWa.loading ? (
        <Box className={classes.boxLoading}>
          <CircularProgress />
        </Box>
      ) : (
        <TextField
          multiline
          fullWidth
          inputRef={register}
          name="message"
          variant="outlined"
          rows={10}
        />
      )}
      <Box display="flex" justifyContent="space-between" marginTop={2}>
        <Controller
          control={control}
          defaultValue={false}
          name="auto_send"
          render={({ onChange, value }) => (
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
              <Typography>Auto Send Message</Typography>
            </Box>
          )}
        />
        <Button variant="contained" className={classes.btn} type="submit">
          Submit
        </Button>
      </Box>
    </form>
  );
};

const mapStateToProps = (state) => ({
  settingWa: state.settingWa,
});

export default connect(mapStateToProps, { getMessageWa })(MessageContent);
