import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { 
    Card, 
    CardContent, 
    CardHeader, 
    Grid, 
    TextField, 
    FormControl,
    Select as MaterialSelect,
    FormHelperText,
    InputLabel
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { connect } from 'react-redux'
import { getBranch } from '../../../../../actions/branch'
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    textInput: {
        width: '100%'
    },
    card: {
        marginTop: theme.spacing(2),
        overflow: 'visible'
    }
}))

const Pereferal = (props) => {
    const classes = useStyles()
    const { 
        errors,
        handleChange,
        onSelectSearchChange,
        formState,
        register,
        getBranch, 
        branch: { 
            branch, 
            loadingBranch 
        },
    } = props

    // const optionsLoading = [{ 'value' : 'loading', 'label' : 'Loading'}];
    const branchOptions = [];
    if(branch != null){
        for (let i = 0; i < branch.length; i++) {
            branchOptions.push({'value' : branch[i].name, 'label' : branch[i].name, 'name' : 'cabang_pembina'});
        }
    }

    useEffect(() => {
        getBranch()
    }, [ getBranch, loadingBranch ])

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardHeader title="Pereferal" />
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            {!loadingBranch || branch != null ? (
                                // <TextField 
                                //     label="Cabang Pembina"
                                //     name="cabang_pembina"
                                //     className={classes.textInput}
                                //     defaultValue={formState.values.cabang_pembina || ''}
                                //     onChange={onSelectSearchChange}
                                //     
                                //     helperText={
                                //         errors.cabang_pembina && errors.cabang_pembina.message
                                //     }
                                //     error={errors.cabang_pembina && true}
                                //     inputRef={register}
                                //     select
                                // >
                                //     {branch.map((item) => (
                                //         <MenuItem key={item.id} value={item.name}>
                                //             {item.name}
                                //         </MenuItem>
                                //     ))}
                                // </TextField>
                                <Select 
                                    className={classes.select} 
                                    options={branchOptions} 
                                    onChange={onSelectSearchChange} 
                                    placeholder="Pilih Cabang Pembina"
                                />
                            ):(
                                <Skeleton variant="rect"></Skeleton>
                            )}
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <TextField 
                                label="Nama Pereferal"
                                name="nama_pereferal"
                                className={classes.textInput}
                                defaultValue={formState.values.nama_pereferal || ''}
                                onChange={handleChange}
                                
                                helperText={
                                    errors.nama_pereferal && errors.nama_pereferal.message
                                }
                                error={errors.nama_pereferal && true}
                                inputRef={register}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <TextField 
                                label="No ID AOG Pereferal"
                                name="no_id_pereferal"
                                className={classes.textInput}
                                defaultValue={formState.values.no_id_pereferal || ''}
                                onChange={handleChange}
                                
                                helperText={
                                    errors.no_id_pereferal && errors.no_id_pereferal.message
                                }
                                error={errors.no_id_pereferal && true}
                                inputRef={register}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            {/* <TextField 
                                label="Status Pereferal AOG/Cabang Langsung"
                                name="status_pereferal"
                                className={classes.textInput}
                                defaultValue={formState.values.status_pereferal || ''}
                                onChange={handleChange}
                                
                                helperText={
                                    errors.status_pereferal && errors.status_pereferal.message
                                }
                                error={errors.status_pereferal && true}
                                inputRef={register}
                                select
                            >
                                <MenuItem key="aog" value="aog">
                                    AOG
                                </MenuItem>
                                <MenuItem key="cabang" value="cabang">
                                    Cabang
                                </MenuItem>
                            </TextField> */}
                            <FormControl
                                error={errors.status_pereferal ? true : false}
                                className={classes.formControl} fullWidth
                            >
                                <InputLabel htmlFor="outlined-age-native-simple">Status Pereferal</InputLabel>
                                <MaterialSelect 
                                    native
                                    label="Status Pereferal"
                                    name="status_pereferal"
                                    className={classes.textInput}
                                    defaultValue={formState.values.status_pereferal || ''}
                                    onChange={handleChange}
                                    inputRef={register}
                                >
                                    <option aria-label="None" value="" />
                                    <option key="aog" value="aog">
                                    AOG
                                    </option>
                                    <option key="cabang" value="cabang">
                                        Cabang
                                    </option>
                                </MaterialSelect>
                                <FormHelperText>{errors.status_pereferal && errors.status_pereferal.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <TextField 
                                label="Nomor Rekening Pereferal"
                                name="no_rek_pereferal"
                                className={classes.textInput}
                                defaultValue={formState.values.no_rek_pereferal || ''}
                                onChange={handleChange}
                                
                                helperText={
                                    errors.no_rek_pereferal && errors.no_rek_pereferal.message
                                }
                                error={errors.no_rek_pereferal && true}
                                inputRef={register}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <TextField 
                                label="Nama Bank Pereferal"
                                name="nama_bank_pereferal"
                                className={classes.textInput}
                                defaultValue={formState.values.nama_bank_pereferal || ''}
                                onChange={handleChange}
                                
                                helperText={
                                    errors.nama_bank_pereferal && errors.nama_bank_pereferal.message
                                }
                                error={errors.nama_bank_pereferal && true}
                                inputRef={register}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                label="Nomor Whatsapp Pereferal"
                                name="no_wa_pereferal"
                                inputRef={register}
                                error={errors.no_wa_pereferal && true}
                                helperText={errors.no_wa_pereferal && errors.no_wa_pereferal.message}
                                fullWidth
                                // defaultValue={formState.wakif[0].phone}
                                defaultValue={formState.values.no_wa_pereferal}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    
                </CardContent>
            </Card>
        </div>
    )
}

const mapStateToProps = state => ({
    branch: state.branch
})

export default connect(mapStateToProps, { getBranch })(Pereferal)