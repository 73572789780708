import React, { Fragment } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import moment from 'moment'

const RegisterAogMog = props => {
  const { registerAogMog } = props
  return (
    <Fragment>
      <TableRow key={registerAogMog.id}>
        <TableCell>
          {moment(registerAogMog.tanggal_pendaftaran).parseZone().format('DD MMMM YYYY')}
        </TableCell>
        <TableCell>
          {registerAogMog.nama_lengkap}
        </TableCell>
        <TableCell>
          {registerAogMog.jenis_pendaftaran}
        </TableCell>
        <TableCell>
          {registerAogMog.cabang_pembina}
        </TableCell>
        <TableCell>
          {registerAogMog.nama_pereferal}
        </TableCell>
        <TableCell>
          {registerAogMog.apakah_sudah_mengikuti_private_class}
        </TableCell>
        <TableCell>
        <NumberFormat thousandSeparator={'.'} prefix={'Rp '} decimalSeparator={','} displayType={'text'} value={registerAogMog.biaya_total}/>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default RegisterAogMog
