import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
	Button,
	TablePagination,
	Grid,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import SearchInput from "../../../../components/SearchInput";

import { connect } from 'react-redux'
import * as actions from '../../../../actions'
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
	},
	searchRoot: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	btn: {
    backgroundColor: '#FF9300',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFA938',
      opacity: 1,
    },
		textTransform: 'none',
	},
}));

const TableBranch = props => {
  const classes = useStyles();
  const {
    onFetchStatisticBranch,
    statisticBranch,
		loadingStatisticBranch,
		handleChangeBranch
	} = props
	
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [search, setSearch] = useState("");

	const handleChangeSearch = event => {
		setSearch(event.target.value)
	}

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
	};
	
	let no = 1
	if(!loadingStatisticBranch && statisticBranch !== null){
		no = statisticBranch.from + 1
	}

  useEffect(() => {
		const timer = setTimeout(() => {
			onFetchStatisticBranch(page+1, search)
		}, 1000)
		return () => clearTimeout(timer)
  }, [onFetchStatisticBranch, page, search])

  return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<SearchInput
						placeholder="Search Branch"
						value={search}
						className={classes.searchRoot}
						onChange={handleChangeSearch}
					/>
				</Grid>
			</Grid>
			<TableContainer component={Paper}>
				<Table className={classes.table} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>No</TableCell>
							<TableCell>Nama Cabang</TableCell>
							<TableCell>Aksi</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
							{loadingStatisticBranch || statisticBranch === null ? (
								<TableRow>
									<TableCell colSpan={3}>
										<Skeleton></Skeleton>
									</TableCell>
								</TableRow>
							):(
								<>
								<TableRow key="0">
									<TableCell>
										1
									</TableCell>
									<TableCell component="th" scope="row">
										All Branch
									</TableCell>
									<TableCell>
										<Button className={classes.btn} onClick={() => handleChangeBranch({ id: '', name: 'All Branch'})}>
											Pilih
										</Button>
									</TableCell>
								</TableRow>
								{statisticBranch.data.map((row) => (
									<TableRow key={row.id}>
										<TableCell>
											{no++}
										</TableCell>
										<TableCell component="th" scope="row">
											{row.name}
										</TableCell>
										<TableCell>
											<Button className={classes.btn} onClick={() => handleChangeBranch(row)}>
												Pilih
											</Button>
										</TableCell>
									</TableRow>
								))}
								</>
							)}
					</TableBody>
				</Table>
				{loadingStatisticBranch || statisticBranch === null ? (
					<Skeleton></Skeleton>
				):(
					<TablePagination
						component="div"
						rowsPerPageOptions={[rowsPerPage]}
						count={statisticBranch.total}
						page={page}
						onChangePage={handleChangePage}
						rowsPerPage={rowsPerPage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				)}
			</TableContainer>
		</Fragment>
  );
}

const mapStateToProps = state => ({
	statisticBranch: state.statisticMembership.statisticBranch,
	loadingStatisticBranch: state.statisticMembership.loadingStatisticBranch
})

const mapDispatchToProps = dispatch => {
	return {
		onFetchStatisticBranch : (page, search) => dispatch(actions.fetchStatisticBranch(page, search))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TableBranch)
