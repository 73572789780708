import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Grid,
  Card,
  CardContent,
  CardHeader,
  MenuItem
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Select from "react-select";

import { connect } from "react-redux";
import {
  getProvinceDomisili,
  getCitiesDomisili,
  getDistrictDomisili,
  getVillageDomisili,
} from "../../../../../actions/province";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  textInput: {
    width: "100%",
  },
  card: {
    marginTop: theme.spacing(2),
    overflow: "visible",
  },
}));

const DomisiliAddress = (props) => {
  const classes = useStyles();
  const {
    register,
    handleChange,
    formState,
    setFormState,
    errors,
    getProvinceDomisili,
    getCitiesDomisili,
    getDistrictDomisili,
    getVillageDomisili,
    province: {
      provinceDomisili,
      cityDomisili,
      districtDomisili,
      villageDomisili,
      // loadingVillageDomisili,
      loadingProvinceDomisili,
      // loadingCityDomisili,
      // loadingDistrictDomisili,
    },
  } = props;

  const onProvinceChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
    getCitiesDomisili(event.target.value.id);
  };

  const onCityChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
    getDistrictDomisili(event.target.value.id);
  };

  const onDistrictChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
    getVillageDomisili(event.target.value.id);
  };

  const onVillageChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
  };

  const optionsLoading = [{ value: "loading", label: "Loading" }];
  const provinceDomisiliOptions = [];
  if (provinceDomisili != null) {
    for (let i = 0; i < provinceDomisili.length; i++) {
      provinceDomisiliOptions.push({
        value: provinceDomisili[i],
        label: provinceDomisili[i].name,
        name: "provinsi_domisili",
      });
    }
  }

  const cityDomisiliOptions = [];
  if (cityDomisili != null) {
    for (let i = 0; i < cityDomisili.length; i++) {
      cityDomisiliOptions.push({
        value: cityDomisili[i],
        label: cityDomisili[i].name,
        name: "kota_domisili",
      });
    }
  }

  const districtDomisiliOptions = [];
  if (districtDomisili != null) {
    for (let i = 0; i < districtDomisili.length; i++) {
      districtDomisiliOptions.push({
        value: districtDomisili[i],
        label: districtDomisili[i].name,
        name: "kecamatan_domisili",
      });
    }
  }

  const villageDomisiliOptions = [];
  if (villageDomisili != null) {
    for (let i = 0; i < villageDomisili.length; i++) {
      villageDomisiliOptions.push({
        value: villageDomisili[i],
        label: villageDomisili[i].name,
        name: "kelurahan_domisili",
      });
    }
  }

  useEffect(() => {
    getProvinceDomisili();
  }, [getProvinceDomisili, loadingProvinceDomisili]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader title="Alamat Domisili" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Alamat Lengkap (Alamat Saat ini)"
                className={classes.textInput}
                name="alamat_domisili"
                value={formState.values.alamat_domisili}
                onChange={handleChange}
                helperText={
                  errors.alamat_domisili && errors.alamat_domisili.message
                }
                error={errors.alamat_domisili && true}
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              {formState.values.provinsi_domisili ? (
                <TextField
                  select
                  fullWidth
                  className={classes.select}
                  label="Pilih Provinsi"
                  name="provinsi_domisili"
                  onChange={onProvinceChange}
                  value={formState.values.provinsi_domisili}
                  error={!!errors.provinsi_domisili}
                  helperText={!!errors.provinsi_domisili && errors.provinsi_domisili.message}
                >
                  <MenuItem value=""></MenuItem>
                  {formState.values.provinsi_domisili && (
                    <MenuItem
                      value={formState.values.provinsi_domisili}
                      disabled
                    >
                      {formState.values.provinsi_domisili}
                    </MenuItem>
                  )}
                  {provinceDomisiliOptions.map((value) => (
                    <MenuItem key={value.value.id} value={value.value}>
                      {value.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Skeleton variant="rect"></Skeleton>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              {formState.values.kota_domisili ? (
                <TextField
                  select
                  fullWidth
                  className={classes.select}
                  label="Pilih Kota/Kabupaten"
                  name="kota_domisili"
                  onChange={onCityChange}
                  value={formState.values.kota_domisili}
                  error={!!errors.kota_domisili}
                  helperText={!!errors.kota_domisili && errors.kota_domisili.message}
                >
                  <MenuItem value=""></MenuItem>
                  {formState.values.kota_domisili && (
                    <MenuItem
                      value={formState.values.kota_domisili}
                      disabled
                    >
                      {formState.values.kota_domisili}
                    </MenuItem>
                  )}
                  {cityDomisiliOptions.map((value) => (
                    <MenuItem key={value.value.id} value={value.value}>
                      {value.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Select
                  disabled
                  className={classes.select}
                  options={optionsLoading}
                  onChange={onCityChange}
                  placeholder="Pilih Kota/Kabupaten"
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              {formState.values.kecamatan_domisili ? (
                <TextField
                  select
                  fullWidth
                  className={classes.select}
                  label="Pilih Kecamatan"
                  name="kecamatan_domisili"
                  onChange={onDistrictChange}
                  value={formState.values.kecamatan_domisili}
                  error={!!errors.kecamatan_domisili}
                  helperText={!!errors.kecamatan_domisili && errors.kecamatan_domisili.message}
                >
                  <MenuItem value=""></MenuItem>
                  {formState.values.kecamatan_domisili && (
                    <MenuItem
                      value={formState.values.kecamatan_domisili}
                      disabled
                    >
                      {formState.values.kecamatan_domisili}
                    </MenuItem>
                  )}
                  {districtDomisiliOptions.map((value) => (
                    <MenuItem key={value.value.id} value={value.value}>
                      {value.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Select
                  disabled
                  className={classes.select}
                  options={optionsLoading}
                  onChange={onDistrictChange}
                  placeholder="Pilih Kecamatan"
                />
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              {formState.values.kelurahan_domisili ? (
                <TextField
                  select
                  fullWidth
                  className={classes.select}
                  label="Pilih Kelurahan"
                  name="kelurahan_domisili"
                  onChange={onVillageChange}
                  value={formState.values.kelurahan_domisili}
                  error={!!errors.kelurahan_domisili}
                  helperText={!!errors.kelurahan_domisili && errors.kelurahan_domisili.message}
                >
                  <MenuItem value=""></MenuItem>
                  {formState.values.kelurahan_domisili && (
                    <MenuItem
                      value={formState.values.kelurahan_domisili}
                      disabled
                    >
                      {formState.values.kelurahan_domisili}
                    </MenuItem>
                  )}
                  {villageDomisiliOptions.map((value) => (
                    <MenuItem key={value.value.id} value={value.value}>
                      {value.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <Select
                  disabled
                  className={classes.select}
                  options={optionsLoading}
                  onChange={onVillageChange}
                  placeholder="Pilih Kelurahan"
                />
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Kode Pos"
                className={classes.textInput}
                name="kode_pos_domisili"
                value={formState.values.kode_pos_domisili}
                defaultValue={formState.values.kode_pos_domisili || ""}
                onChange={handleChange}
                helperText={
                  errors.kode_pos_domisili && errors.kode_pos_domisili.message
                }
                error={errors.kode_pos_domisili && true}
                inputRef={register}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  province: state.province,
});

export default connect(mapStateToProps, {
  getProvinceDomisili,
  getCitiesDomisili,
  getDistrictDomisili,
  getVillageDomisili,
})(DomisiliAddress);
