import * as types from '../actions/types';
import { updateObject } from '../shared/utility';

const initialState = {
  listAogMog: null,
  detailAogMog: null,
  dataAogMog: null,
  loadingDetail: false,
  loading: false,
  error: {},
};

const fetchAOGMOGListStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchAOGMOGListSuccess = (state, action) => {
  return updateObject(state, {
    listAogMog: action.listAogMog,
    loading: false
  })
}

const fetchAOGMOGListFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

// Detail Aog Mog
const fetchAOGMOGDetailStart = (state, action) => {
    return updateObject(state, { loadingDetail: true })
  }
  
  const fetchAOGMOGDetailSuccess = (state, action) => {
    return updateObject(state, {
      detailAogMog: action.detailAogMog,
      loadingDetail: false
    })
  }
  
  const fetchAOGMOGDetailFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loadingDetail: false
    })
  }

// Patching Data AOG MOG
const patchAOGMOGStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const patchAOGMOGSuccess = (state, action) => {
  return updateObject(state, {
    dataAogMog: action.dataAogMog,
    loading: false
  })
}

const patchAOGMOGFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_LIST_AOG_MOG_START: return fetchAOGMOGListStart(state, action)
      case types.GET_LIST_AOG_MOG_SUCCESS: return fetchAOGMOGListSuccess(state, action)
      case types.GET_LIST_AOG_MOG_FAIL: return fetchAOGMOGListFail(state, action)

      case types.GET_DETAIL_AOG_MOG_START: return fetchAOGMOGDetailStart(state, action)
      case types.GET_DETAIL_AOG_MOG_SUCCESS: return fetchAOGMOGDetailSuccess(state, action)
      case types.GET_DETAIL_AOG_MOG_FAIL: return fetchAOGMOGDetailFail(state, action)
      
      case types.PATCH_AOG_MOG_START: return patchAOGMOGStart(state, action)
      case types.PATCH_AOG_MOG_SUCCESS: return patchAOGMOGSuccess(state, action)
      case types.PATCH_AOG_MOG_FAIL: return patchAOGMOGFail(state, action)
      default: return state
    }
}
  
export default reducer