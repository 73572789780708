import React, { Fragment } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import NumberFormat from 'react-number-format';


const RegisterAogMog = props => {
  const { registerAogMog } = props
  console.log(registerAogMog);
  return (
    <Fragment>
      <TableRow key={registerAogMog.id}>
        <TableCell>
          {registerAogMog.tanggal_pendaftaran}
        </TableCell>
        <TableCell>
          {registerAogMog.nama_lengkap}
        </TableCell>
        <TableCell>
          {registerAogMog.jenis_pendaftaran}
        </TableCell>
        <TableCell>
          {registerAogMog.cabang_pembina}
        </TableCell>
        <TableCell>
          {registerAogMog.nama_pereferal}
        </TableCell>
        <TableCell>
          <NumberFormat thousandSeparator={'.'} prefix={'Rp.'} decimalSeparator={','} displayType={'text'} value={registerAogMog.biaya_total}/>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default RegisterAogMog
