import { Box, Button, FormControl, Grid, InputLabel, makeStyles, Paper, Select, Tab, Tabs, Typography } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import * as actions from '../../../../actions/index';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  ShowPaymentReceipt,
  Detail
} from './Components';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const schema = yup.object().shape({
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonDelete: {
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(200, 0, 58)'
    },
    color: '#FFFFFF'
  }, 
  tabContainer: {
    margin: '0px 15px',
    backgroundColor: '#F5F5F5'
  }
}))

const EditPaymentReceipt = props => {
  const classes = useStyles();

  const { paymentReceipt, onUpdatePayementReceipt } =  props;

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const token = sessionStorage.getItem('access_token');
  
  const onSubmit = data => {
    onUpdatePayementReceipt(paymentReceipt.id, data, token);
    console.log(data);
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const [formState, setFormState] = useState({
    image: paymentReceipt.image,
    status: paymentReceipt.status,
    inv_name: paymentReceipt.inv_name,
  });

  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <Fragment>
      {/* <AppBar position="static"> */}
      <div>
      <Paper square className={classes.tabContainer}>
        <Tabs 
          value={value} 
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTabs} 
          aria-label="simple tabs example"
        >
          <Tab label="Bukti Pembayaran" {...a11yProps(0)} />
          <Tab label="Rincian" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      </div>
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        <Detail paymentReceipt={paymentReceipt} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ShowPaymentReceipt paymentReceiptShow={paymentReceipt} />
      </TabPanel>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper className={classes.formRoot}>
              <Typography>Form Konfirmasi</Typography>
              <div className={classes.row}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      // error={errors.value ? true : false}
                      variant="outlined" fullWidth>
                      <NumberFormat
                        style={{ padding: '18.5px 14px', width: '100%' }}
                        allowLeadingZeros={false}
                        thousandSeparator="." 
                        decimalSeparator={','} 
                        prefix={'Rp.'} 
                        name="value" 
                        isNumericString={true} 
                        defaultValue={paymentReceipt.total_biaya} 
                        disabled
                        placeholder="Perolehan Aset"
                      />  
                      {/* <FormHelperText>{errors.value && errors.value.message}</FormHelperText> */}
                    </FormControl>
                  </Grid>
                {/* </Grid>

                <Grid container spacing={2}> */}
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl
                      // error={errors.status && true}
                      variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                      <Select
                        native
                        value={formState.status}
                        onChange={handleChange}
                        label="Pilih Status"
                        inputProps={{
                          name: 'status',
                          id: 'outlined-age-native-simple'
                        }}
                        name="status"
                        inputRef={register}
                      >
                        <option aria-label="None" value="" />
                        <option key={1} value={1}>Terkonfirmasi</option>
                        <option key={2} value={2}>Tidak Disetujui</option>
                      </Select>
                      {/* <FormHelperText>{errors.status && errors.status.message}</FormHelperText> */}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={8} md={8} sm={12} xs={12}>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Button variant="contained" fullWidth onClick={props.closedModalDialog} disableElevation>
                          Batal
                        </Button>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
                          Simpan
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>
        
        

      </form>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdatePayementReceipt: (id, storeData, token) => dispatch(actions.updatePaymentReceipt(id, storeData, token))
  }
}

export default connect(null, mapDispatchToProps)(EditPaymentReceipt);