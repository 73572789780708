import { updateObject } from "../shared/utility";
import {
  GET_SEARCH_MOG_START,
  GET_SEARCH_MOG_SUCCESS,
  GET_SEARCH_MOG_FAIL,
  POST_FORM_UPGRADE_START,
  POST_FORM_UPGRADE_SUCCESS,
  POST_FORM_UPGRADE_FAIL,
  CLEAR_SEARCH_MOG,
  SET_UPGRADE_LIST,
} from "../actions/types";

const initialState = {
  loading: false,
  formList: {
    register: [],
    registerList: [],
    biaya_total: 0
  },
  start: true,
  loadingForm: false,
};

const getSearchMogStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getSearchMogFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const getSearchMogSuccess = (state, action) => {
  return updateObject(state, {
    formList: { ...state.formList,register: action.data },
    loading: false,
    start: false,
  });
};

const clearSearchMog = (state, action) => {
  return updateObject(state, {
    formList: { ...state.formList, register: []},
    start: true
  });
};

const postFormUpgradeStart = (state, action) => {
  return updateObject(state, { loadingForm: true });
};

const postFormUpgradeSuccess = (state, action) => {
  return updateObject(state, { loadingForm: false });
};

const postFormUpgradeFail = (state, action) => {
  return updateObject(state, { loadingForm: false });
};

const setUpgradeList = (state, action) => {
  return updateObject(state, {
    formList: {
      ...state.formList,
      registerList: action.payload.upgrade,
      biaya_total: action.payload.biaya_total,
    },
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_MOG_START:
      return getSearchMogStart(state, action);
    case GET_SEARCH_MOG_SUCCESS:
      return getSearchMogSuccess(state, action);
    case GET_SEARCH_MOG_FAIL:
      return getSearchMogFail(state, action);
    case CLEAR_SEARCH_MOG:
      return clearSearchMog(state, action);
    case POST_FORM_UPGRADE_START:
      return postFormUpgradeStart(state, action);
    case POST_FORM_UPGRADE_SUCCESS:
      return postFormUpgradeSuccess(state, action);
    case POST_FORM_UPGRADE_FAIL:
      return postFormUpgradeFail(state, action);
    case SET_UPGRADE_LIST:
      return setUpgradeList(state, action);
    default:
      return state;
  }
};

export default reducer;
