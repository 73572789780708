/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, MenuItem } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import { getListIDCabang } from "../../actions";
import Loading from "../../components/UI/Loading/Loading";
import { Table } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  containField: {
    marginTop: theme.spacing(4),
  },
  btnBranch: {
    backgroundColor: "#CDD8FF",
    color: "#0D298F",
    marginRight: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: "#CDD8FF",
      color: "#0D298F",
    },
  },
  boxField: {
    padding: theme.spacing(0, 1.5),
  },
  field: {
    marginTop: theme.spacing(1),
    "& .MuiFilledInput-root": {
      backgroundColor: "#fff",
    },
  },
  containTable: {
    marginTop: theme.spacing(4),
    backgroundColor: "#fff",
    padding: theme.spacing(5),
  },
  tableTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  boxTable: {
    marginTop: theme.spacing(2),
  },
  boxPagination: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const IdAogMog = ({ loading, generateID, getListIDCabang }) => {
  const classes = useStyles();
  const page_size = 20;

  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(1);

  const handlePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getListIDCabang(type, status, page, page_size, sort, search);
  }, [type, status, page_size, sort, search]);

  useEffect(() => {
    getListIDCabang(type, status, page, page_size, sort, search);
  }, [page]);

  return loading ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <div>
        <Typography variant="h4">Generate ID AOG MOG</Typography>
      </div>
      <Grid container className={classes.containField}>
        <Grid item md={4} className={classes.boxField}>
          <Typography variant="body1">Tipe</Typography>
          <TextField
            select
            fullWidth
            variant="filled"
            className={classes.field}
            defaultValue="default"
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="default" disabled>
              Pilih Tipe
            </MenuItem>
            <MenuItem value="aog">AOG</MenuItem>
            <MenuItem value="mog">MOG</MenuItem>
          </TextField>
        </Grid>
        <Grid item md={4} className={classes.boxField}>
          <Typography variant="body1">Status</Typography>
          <TextField
            select
            fullWidth
            variant="filled"
            className={classes.field}
            defaultValue="default"
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="default" disabled>
              Pilih Status
            </MenuItem>
            <MenuItem value="available">Tersedia</MenuItem>
            <MenuItem value="used">Sudah Digunakan</MenuItem>
          </TextField>
        </Grid>
        <Grid item md={4} className={classes.boxField}>
          <Typography variant="body1">Search ID AOG MOG</Typography>
          <TextField
            fullWidth
            variant="filled"
            className={classes.field}
            placeholder="Cari ID AOG MOG"
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <div className={classes.containTable}>
        <div className={classes.tableTitle}>
          <Typography variant="h4">{`List Generate (Total = ${
            generateID.branch.total || 0
          })`}</Typography>
        </div>
        <div className={classes.boxTable}>
          <Table data={generateID} sort={sort} setSort={setSort} />
        </div>
        <div className={classes.boxPagination}>
          <Pagination
            color="primary"
            count={generateID.branch.lastPage}
            page={page}
            onChange={handlePage}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    generateID: state.generateID,
  };
};

export default connect(mapStateToProps, {
  getListIDCabang,
})(IdAogMog);
