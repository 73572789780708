import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Grid,  
  Typography,
} from '@material-ui/core';
// import { Link as RouterLink } from 'react-router-dom';
import * as actions from '../../actions';
import {connect} from 'react-redux';
import moment from 'moment';
import {
  Card
} from './components';

import Loading from '../../components/UI/Loading/Loading';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		}
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute',
    // zIndex: 0
  },
  cardMobile: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  executiveSummary: {
    marginBottom: theme.spacing(2)
  },
  btn: {
    backgroundColor: '#FF9300',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFA938',
      opacity: 1,
    },
  },
  backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));


const Dashboard = props => {
  const classes = useStyles();

  const [formSearch] = useState({
    start_date: moment(Date()).format('YYYY-MM-DD'),
    end_date: moment(Date()).format('YYYY-MM-DD'),
  })

  const token = sessionStorage.getItem('access_token');
  const { onDashboardCount, loading, dashboardCounts } = props;

  useEffect(() => {
    onDashboardCount(token, formSearch)
  },[onDashboardCount, token, formSearch])

  function isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  let pending = '';
  let approved = '';
  let rejected = '';

  if(!isEmpty(dashboardCounts)){
    pending = <Card nameCard='Menunggu Konfirmasi' countPending={dashboardCounts.count_pending + dashboardCounts.count_upgrade_pending} />;
    approved = <Card nameCard='Terkonfirmasi' countPending={dashboardCounts.count_approved + dashboardCounts.count_upgrade_approved} />;
    rejected = <Card nameCard='Ditolak' countPending={dashboardCounts.count_rejected + dashboardCounts.count_upgrade_reject} />;
  }  

  return loading ? 
  <Loading/>
  : 
  <div className={classes.root}>
    <div className={classes.bgColor}></div>
    <Grid
      container
      spacing={3}
      justify="space-between"
    >
      <Grid item>  
        <Typography variant="h4">Dashboard</Typography>
      </Grid>
    </Grid>
    <div>
      <Grid
        container
        spacing={3}
      > 
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={3}
          >
            <Grid item lg={4} md={4} sm={6} xs={12}>
              {pending}
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              {approved}
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              {rejected}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
    </div>
  </div>;
};

const mapStateToProps = state => {
  return {
    dashboardCounts: state.dashboard.counts,
    loading: state.dashboard.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDashboardCount: (token, formSearch) => dispatch(actions.fetchDashboard(token, formSearch))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
