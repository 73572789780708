import axios from 'axios'
import { setAlert } from './alert'
import { 
    GET_BRANCH,
} from './types'

export const getBranch = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/branch/search?kata_kunci`
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: GET_BRANCH,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_BRANCH,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

