import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// import Checkbox from '@material-ui/core/Checkbox';
import { Button } from "@material-ui/core";
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import { Skeleton } from "@material-ui/lab";
// import NumberFormat from 'react-number-format'
// import moment from 'moment'

import { connect } from "react-redux";
// import { getForm } from '../../../actions/form'
import { Grid, Tooltip } from "@material-ui/core";
// import { IconButton, Tooltip } from '@material-ui/core';
// import { Edit } from '@material-ui/icons';
// import { NavLink } from 'react-router-dom';
import TablePaginationsArrayActions from '../../../components/UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions';
import { clearSearch } from "../../../actions/updateMog";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "nama", numeric: false, disablePadding: false, label: "Nama Lengkap" },
  { id: "id_mog", numeric: true, disablePadding: false, label: "No ID MOG" },
  {
    id: "jenis",
    numeric: true,
    disablePadding: false,
    label: "Jenis Pendaftaran",
  },
  {
    id: "kota",
    numeric: true,
    disablePadding: false,
    label: "Kota/Kab Domisili",
  },
  {
    id: "kecamatan",
    numeric: true,
    disablePadding: false,
    label: "Kecamatan Domisili",
  },
  {
    id: "cabang",
    numeric: true,
    disablePadding: false,
    label: "Cabang Pembina",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
  // { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    // flex: '1 1 100%',
    display: "block",
  },
  subtitle: {
    // flex: '1 1 100%',
    color: "red",
    fontSize: "12px",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                className={classes.title}
                variant="h4"
                id="tableTitle"
                component="div"
              >
                Data MOG
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item>
              <Typography className={classes.subtitle} variant="h6" id="tableTitle" component="div">
                Peraturan Operasional Upload Bukti Bayar <br/>
                1. Jam operasional adalah jam 10.00 WIB sampai dengan 16.00 WIB <br/>
                2. Maksimal Upload Bukti Pembayaran dalam 1 hari adalah 2 kali
              </Typography>
            </Grid>
          </Grid> */}
        </>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 10,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const RegistrationList = (props) => {
  const classes = useStyles();
  const {
    // getForm,
    upgradeMog: { formList, loading, start },
    clearSearch,
    countUpload,
    loadingCount,
    currentTime,
    startTime,
    endTime,
    handleOpenDialog,
    getDays
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const libur = false;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked && formList !== null && !loading) {
  //     const newSelecteds = formList.register.map((n) => n.id);
  //       setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // useEffect(() => {
  //   // getForm()
  // }, [loadingFormList])

  // const handleClick = (event, name, index) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    clearSearch();
  }, [clearSearch]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {formList !== null || !loading ? (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <>
                  <EnhancedTableHead
                    classes={classes}
                    // numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={formList.register.length}
                  />
                  <TableBody>
                    {formList.register.length === 0 && !start ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            "Data tidak ditemukan"
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                          {!loadingCount && (
                            <>
                              {currentTime >= startTime && currentTime < endTime && getDays < 6 ? (
                                <>
                                {countUpload.count < 2 ? (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    component={CustomRouterLink}
                                    to="/registrasion-form/upgrade-mog"
                                    disabled={libur}
                                  >
                                    + Upgrade MOG Baru
                                  </Button>
                                ) : (
                                  <Tooltip
                                    title="Upload bukti pembayaran sudah mencapai limit"
                                    onClick={handleOpenDialog}
                                    placement="bottom"
                                    arrow
                                  >
                                    <Button variant="outlined" color="primary" size="small" disabled={libur}>
                                      + Upgrade MOG Baru
                                    </Button>
                                  </Tooltip>
                                )}
                                </>
                              ) : (
                                <Tooltip
                                  title="Sudah melewati batas waktu"
                                  placement="bottom"
                                  onClick={handleOpenDialog}
                                  arrow
                                >
                                  <Button variant="outlined" color="primary" size="small" disabled={libur}>
                                    + Upgrade MOG Baru
                                  </Button>
                                </Tooltip>
                              )}
                              
                            </>
                          )}
                            
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                    {stableSort(
                      formList.register,
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        // const isItemSelected = isSelected(row.id);

                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.id, index)}
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            // selected={isItemSelected}
                          >
                            <TableCell align="right">
                              {row.nama_lengkap}
                            </TableCell>
                            <TableCell align="right">{row.no_id}</TableCell>
                            <TableCell align="right">
                              {row.jenis_pendaftaran}
                            </TableCell>
                            <TableCell align="right">
                              {row.kota_domisili}
                            </TableCell>
                            <TableCell align="right">
                              {row.kecamatan_domisili}
                            </TableCell>
                            <TableCell align="right">
                              {row.cabang_pembina}
                            </TableCell>
                            <TableCell align="right">
                            {!loadingCount && (
                              <>
                                {currentTime >= startTime && currentTime < endTime && getDays < 6 ? (
                                  <>
                                  {countUpload.count < 2 ? (
                                    <>
                                    {row.jenis_pendaftaran === "MOG" || row.jenis_pendaftaran === "mog" ? (
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        component={CustomRouterLink}
                                        to={`/registrasion-form/upgrade-mog/${row.id}`}
                                      >
                                        Upgrade
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled
                                      >
                                        Upgrade
                                      </Button>
                                    )}
                                    </>
                                  ) : (
                                    <Tooltip
                                      title="Upload bukti pembayaran sudah mencapai limit"
                                      onClick={handleOpenDialog}
                                      placement="bottom"
                                      arrow
                                    >
                                      <Button variant="outlined" color="primary" size="small">
                                        Upgrade
                                      </Button>
                                    </Tooltip>
                                  )}
                                  
                                  </>
                                ) : (
                                  <Tooltip
                                    title="Sudah melewati batas waktu"
                                    placement="bottom"
                                    onClick={handleOpenDialog}
                                    arrow
                                  >
                                    <Button variant="outlined" color="primary" size="small">
                                      Upgrade
                                    </Button>
                                  </Tooltip>
                                )}
                                
                              </>
                            )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {emptyRows > 0 && (
                      <TableRow
                        style={{ height: (dense ? 33 : 53) * emptyRows }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                    {/* <TableRow>
                        <TableCell colSpan={5}>
                          <Typography variant="h4">Total</Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography variant="h4" alrigh="right">
                            <NumberFormat value={formList.biaya_total} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
                          </Typography>
                        </TableCell>
                      </TableRow> */}
                  </TableBody>
                </>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={5}
              component="div"
              count={formList.register.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationsArrayActions}
            />
          </>
        ) : (
          <Skeleton variant="rect"></Skeleton>
        )}
      </Paper>

      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  upgradeMog: state.upgradeMog,
});

export default connect(mapStateToProps, { clearSearch })(RegistrationList);
