import React, { useState } from 'react'
import { Line } from 'react-chartjs-2';
import {
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    Grid,
    Button,
    TextField,
    Select,
    FormControl,
    Dialog,
    DialogContent,
    DialogActions
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import TableBranch from './TableBranch'
import { options } from './chart'

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'auto',
        marginBottom: theme.spacing(1)
    },
    text: {
        color: '#000000',
        fontFamily: 'Nunito'
    }
}))

const CardNumbers = props => {
    const classes = useStyles()
    const {
        chartName,
        // label,
        year,
        setYear,
        month,
        setMonth,
        branchGrafikMonthly,
        setBranchGrafikMonthly,
        grafikMonthlyStatistic
    } = props

    var data = {}
    
    // if(!loadingBarChartList && barChartList[`${barchart.id}`]){
    let tanggal = []
    let valueAOG = []
    let valueMOG = []

    for (let index = 0; index < grafikMonthlyStatistic.length; index++) {
        tanggal.push(grafikMonthlyStatistic[index].tanggal)
        valueAOG.push(grafikMonthlyStatistic[index].daftar_aog)
        valueMOG.push(grafikMonthlyStatistic[index].daftar_mog)
    }

        
    // }

    data = {
        labels: tanggal,
        // labels: label,
        datasets: [
          {
            label : 'Agent of Gold',
            data: valueAOG,
            // data: [100,200,300,400,200,320,400,250,700,500,800,400],
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
            yAxisID: 'y-axis-1',  
          },
          {
            label : 'Member of Gold',
            data: valueMOG,
            // data: [200,100,400,230,150,190,20,25,70,50,80,40],
            fill: false,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgba(54, 162, 235, 0.2)',
            yAxisID: 'y-axis-2',
          }
        ]
    };

    
    const handleYear = (date) => {
        const all = moment(date).format('YYYY');
        console.log(all)
        setYear(year => ({
            ...year,
            submit: {
                submit: all
            },
            view: {
                view: date
            }
        }));
    };

    const handleMonth = (event) => {
        setMonth(event.target.value)
    }

    const handleChangeBranch = (event) => {
        setBranchGrafikMonthly({
            id: event.id,
            name: event.name
        })
    }

    // Dialog Branch
	const [ openDialogBranch, setOpenDialogBranch ] = useState(false)
    
    return(
        <div>
            <Card className={classes.root}>
                <CardHeader 
                    title={chartName}
                    classes={{
                        title: classes.text
                    }}
                />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                {/* <InputLabel htmlFor="age-native-simple">Pilih Bulan</InputLabel> */}
                                <Select
                                    native
                                    value={month}
                                    onChange={handleMonth}
                                    inputProps={{
                                        name: 'month',
                                    }}
                                >
                                    <option value={1}>Januari</option>
                                    <option value={2}>Februari</option>
                                    <option value={3}>Maret</option>
                                    <option value={4}>April</option>
                                    <option value={5}>Mei</option>
                                    <option value={6}>Juni</option>
                                    <option value={7}>Juli</option>
                                    <option value={8}>Agustus</option>
                                    <option value={9}>September</option>
                                    <option value={10}>Oktober</option>
                                    <option value={11}>November</option>
                                    <option value={12}>Desember</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                {/* <InputLabel htmlFor="age-native-simple">Pilih Cabang</InputLabel> */}
                                <TextField
                                    value={branchGrafikMonthly.name}
                                    onClick={() => setOpenDialogBranch(true)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker 
                                    fullWidth
                                    // label="Tahun"
                                    variant="outlined"
                                    name="end_date"
                                    views={["year"]}
                                    format="yyyy"
                                    value={year.view.view} 
                                    onChange={handleYear} 
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Line
                        width={400} 
                        height={200}
                        data={data}
                        options={options}
                    />
                </CardContent>
            </Card>
            <Dialog
                open={openDialogBranch}
                onClose={() => setOpenDialogBranch(false)}
            >
                <DialogContent>
                    <TableBranch handleChangeBranch={handleChangeBranch} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialogBranch(false)}>
                        Batal
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CardNumbers