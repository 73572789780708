import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Grid,
    Typography,
    Button
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2)
    },
    text: {
        fontFamily: 'Nunito'
    }
}))

const Help = props => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
                justify="space-between"
            >
                <Grid item>  
                    <Typography variant="h4">Help Center</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={3}
            >
                <Grid item>
                    <Typography className={classes.text}>
                        Untuk melihat panduan lebih lengkap mengenai website ini silahkan klik tombol dibawah ini <br/>
                        <a
                            href="https://eoatech-oss-1.oss-ap-southeast-5.aliyuncs.com/pos-cabang/asset/Pendaftaran%20AOG%20%26%20MOG_update%20as%20per%2009112020.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button variant="outlined" color="primary" size="small">
                                Download Panduan
                            </Button>
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Help