import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Grid, Avatar } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import HomeWork from '@material-ui/icons/HomeWork'
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 0,
  },
  root: {
    // minWidth: 275,
    borderRadius: 8,
    color: '#231E2E'
  },
  avatar: {
    backgroundColor: '#fff',
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32,
    color: '#DFE0EB',
    textAlign: 'right'
  },
  title: {
    fontSize: 15,
    paddingBottom: 15,
    fontFamily: 'Nunito Sans, Roboto, sans-serif'
  },
  value: {
    fontSize: 25,
    color: '#FF9300',
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
  }
}))

const CardComponent = props => {
  const { nameCard, countPending, type, iconType } = props;
  
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.title} gutterBottom> {nameCard} </Typography>
              {type === 'currency' ? (
                <NumberFormat className={classes.value} value={countPending} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
              ):(
                <NumberFormat className={classes.value} value={countPending} displayType={'text'} thousandSeparator={true} />
              )}
            </Grid>
            <Grid item>
              {iconType && (
                <Avatar className={classes.avatar}>
                  {iconType === 'AllBranch' && (
                    <HomeWork className={classes.icon} />
                  )}
                  {iconType === 'TotalAOG' && (
                    <SupervisedUserCircle className={classes.icon} />
                  )}
                  {iconType === 'TotalMOG' && (
                    <SupervisorAccount className={classes.icon} />
                  )}
                </Avatar>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default CardComponent;
