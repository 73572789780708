import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";

const config = [
  {
    variable: "{{tgl_daftar}}",
    detail: "Tanggal Pendaftaran",
  },
  {
    variable: "{{nama}}",
    detail: "Nama Lengkap Pendaftar",
  },
  {
    variable: "{{no_id}}",
    detail: "ID AOG MOG Pendaftar",
  },
  {
    variable: "{{jenis_pendaftaran}}",
    detail: "Jenis Pendaftaran",
  },
  {
    variable: "{{cabang_pembina}}",
    detail: "Cabang Pembina",
  },
  {
    variable: "{{nama_preferal}}",
    detail: "Nama Preferal Cabang Pembina",
  },
  {
    variable: "{{total_biaya}}",
    detail: "Total Biaya",
  },
  {
    variable: "{{nama_bank_pendaftar}}",
    detail: "Nama Bank Pendaftar",
  },
  {
    variable: "{{norek_pendaftar}}",
    detail: "Nomor Rekening Pendaftar",
  },
];

const useStyles = makeStyles({
  infoText: {
    color: "#929292",
  },
  marginTop: {
    marginTop: "5px",
  },
});

const Info = () => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Info" />
      <CardContent>
        {config.map((value, index) => (
          <Grid container key={index}>
            <Grid item xs={6} className={classes.marginTop}>
              <Typography className={classes.infoText}>
                {value.variable}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.marginTop}>
              <Typography>{value.detail}</Typography>
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
};

export default Info;
