import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, Collapse, colors, Divider, useMediaQuery } from '@material-ui/core';
import { Link as RouterLink, Redirect } from 'react-router-dom';
// import { useMediaQuery } from 'react-responsive'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PaymentIcon from '@material-ui/icons/Payment'
import HelpIcon from '@material-ui/icons/Help'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignOutIcon from '@material-ui/icons/Input';
import StatisticMemberIcon from '@material-ui/icons/Assessment'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NfcIcon from '@material-ui/icons/Nfc';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import Hidden from '@material-ui/core/Hidden';

import { Footer, AppBar, AccountName } from './components';
import { 
  AssignmentInd, 
  PersonAdd 
} from '@material-ui/icons';

const drawerWidth = 240;
const drawerColorBlue = '#FFFFFF';

// const textMenuWhite = '#FFFFFF';
const textMenuBlack = '#000000';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  content: {
    flexGrow: 1,
    paddingTop: 56,
    marginLeft: 0,
    width: '100%',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    zIndex: 10
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: drawerColorBlue,
    borderRadius: theme.spacing(2)
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: drawerColorBlue,
    paddingTop: theme.spacing(5)
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: drawerColorBlue,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    }
  },
  
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // backgroundColor: '#51CDFB'
  },
  menus: {
    paddingTop: theme.spacing(3)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
    width: 'auto'
  },
  textMenu: {
    color: textMenuBlack,
    fontFamily: 'Nunito',
    paddingLeft: theme.spacing(2)
  },
  textMenuNested: {
    color: textMenuBlack,
    fontFamily: 'Nunito',
    fontSize: '14px',
    paddingLeft: theme.spacing(2)
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  nested: {
    paddingLeft: theme.spacing(6),
    paddingTop: 0,
    paddingBottom: 0,
    // fontSize: '14px'
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const Main = props => {
  const { children, window } = props;

  const userData = JSON.parse(sessionStorage.getItem('data'));
  
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerClose = () => {
    setMobileOpen(false)
  }

  // Dialog Box
  const [dialogOpen, setDialogOpen] = useState(false);

  // Collapsible Menu
  const [payment, setPayment] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDoLogout = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('expires_in');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('data');
    sessionStorage.clear();
    setRedirect({values: true});
  }

  const [redirect, setRedirect] = useState({
    values : false
  });

  const handlingSignout = event => {
    event.persist();
    setDialogOpen(true)
    
  };

  if (redirect.values) {
    return <Redirect to='/sign-in'/>;
  };

  const handleClick = (event) => {
    if (event === 'payment') {
      setPayment(!payment);
    }
  };

  let paymentReceipt = (
    <>
    <ListItem 
      key='payment' 
      disabledGutters
      className={classes.item}
      onClick={() => handleClick('payment')}
    >
      <Button
        className={classes.button}
      >
        <div className={classes.icon}>
          <PaymentIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Payment</div>
      </Button>
      {payment ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={payment} timeout="auto" unmountOnExit>
      <List disablePadding>
        <ListItem
          className={classes.nested}
          key='payment-receipt'
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={handleDrawerClose}
            to='/payment-receipt'
          >
            <div className={classes.textMenu}>Payment Receipt</div>
          </Button>
        </ListItem>
        <ListItem
          className={classes.nested}
          key='payment-receipt-upgrade'
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={handleDrawerClose}
            to='/payment-receipt-upgrade'
          >
            <div className={classes.textMenu}>Payment Receipt Upgrade</div>
          </Button>
        </ListItem>
      </List>
    </Collapse>
    
    <ListItem
      disabledGutters
      className={classes.item}
      key='inquiry-data'
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/inquiry-data'
      >
        <div className={classes.icon}>
          <GroupAddIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Inquiry Data</div>
      </Button>
    </ListItem>
    
    </>
  )

  let menuSBS = (
    <>
    <ListItem
      disabledGutters
      className={classes.item}
      key='statistic-member'
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/statistic-member'
      >
        <div className={classes.icon}>
          <StatisticMemberIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Statistic Member</div>
      </Button>
    </ListItem>
    <ListItem
      disabledGutters
      className={classes.item}
      key='setting-wa'
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/setting-wa'
      >
        <div className={classes.icon}>
          <WhatsAppIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Setting WA</div>
      </Button>
    </ListItem>
    </>
  )

  let menu = (
    <>
    <ListItem
      key="transaksi"
      button 
      disabledGutters
      className={classes.item}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/id-aog-mog'
      >
        <div className={classes.icon}>
          <NfcIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>
          ID AOG MOG
        </div>
      </Button>
    </ListItem>
    <ListItem
      key="transaksi"
      button 
      disabledGutters
      className={classes.item}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/registration-form'
      >
        <div className={classes.icon}>
          <PersonAdd style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>
          Registration Form
        </div>
      </Button>
    </ListItem>
    <ListItem
      key="transaksi"
      button 
      disabledGutters
      className={classes.item}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/upgrade-mog'
      >
        <div className={classes.icon}>
          <AssignmentInd style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>
          Upgrade Status Anggota
        </div>
      </Button>
    </ListItem>
    <ListItem
      disabledGutters
      className={classes.item}
      key='payment-receipt'
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/payment-history'
      >
        <div className={classes.icon}>
          <PaymentIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Payment History</div>
      </Button>
    </ListItem>
    <ListItem
      disabledGutters
      className={classes.item}
      key='payment-history-upgrade'
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/payment-history-upgrade'
      >
        <div className={classes.icon}>
          <PaymentIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Payment History Upgrade</div>
      </Button>
    </ListItem>
    </>
  )

  let aogMog = (
    <ListItem
      disabledGutters
      className={classes.item}
      key='aog-mog'
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/aog-mog'
      >
        <div className={classes.icon}>
          <AssignmentInd style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Aog Mog</div>
      </Button>
    </ListItem>
  )

  // ===== DISABLE FITUR FOR PRODUCTION
  const generateID = (
    <ListItem
      disabledGutters
      className={classes.item}
      key='generate-id'
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        onClick={handleDrawerClose}
        to='/generate-id'
      >
        <div className={classes.icon}>
          <NfcIcon style={{ color: textMenuBlack }} />
        </div>
        <div className={classes.textMenu}>Generate ID Aog Mog</div>
      </Button>
    </ListItem>
  )

  const drawer = (
    <div>
      <Divider />
      <List
        className={classes.menus}
      >
        <ListItem 
          key='dashboard' 
          button
          disabledGutters
          className={classes.item}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={handleDrawerClose}
            to='/dashboard'
          >
              <div className={classes.icon}>
                <DashboardIcon style={{ color: textMenuBlack }} />
              </div>
              <div className={classes.textMenu}>
                Dashboard
              </div>
          </Button>
        </ListItem>

        {
          userData.group_user.name === 'CABANG'
          ? menu
          : null
        }

        {
          userData.group_user.name === 'SBS'
          ? generateID 
          : null
        }

        {
          userData.group_user.name === 'SBS'
          ? paymentReceipt 
          : null
        }

        {
          userData.group_user.name === 'SBS'
          || userData.group_user.name === 'RND'
          ? menuSBS 
          : null
        }

        {
          userData.group_user.name === 'SBS' 
          || userData.group_user.name === 'ESS'
          || userData.group_user.name === 'CLUB'
          // || userData.group_user.name === 'RND'
          ? aogMog 
          : null
        }   

        {
          userData.group_user.name === "ESS" 
          ? generateID 
          : null
        }

        <ListItem 
          key='help-center' 
          button
          disabledGutters
          className={classes.item}
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={handleDrawerClose}
            to='/help-center'
          >
            <div className={classes.icon}>
              <HelpIcon style={{ color: textMenuBlack }} />
            </div>
            <div className={classes.textMenu}>
              Help Center
            </div>
          </Button>
        </ListItem>
        <ListItem
          disabledGutters
          className={classes.item}
          key='signout'
        >
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={handlingSignout}
          >
            <div className={classes.icon}>
              <SignOutIcon style={{ color: textMenuBlack }} />
            </div>
            <div className={classes.textMenu}>Sign Out</div>
          </Button>
        </ListItem>
        
      </List>
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <AppBar handleDrawerToggle={handleDrawerToggle} open={open} setOpen={setOpen} />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {<AccountName /> }
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <SwipeableDrawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {<AccountName /> }
            {drawer}

          </SwipeableDrawer>
        </Hidden>
      </nav>
      {/* <SwipeableDrawer
        anchor="left"
        variant="permanent"
        open={open}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {open && <AccountName /> }
        {drawer}
        
        <div className={classes.bottomPush}>
          <div className={classes.toolbar}>
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: textMenuBlack }} /> : <ChevronLeftIcon style={{ color: textMenuBlack }} />}
                <Typography>Kecilkan Menu</Typography>
              </IconButton>
            ):(
              <IconButton onClick={handleDrawerOpen}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon style={{ color: textMenuBlack }} /> : <ChevronRightIcon style={{ color: textMenuBlack }} />}
              </IconButton>
            )}
          </div>
        </div>
      </SwipeableDrawer> */}
      <main 
        className={classes.content}
      >
        {children}
        <Hidden only={['xs','sm']}>
          <Footer />
        </Hidden>
      </main>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logout Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda ingin keluar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleDoLogout} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
