import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Fragment, useState, useEffect } from 'react'
import {
    Card,
    Grid,
    Typography,
    CardContent,
    CardHeader,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    TableContainer,
    Button,
    Select,
    // InputLabel,
    FormControl,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { connect } from 'react-redux'
import * as actions from '../../../actions'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    btn: {
        backgroundColor: '#FF9300',
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#FFA938',
          opacity: 1,
        },
        textTransform: 'none'
    },
    formControl: {
        width: '100%'
    },
    cardRoot: {
        marginTop: theme.spacing(2)
    }
}))

const CreateTarget = props => {
    const classes = useStyles()
    const {
        onFetchInputTargetActive,
        inputTargetActive,
        loadingInputTargetActive,
        onAddInputTargetActive,
        onFetchInputTargetUpgradeActive,
        inputTargetUpgradeActive,
        loadingInputTargetUpgradeActive,
        onAddInputTargetUpgradeActive
    } = props

    const [ formState, setFormState ] = useState({
        values: null
    })

    const [ formStateUpgrade, setFormStateUpgrade ] = useState({
        values: null
    })

    const [ openDialogConfirmation, setOpenDialogConfirmation ] = useState(false)

    const selectedDate  = useState(new Date());

	const submitDefault = moment().format('YYYY');

	const [ year, setYear ] = useState({
        submit: {
                submit: submitDefault
        },
        view: {selectedDate}
    });

    const handleYear = (date) => {
        const all = moment(date).format('YYYY');
        console.log(all)
        setYear(year => ({
            ...year,
            submit: {
                submit: all
            },
            view: {
                view: date
            }
        }));
    };

    const months = ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"]

    const [ jenis, setJenis ] = useState('registration')

    const handleChangeJenis = event => {
        setJenis(event.target.value)
    }

    const onSubmit = () => {
        onAddInputTargetActive(formState.values, setFormState, year.submit.submit)
        setOpenDialogConfirmation(false)
    }

    const onSubmitUpgrade = () => {
        onAddInputTargetUpgradeActive(formStateUpgrade.values, setFormStateUpgrade, year.submit.submit)
        setOpenDialogConfirmation(false)
    }

    useEffect(() => {
        onFetchInputTargetActive(setFormState, year.submit.submit)
        onFetchInputTargetUpgradeActive(setFormStateUpgrade, year.submit.submit)
    }, [
        onFetchInputTargetActive, 
        onFetchInputTargetUpgradeActive, 
        jenis, 
        setFormState, 
        setFormStateUpgrade,
        year
    ])

    return (
        <Fragment>
            <div className={classes.root}>
                <Grid
                    container
                    spacing={3}
                    justify="space-between"
                >
                    <Grid item>  
                        <Typography variant="h4">History Target AOG & MOG</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <FormControl className={classes.formControl}>
                            {/* <InputLabel>Pilih Jenis</InputLabel> */}
                            <Select
                                fullWidth
                                native
                                inputProps={{
                                    name: 'jenis',
                                }}
                                value={jenis}
                                onChange={handleChangeJenis}
                            >
                                <option value="registration">Registration</option>
                                <option value="upgrade">Upgrade</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker 
                                    fullWidth
                                    // label="Tahun"
                                    variant="outlined"
                                    name="year"
                                    views={["year"]}
                                    format="yyyy"
                                    value={year.view.view} 
                                    onChange={handleYear} 
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                </Grid>
                {jenis === 'registration' ? (
                    <>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Card className={classes.cardRoot}>
                                <CardHeader
                                    title="List Target Registration"
                                />
                                <CardContent>
                                    <TableContainer>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Month</TableCell>
                                                    <TableCell>Target AOG</TableCell>
                                                    <TableCell>Target MOG</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {months.map((row, index) => (
                                                <TableRow key={row}>
                                                    <TableCell component="th" scope="row">
                                                        {row}
                                                    </TableCell>
                                                    <TableCell>
                                                        {loadingInputTargetActive || inputTargetActive === null || formState.values === null ? (
                                                            <Skeleton></Skeleton>
                                                        ):(
                                                            <Typography variant="p">
                                                                {formState.values[`AOG_${index+1}`]}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {loadingInputTargetActive || inputTargetActive === null || formState.values === null ? (
                                                            <Skeleton></Skeleton>
                                                        ):(
                                                            <Typography variant="p">
                                                                {formState.values[`MOG_${index+1}`]}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} justify="space-between">
                        <Grid item></Grid>
                        <Grid item>
                            <Button fullWidth onClick={() => setOpenDialogConfirmation(true)} className={classes.btn}>
                                Save
                            </Button>
                        </Grid>
                    </Grid> */}
                    </>
                ):(
                    <>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Card>
                                <CardHeader
                                    title="List Target Upgrade"
                                />
                                <CardContent>
                                    <TableContainer>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Month</TableCell>
                                                    <TableCell>Target AOG</TableCell>
                                                    <TableCell>Target MOG</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {months.map((row, index) => (
                                                <TableRow key={row}>
                                                    <TableCell component="th" scope="row">
                                                        {row}
                                                    </TableCell>
                                                    <TableCell>
                                                        {loadingInputTargetUpgradeActive || inputTargetUpgradeActive === null || formStateUpgrade.values === null ? (
                                                            <Skeleton></Skeleton>
                                                        ):(
                                                            <Typography variant="p">
                                                                {formStateUpgrade.values[`AOG_${index+1}`]}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {loadingInputTargetUpgradeActive || inputTargetUpgradeActive === null || formStateUpgrade.values === null ? (
                                                            <Skeleton></Skeleton>
                                                        ):(
                                                            <Typography variant="p">
                                                                {formStateUpgrade.values[`MOG_${index+1}`]}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} justify="space-between">
                        <Grid item></Grid>
                        <Grid item>
                            <Button fullWidth onClick={() => setOpenDialogConfirmation(true)} className={classes.btn}>
                                Save
                            </Button>
                        </Grid>
                    </Grid> */}
                    </>
                )}
            </div>
            <Dialog
                open={openDialogConfirmation}
                onClose={() => setOpenDialogConfirmation(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure to save this data?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.btn} onClick={() => setOpenDialogConfirmation(false)}>
                        Cancel
                    </Button>
                    {jenis === 'registration' ? (
                        <Button className={classes.btn} onClick={onSubmit}>
                            Yes
                        </Button>
                    ):(
                        <Button className={classes.btn} onClick={onSubmitUpgrade}>
                            Yes
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    inputTargetActive: state.statisticMembership.inputTargetActive,
    loadingInputTargetActive: state.statisticMembership.loadingInputTargetActive,
    inputTargetUpgradeActive: state.statisticMembership.inputTargetUpgradeActive,
    loadingInputTargetUpgradeActive: state.statisticMembership.loadingInputTargetUpgradeActive,
})

const mapDispatchToProps = dispatch => {
    return {
      onFetchInputTargetActive: (setFormState, year) => dispatch(actions.fetchInputTargetActive(setFormState, year)),
      onFetchInputTargetUpgradeActive: (setFormStateUpgrade, year) => dispatch(actions.fetchInputTargetUpgradeActive(setFormStateUpgrade, year)),
      onAddInputTargetActive: (formState, setFormState, year) => dispatch(actions.addingInputTargetActive(formState, setFormState, year)),
      onAddInputTargetUpgradeActive: (formStateUpgrade, setFormStateUpgrade, year) => dispatch(actions.addingInputTargetUpgradeActive(formStateUpgrade, setFormStateUpgrade, year)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTarget)