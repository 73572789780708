// Alert
export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"

// Login
export const ADD_LOGIN = "ADD_LOGIN"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const AUTH_LOGOUT  = "AUTH_LOGOUT"
export const AUTH_SUCCESS = "AUTH_SUCCESS"

// Profile
export const GET_PROFILE = "GET_PROFILE"
export const EDIT_PROFILE = "EDIT_PROFILE"
export const UPDATE_PROFILE = "UPDATE_PROFILE"

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

// Payment Receipt
export const FETCH_PAYMENT_RECEIPT_START = "FETCH_PAYMENT_RECEIPT_START"
export const FETCH_PAYMENT_RECEIPT_SUCCESS = "FETCH_PAYMENT_RECEIPT_SUCCESS"
export const FETCH_PAYMENT_RECEIPT_FAIL = "FETCH_PAYMENT_RECEIPT_FAIL"

export const FETCH_PAYMENT_HISTORY_START = "FETCH_PAYMENT_HISTORY_START"
export const FETCH_PAYMENT_HISTORY_SUCCESS = "FETCH_PAYMENT_HISTORY_SUCCESS"
export const FETCH_PAYMENT_HISTORY_FAIL = "FETCH_PAYMENT_HISTORY_FAIL"

export const SHOW_PAYMENT_RECEIPT_START = "SHOW_PAYMENT_RECEIPT_START"
export const SHOW_PAYMENT_RECEIPT_SUCCESS = "SHOW_PAYMENT_RECEIPT_SUCCESS"
export const SHOW_PAYMENT_RECEIPT_FAIL = "SHOW_PAYMENT_RECEIPT_FAIL"

export const SHOW_PAYMENT_HISTORY_START = "SHOW_PAYMENT_HISTORY_START"
export const SHOW_PAYMENT_HISTORY_SUCCESS = "SHOW_PAYMENT_HISTORY_SUCCESS"
export const SHOW_PAYMENT_HISTORY_FAIL = "SHOW_PAYMENT_HISTORY_FAIL"

export const UPDATE_PAYMENT_RECEIPT_START = "UPDATE_PAYMENT_RECEIPT_START"
export const UPDATE_PAYMENT_RECEIPT_SUCCESS = "UPDATE_PAYMENT_RECEIPT_SUCCESS"
export const UPDATE_PAYMENT_RECEIPT_FAIL = "UPDATE_PAYMENT_RECEIPT_FAIL"

export const SHOW_PAYMENT_RECEIPT = "SHOW_PAYMENT_RECEIPT"
export const UPDATE_PAYMENT_RECEIPT = "UPDATE_PAYMENT_RECEIPT"

// Payment Receipt Upgrade
export const FETCH_PAYMENT_UPGRADE_RECEIPT_START = "FETCH_PAYMENT_UPGRADE_RECEIPT_START"
export const FETCH_PAYMENT_UPGRADE_RECEIPT_SUCCESS = "FETCH_PAYMENT_UPGRADE_RECEIPT_SUCCESS"
export const FETCH_PAYMENT_UPGRADE_RECEIPT_FAIL = "FETCH_PAYMENT_UPGRADE_RECEIPT_FAIL"

export const FETCH_PAYMENT_UPGRADE_HISTORY_START = "FETCH_PAYMENT_UPGRADE_HISTORY_START"
export const FETCH_PAYMENT_UPGRADE_HISTORY_SUCCESS = "FETCH_PAYMENT_UPGRADE_HISTORY_SUCCESS"
export const FETCH_PAYMENT_UPGRADE_HISTORY_FAIL = "FETCH_PAYMENT_UPGRADE_HISTORY_FAIL"

export const SHOW_PAYMENT_UPGRADE_RECEIPT_START = "SHOW_PAYMENT_UPGRADE_RECEIPT_START"
export const SHOW_PAYMENT_UPGRADE_RECEIPT_SUCCESS = "SHOW_PAYMENT_UPGRADE_RECEIPT_SUCCESS"
export const SHOW_PAYMENT_UPGRADE_RECEIPT_FAIL = "SHOW_PAYMENT_UPGRADE_RECEIPT_FAIL"

export const SHOW_PAYMENT_UPGRADE_HISTORY_START = "SHOW_PAYMENT_UPGRADE_HISTORY_START"
export const SHOW_PAYMENT_UPGRADE_HISTORY_SUCCESS = "SHOW_PAYMENT_UPGRADE_HISTORY_SUCCESS"
export const SHOW_PAYMENT_UPGRADE_HISTORY_FAIL = "SHOW_PAYMENT_UPGRADE_HISTORY_FAIL"

export const UPDATE_PAYMENT_UPGRADE_RECEIPT_START = "UPDATE_PAYMENT_UPGRADE_RECEIPT_START"
export const UPDATE_PAYMENT_UPGRADE_RECEIPT_SUCCESS = "UPDATE_PAYMENT_UPGRADE_RECEIPT_SUCCESS"
export const UPDATE_PAYMENT_UPGRADE_RECEIPT_FAIL = "UPDATE_PAYMENT_UPGRADE_RECEIPT_FAIL"

export const SHOW_PAYMENT_UPGRADE_RECEIPT = "SHOW_PAYMENT_UPGRADE_RECEIPT"
export const UPDATE_PAYMENT_UPGRADE_RECEIPT = "UPDATE_PAYMENT_UPGRADE_RECEIPT"

// Province
export const GET_PROVINCE = "GET_PROVINCE"
export const GET_CITY = "GET_CITY"
export const GET_DISTRICT = "GET_DISTRICT"
export const GET_VILLAGE = "GET_VILLAGE"

export const GET_PROVINCE_DOMISILI = "GET_PROVINCE_DOMISILI"
export const GET_CITY_DOMISILI = "GET_CITY_DOMISILI"
export const GET_DISTRICT_DOMISILI = "GET_DISTRICT_DOMISILI"
export const GET_VILLAGE_DOMISILI = "GET_VILLAGE_DOMISILI"

// Branch
export const GET_BRANCH = "GET_BRANCH"

export const FETCH_BRANCH_START = "FETCH_BRANCH_START"
export const FETCH_BRANCH_SUCCESS = "FETCH_BRANCH_SUCCESS"
export const FETCH_BRANCH_FAIL = "FETCH_BRANCH_FAIL"



// Form
export const POST_FORM_START = "POST_FORM_START"
export const POST_FORM_END = "POST_FORM_END"
export const GET_FORM = "GET_FORM"
export const POST_UPLOAD_BUKTI_START = "POST_UPLOAD_BUKTI_START"
export const POST_UPLOAD_BUKTI_END = "POST_UPLOAD_BUKTI_END"
export const GET_LIMIT = "GET_LIMIT"

export const SHOW_FORM_START = "SHOW_FORM_START"
export const SHOW_FORM_SUCCESS = "SHOW_FORM_SUCCESS"
export const SHOW_FORM_FAIL = "SHOW_FORM_FAIL"

//AOG MOG
export const EXPORT_AOG_MOG_START = "EXPORT_AOG_MOG_START"
export const EXPORT_AOG_MOG_SUCCESS = "EXPORT_AOG_MOG_SUCCESS"
export const EXPORT_AOG_MOG_FAIL = "EXPORT_AOG_MOG_FAIL"

export const FETCH_AOG_MOG_START = "FETCH_AOG_MOG_START"
export const FETCH_AOG_MOG_SUCCESS = "FETCH_AOG_MOG_SUCCESS"
export const FETCH_AOG_MOG_FAIL = "FETCH_AOG_MOG_FAIL"

//Dashboard
export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START"
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS"
export const FETCH_DASHBOARD_FAIL = "FETCH_DASHBOARD_FAIL"

export const FETCH_DASHBOARD_CABANG_START = "FETCH_DASHBOARD_CABANG_START"
export const FETCH_DASHBOARD_CABANG_SUCCESS = "FETCH_DASHBOARD_CABANG_SUCCESS"
export const FETCH_DASHBOARD_CABANG_FAIL = "FETCH_DASHBOARD_CABANG_FAIL"

//config
export const GET_CONFIG_TIME_START = "GET_CONFIG_TIME_START"
export const GET_CONFIG_TIME_SUCCESS = "GET_CONFIG_TIME_SUCCESS"
export const GET_CONFIG_TIME_FAIL = "GET_CONFIG_TIME_FAIL"

//Update MOG
export const GET_SEARCH_MOG_START = "GET_SEARCH_MOG_START"
export const GET_SEARCH_MOG_SUCCESS = "GET_SEARCH_MOG_SUCCESS"
export const GET_SEARCH_MOG_FAIL = "GET_SEARCH_MOG_FAIL"
export const POST_FORM_UPGRADE_START = "POST_FORM_UPGRADE_START"
export const POST_FORM_UPGRADE_SUCCESS = "POST_FORM_UPGRADE_SUCCESS"
export const POST_FORM_UPGRADE_FAIL = "POST_FORM_UPGRADE_FAIL"
export const CLEAR_SEARCH_MOG = "CLEAR_SEARCH_MOG"
export const SET_UPGRADE_LIST = "SET_UPGRADE_LIST"

// Forgot Password
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"

// Statistic Membership AOG MOG
    export const GET_STATISTIC_MEMBER_START = "GET_STATISTIC_MEMBER_START"
    export const GET_STATISTIC_MEMBER = "GET_STATISTIC_MEMBER"
    export const GET_STATISTIC_MEMBER_FAIL = "GET_STATISTIC_MEMBER_FAIL"

    export const GET_STATISTIC_V3_MEMBER_START = "GET_STATISTIC_V3_MEMBER_START"
    export const GET_STATISTIC_V3_MEMBER = "GET_STATISTIC_V3_MEMBER"
    export const GET_STATISTIC_V3_MEMBER_FAIL = "GET_STATISTIC_V3_MEMBER_FAIL"

    export const GET_STATISTIC_GRAFIK_MONTHLY_START = "GET_STATISTIC_GRAFIK_MONTHLY_START"
    export const GET_STATISTIC_GRAFIK_MONTHLY_SUCCESS = "GET_STATISTIC_GRAFIK_MONTHLY_SUCCESS"
    export const GET_STATISTIC_GRAFIK_MONTHLY_FAIL = "GET_STATISTIC_GRAFIK_MONTHLY_FAIL"

    export const GET_STATISTIC_GRAFIK_YEARLY_START = "GET_STATISTIC_GRAFIK_YEARLY_START"
    export const GET_STATISTIC_GRAFIK_YEARLY_SUCCESS = "GET_STATISTIC_GRAFIK_YEARLY_SUCCESS"
    export const GET_STATISTIC_GRAFIK_YEARLY_FAIL = "GET_STATISTIC_GRAFIK_YEARLY_FAIL"

    export const GET_INPUT_TARGET_ACTIVE_START = "GET_INPUT_TARGET_ACTIVE_START"
    export const GET_INPUT_TARGET_ACTIVE_SUCCESS = "GET_INPUT_TARGET_ACTIVE_SUCCESS"
    export const GET_INPUT_TARGET_ACTIVE_FAIL = "GET_INPUT_TARGET_ACTIVE_FAIL"

    export const GET_INPUT_TARGET_UPGRADE_ACTIVE_START = "GET_INPUT_TARGET_UPGRADE_ACTIVE_START"
    export const GET_INPUT_TARGET_UPGRADE_ACTIVE_SUCCESS = "GET_INPUT_TARGET_UPGRADE_ACTIVE_SUCCESS"
    export const GET_INPUT_TARGET_UPGRADE_ACTIVE_FAIL = "GET_INPUT_TARGET_UPGRADE_ACTIVE_FAIL"

    export const ADD_INPUT_TARGET_ACTIVE_START = "ADD_INPUT_TARGET_ACTIVE_START"
    export const ADD_INPUT_TARGET_ACTIVE_SUCCESS = "ADD_INPUT_TARGET_ACTIVE_SUCCESS"
    export const ADD_INPUT_TARGET_ACTIVE_FAIL = "ADD_INPUT_TARGET_ACTIVE_FAIL"

    export const ADD_INPUT_TARGET_UPGRADE_ACTIVE_START = "ADD_INPUT_TARGET_UPGRADE_ACTIVE_START"
    export const ADD_INPUT_TARGET_UPGRADE_ACTIVE_SUCCESS = "ADD_INPUT_TARGET_UPGRADE_ACTIVE_SUCCESS"
    export const ADD_INPUT_TARGET_UPGRADE_ACTIVE_FAIL = "ADD_INPUT_TARGET_UPGRADE_ACTIVE_FAIL"

    export const GET_STATISTIC_PPA_START = "GET_STATISTIC_PPA_START"
    export const GET_STATISTIC_PPA_SUCCESS = "GET_STATISTIC_PPA_SUCCESS"
    export const GET_STATISTIC_PPA_FAIL = "GET_STATISTIC_PPA_FAIL"

    export const GET_STATISTIC_BRANCH_START = "GET_STATISTIC_BRANCH_START"
    export const GET_STATISTIC_BRANCH_SUCCESS = "GET_STATISTIC_BRANCH_SUCCESS"
    export const GET_STATISTIC_BRANCH_FAIL = "GET_STATISTIC_BRANCH_FAIL"

// Inquiry Data

    export const GET_LIST_AOG_MOG_START = "GET_LIST_AOG_MOG_START"
    export const GET_LIST_AOG_MOG_SUCCESS = "GET_LIST_AOG_MOG_SUCCESS"
    export const GET_LIST_AOG_MOG_FAIL = "GET_LIST_AOG_MOG_FAIL"
    
    export const GET_DETAIL_AOG_MOG_START = "GET_DETAIL_AOG_MOG_START"
    export const GET_DETAIL_AOG_MOG_SUCCESS = "GET_DETAIL_AOG_MOG_SUCCESS"
    export const GET_DETAIL_AOG_MOG_FAIL = "GET_DETAIL_AOG_MOG_FAIL"

    export const PATCH_AOG_MOG_START = "PATCH_AOG_MOG_START"
    export const PATCH_AOG_MOG_SUCCESS = "PATCH_AOG_MOG_SUCCESS"
    export const PATCH_AOG_MOG_FAIL = "PATCH_AOG_MOG_FAIL"

// Generate ID
    export const GENERATE_ID_LOAD = "GENERATE_ID_LOAD"
    export const SET_LIST_GENERATE_ID = "SET_LIST_GENERATE_ID"
    export const SET_LIST_ID_BRANCH = "SET_LIST_ID_BRANCH"
    export const SET_GENERATE_ID = "SET_GENERATE_ID"
    export const GENERATE_ID_FINISH = "GENERATE_ID_FINISH"

// Setting WA
    export const SET_MESSAGE = "SET_MESSAGE"
    export const SETTING_WA_LOAD = "SETTING_WA_LOAD"
    export const SETTING_WA_FINISH = "SETTING_WA_FINISH"