import {
  SET_MESSAGE,
  SETTING_WA_LOAD,
  SETTING_WA_FINISH,
} from "../actions/types";
import { updateObject } from "../shared/utility";

const initialState = {
  data: {
    upgrade: {
      branch: {
        waiting: {
          message: "",
          auto_send: false,
        },
        confirmed: {
          message: "",
          auto_send: false,
        },
        rejected: {
          message: "",
          auto_send: false,
        },
      },
      user: {
        waiting: {
          message: "",
          auto_send: false,
        },
        confirmed: {
          message: "",
          auto_send: false,
        },
        rejected: {
          message: "",
          auto_send: false,
        },
      },
      preferal: {
        waiting: {
          message: "",
          auto_send: false,
        },
        confirmed: {
          message: "",
          auto_send: false,
        },
        rejected: {
          message: "",
          auto_send: false,
        },
      },
    },
    create: {
      branch: {
        waiting: {
          message: "",
          auto_send: false,
        },
        confirmed: {
          message: "",
          auto_send: false,
        },
        rejected: {
          message: "",
          auto_send: false,
        },
      },
      user: {
        waiting: {
          message: "",
          auto_send: false,
        },
        confirmed: {
          message: "",
          auto_send: false,
        },
        rejected: {
          message: "",
          auto_send: false,
        },
      },
      preferal: {
        waiting: {
          message: "",
          auto_send: false,
        },
        confirmed: {
          message: "",
          auto_send: false,
        },
        rejected: {
          message: "",
          auto_send: false,
        },
      },
    },
  },
  loading: false,
};

const setMessage = (state, action) => {
  return updateObject(state, {
    data: {
      ...state.data,
      [action.payload.action_on]: {
        ...state.data[action.payload.action_on],
        [action.payload.type]: {
          ...state.data[action.payload.action_on][action.payload.type],
          [action.payload.status === "0"
            ? "waiting"
            : action.payload.status === "1"
            ? "confirmed"
            : "rejected"]: {
            message: action.payload.message,
            auto_send: action.payload.auto_send === 1,
          },
        },
      },
    },
  });
};

const loading = (state, action) => {
  return updateObject(state, { loading: true });
};

const finish = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return setMessage(state, action);
    case SETTING_WA_LOAD:
      return loading(state, action);
    case SETTING_WA_FINISH:
      return finish(state, action);
    default:
      return state;
  }
};

export default reducer;
