import {
  GENERATE_ID_LOAD,
  GENERATE_ID_FINISH,
  SET_LIST_GENERATE_ID,
  SET_LIST_ID_BRANCH,
  SET_GENERATE_ID,
} from "../actions/types";
import { updateObject } from "../shared/utility";

const initialState = {
  // List All
  list: {
    data: [],
    lastPage: 1,
    total: 0,
    dataLength: 0,
  },
  // Form Register
  id: {
    data: [],
  },
  // List in Cabang
  branch: {
    data: [],
    lastPage: 1,
    total: 0,
  },
  loading: false,
  loadingGenerate: false,
};

const generateLoad = (state, action) => {
  return updateObject(state, { loading: true });
};

const generateList = (state, action) => {
  return updateObject(state, {
    list: {
      data: action.payload,
      lastPage: action.last_page,
      total: action.total,
      dataLength: action.dataLength,
    },
  });
};

const setListIdBranch = (state, action) => {
  return updateObject(state, {
    branch: {
      data: action.payload,
      lastPage: action.last_page,
      total: action.total,
    },
  });
};

const generateId = (state, action) => {
  return updateObject(state, {
    id: { data: action.payload },
  });
};

const generateFinish = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_ID_LOAD:
      return generateLoad(state, action);
    case SET_LIST_GENERATE_ID:
      return generateList(state, action);
    case SET_LIST_ID_BRANCH:
      return setListIdBranch(state, action);
    case SET_GENERATE_ID:
      return generateId(state, action);
    case GENERATE_ID_FINISH:
      return generateFinish(state, action);
    default:
      return state;
  }
};

export default reducer;
