import * as types from '../actions/types';
import { updateObject } from '../shared/utility';

const initialState = {
  paymentReceiptsUpgrade: {
    paymentReceiptsUpgrade: [],
    current_page: 1,
    per_page: 0,
    total: 0,
    from: 0,
  },
  paymentReceiptUpgrade: {},
  loading: false,
  error: {},
  changing: 0,
};

const fetchPaymentReceiptUpgradeStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchPaymentReceiptUpgradeSuccess = (state, action) => {
  return updateObject(state, {
    paymentReceiptsUpgrade: action.paymentReceiptsUpgrade,
    loading: false
  })
}

const fetchPaymentReceiptUpgradeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const showPaymentReceiptUpgradeStart = (state, action) => {
  return updateObject(state, { 
    paymentReceiptUpgrade: {},
    loading: true 
  })
}

const showPaymentReceiptUpgradeSuccess = (state, action) => {
  return updateObject(state, {
    paymentReceiptUpgrade: action.paymentReceiptUpgrade,
    loading: false
  })
}

const showPaymentReceiptUpgradeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_UPGRADE_HISTORY_START: return fetchPaymentReceiptUpgradeStart(state, action)
    case types.FETCH_PAYMENT_UPGRADE_HISTORY_SUCCESS: return fetchPaymentReceiptUpgradeSuccess(state, action)
    case types.FETCH_PAYMENT_UPGRADE_HISTORY_FAIL: return fetchPaymentReceiptUpgradeFail(state, action)

    case types.SHOW_PAYMENT_UPGRADE_HISTORY_START: return showPaymentReceiptUpgradeStart(state, action)
    case types.SHOW_PAYMENT_UPGRADE_HISTORY_SUCCESS: return showPaymentReceiptUpgradeSuccess(state, action)
    case types.SHOW_PAYMENT_UPGRADE_HISTORY_FAIL: return showPaymentReceiptUpgradeFail(state, action)
    default: return state
  }
}

export default reducer