import React, { useEffect, useState, forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Grid,  
  Typography,
  Card as CardContainer,
  CardContent,
  CardHeader,
	FormControl,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	TextField
} from '@material-ui/core';
import {
  Card,
  CardPercentage,
  LineChart,
	LineChartMonthly,
	TableRanking,
	LineChartMonthlyUpgrade,
	LineChartYearlyUpgrade,
	TableBranch
} from './components';
import { Fragment } from 'react';
import moment from 'moment'
import { Link as RouterLink } from 'react-router-dom'

// redux
import { connect } from 'react-redux'
import * as actions from '../../actions'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		}
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute',
    // zIndex: 0
  },
  cardMobile: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    width: '100%'
	},
	cardContainerMargin: {
		marginBottom: theme.spacing(1)
	},
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  executiveSummary: {
    marginBottom: theme.spacing(2)
  },
  btn: {
    backgroundColor: '#FF9300',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFA938',
      opacity: 1,
    },
		textTransform: 'none',
	},
	textSubtitle: {
		color: '#757575',
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2)
	},
	formControl: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(2)
	}
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <RouterLink {...props} />
    </div>
  ));

const StatisticMember = props => {
  const classes = useStyles();
  const {
    onFetchStatisticMembership,
    countStatistic,
		loadingStatistic,
		countStatisticV3,
		loadingStatisticV3,
		onFetchStatisticV3Membership,
		onFetchGrafikMonthlyStatisticMembership,
		grafikMonthlyStatistic,
		loadingGrafikMonthlyStatistic,
		onFetchGrafikYearlyStatisticMembership,
		grafikYearlyStatistic,
		loadingGrafikYearlyStatistic,
		onFetchStatisticPPA,
		loadingStatisticPPA,
		statisticPPA,
		onFetchStatisticBranch,
		statisticBranch,
		loadingStatisticBranch
  } = props

  const labelChartTargetMonthly = ['1 jan 2021','2 jan 2021','3 jan 2021','4 jan 2021','5 jan 2021','6 jan 2021','7 jan 2021','8 jan 2021','9 jan 2021','10 jan 2021','11 jan 2021','12 jan 2021']
  const labelChartTargetYearly = ['januari','februari','maret','april','mei','juni','juli','agustus','september','oktober','november','desember']

	// target monthly pick Year
	const selectedDate  = useState(new Date());

	const submitDetailMonthly = moment().format('YYYY');

	const [ year, setYear ] = useState({
			submit: {
					submit: submitDetailMonthly
			},
			view: {selectedDate}
    });

		const [ month, setMonth ] = useState(1)
		const [ branchGrafikMonthly, setBranchGrafikMonthly ] = useState({
			id: '',
			name: 'All Branch'
		})
	// End Target monthly pick Year

	// target yearly pick Year

	const submitYearYearly = moment().format('YYYY');

	const [ yearYearly, setYearYearly ] = useState({
			submit: {
					submit: submitYearYearly
			},
			view: {selectedDate}
	});
	const [ branchGrafikYearly, setBranchGrafikYearly ] = useState({
		id: '',
		name: 'All Branch'
	})
	// End Target yearly pick Year

	// Branch
	const [ branch, setBranch ] = useState({
		id : "",
		name: "All Branch"
	})

	const handleChangeBranch = event => {
		
		setBranch({
			id: event.id,
			name: event.name
		})
		setOpenDialogBranch(false)
	}

	// Dialog Branch
	const [ openDialogBranch, setOpenDialogBranch ] = useState(false)

	// Grafik Monthly
	useEffect(() => {
		onFetchGrafikMonthlyStatisticMembership(month, year.submit.submit, branchGrafikMonthly.id)
	}, [
		year, 
		month,
		branchGrafikMonthly,
		onFetchGrafikMonthlyStatisticMembership
	])

	// Grafik Yearly
	useEffect(() => {
		onFetchGrafikYearlyStatisticMembership(yearYearly.submit.submit, branchGrafikYearly.id)
	}, [
		onFetchGrafikYearlyStatisticMembership,
		yearYearly,
		branchGrafikYearly
	])

	useEffect(() => {
		onFetchStatisticV3Membership(1, branch.id)
		onFetchStatisticBranch()
	}, [ onFetchStatisticV3Membership, onFetchStatisticBranch, branch ])

	useEffect(() => {
			onFetchStatisticMembership()
			onFetchStatisticPPA()
	}, [
		onFetchStatisticMembership, 
		onFetchStatisticPPA,
	])

	const userData = JSON.parse(sessionStorage.getItem('data'))

  return (
    <Fragment>
        <div className={classes.root}>
            <div className={classes.bgColor}></div>
						<Grid
							container
							spacing={2}
							justify="space-between"
						>
							<Grid item>  
									<Typography variant="h4">AOG & MOG Statistic</Typography>
							</Grid>
						</Grid>
            <Grid
                container
                spacing={2}
                alignItems="flex-start" 
                justify="flex-end"
            >
								{userData.group_user.name === 'SBS' && (
									<Grid item >  
											<Button className={classes.btn} component={CustomRouterLink} to='/statistic-member/create-target'>
													Input Target
											</Button>
									</Grid>
								)}
								<Grid item>
										<Button className={classes.btn} component={CustomRouterLink} to='/statistic-member/history-target'>
                        History Target
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
            > 
								<Grid item lg={4} md={4} sm={6} xs={12}>
										{loadingStatistic || countStatistic === null ? (
												<Skeleton></Skeleton>
										):(
												<Card nameCard='All Branch EOA Gold' iconType='AllBranch' countPending={countStatistic.total_branch} />
										)}
								</Grid>
								<Grid item lg={4} md={4} sm={6} xs={12}>
										{loadingStatistic || countStatistic === null ? (
												<Skeleton></Skeleton>
										):(
												<Card nameCard='Total Agent of Gold' iconType='TotalAOG' countPending={countStatistic.total_aog} type="number" />
										)}
								</Grid>
								<Grid item lg={4} md={4} sm={6} xs={12}>
										{loadingStatistic || countStatistic === null ? (
												<Skeleton></Skeleton>
										):(
												<Card nameCard='Total Member of Gold' iconType='TotalMOG' countPending={countStatistic.total_mog} type="number" />
										)}
								</Grid>
            </Grid>
						<Grid
                container
                spacing={2}
            > 
                <Grid item lg={12} md={12} sm={12} xs={12}>
									<CardContainer className={classes.cardContainerMargin}> 
										<CardHeader
											title="Membership Joined PPA Private Class"
										/>
										<CardContent>
											<Grid
												container
												spacing={2}
											>
												<Grid item lg={4} md={4} sm={6} xs={12}>
													{loadingStatisticPPA || statisticPPA === null ? (
														<Skeleton></Skeleton>
													):(
														<Card nameCard='AOG Have Joined' countPending={statisticPPA.aog_sudah_ppa} />
													)}
												</Grid>
												<Grid item lg={4} md={4} sm={6} xs={12}>
													{loadingStatisticPPA || statisticPPA === null ? (
														<Skeleton></Skeleton>
													):(
														<Card nameCard='MOG Have Joined' countPending={statisticPPA.mog_sudah_ppa} type="number" />
													)}
												</Grid>
												<Grid item lg={4} md={4} sm={6} xs={12}>
													{loadingStatisticPPA || statisticPPA === null ? (
														<Skeleton></Skeleton>
													):(
														<Card nameCard='AOG Attendees' countPending={statisticPPA.aog_akan_ppa} type="number" />
													)}
												</Grid>
											</Grid>
										</CardContent>
									</CardContainer>
                </Grid>
            </Grid>
						<Grid
							container
							spacing={2}
						>
							<Grid item lg={12} md={12} sm={12} xs={12}>
									<CardContainer className={classes.cardContainerMargin}>
										<CardHeader
											title="Membership Fee"
											action={
												<FormControl className={classes.formControl}>
														{/* <InputLabel htmlFor="age-native-simple">Choose Branch</InputLabel> */}
														{loadingStatisticBranch || statisticBranch === null ? (
															<Skeleton></Skeleton>
														):(
															<TextField
																value={branch.name}
																onClick={() => setOpenDialogBranch(true)}
															/>
														)}
												</FormControl>
											}
										/>
										<CardContent>
											<Grid
													container
													spacing={2}
											>
													<Grid item lg={3} md={3} sm={6} xs={12}>
														{loadingStatisticV3 || countStatisticV3 === null ? (
															<Skeleton></Skeleton>
														):(
															<Card nameCard='Registration Fee AOG' countPending={countStatisticV3.biaya_daftar[0].biaya_daftar_aog} type="currency" />
														)}
													</Grid>
													<Grid item lg={3} md={3} sm={6} xs={12}>
														{loadingStatisticV3 || countStatisticV3 === null ? (
															<Skeleton></Skeleton>
														):(
															<Card nameCard='Registration Fee MOG' countPending={countStatisticV3.biaya_daftar[0].biaya_daftar_mog} type="currency" />
														)}
													</Grid>
													<Grid item lg={3} md={3} sm={6} xs={12}>
														{loadingStatisticV3 || countStatisticV3 === null ? (
															<Skeleton></Skeleton>
														):(
															<Card nameCard='Upgrade Fee AOG' countPending={countStatisticV3.biaya_upgrade[0].biaya_aog_upgrade} type="currency" />
														)}
													</Grid>
													<Grid item lg={3} md={3} sm={6} xs={12}>
														{loadingStatisticV3 || countStatisticV3 === null ? (
															<Skeleton></Skeleton>
														):(
															<Card nameCard='Upgrade Fee MOG' countPending={countStatisticV3.biaya_upgrade[0].biaya_mog_upgrade} type="currency" />
														)}
													</Grid>
											</Grid>
										</CardContent>
									</CardContainer>
							</Grid>
						</Grid>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                >
                    <CardContainer className={classes.cardContainerMargin}>
                        <CardHeader 
														action={
															<Typography variant="subtitle2" gutterBottom className={classes.textSubtitle}>
																{moment().format('yyyy')}
															</Typography>
														}
														title="Target Year To Date"
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.pendaftaran_aog_tahunan.jumlah_pendaftaran_aog_tahunan} of ${countStatistic.pendaftaran_aog_tahunan.jumlah_target_aog_tahunan}`} 
                                            nameCard='Registration AOG Target' 
                                            countPending={countStatistic.pendaftaran_aog_tahunan.persentase_pendaftaran_aog_tahunan} 
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.pendaftaran_mog_tahunan.jumlah_pendaftaran_mog_tahunan} of ${countStatistic.pendaftaran_mog_tahunan.jumlah_target_mog_tahunan}`} 
                                            nameCard='Registration MOG Target' 
                                            countPending={countStatistic.pendaftaran_mog_tahunan.persentase_pendaftaran_mog_tahunan} 
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.upgrade_aog_tahunan.jumlah_upgrade_aog_tahunan} of ${countStatistic.upgrade_aog_tahunan.jumlah_target_upgrade_aog_tahunan}`} 
                                            nameCard='Upgrade AOG Target' 
                                            countPending={countStatistic.upgrade_aog_tahunan.persentase_upgrade_aog_tahunan} 
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.upgrade_mog_tahunan.jumlah_upgrade_mog_tahunan} of ${countStatistic.upgrade_mog_tahunan.jumlah_target_upgrade_mog_tahunan}`} 
                                            nameCard='Upgrade MOG Target' 
                                            countPending={countStatistic.upgrade_mog_tahunan.persentase_upgrade_mog_tahunan} 
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardContainer>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                >
                    <CardContainer>
                        <CardHeader 
														title="Target Month To Date"
														action={
															<Typography variant="subtitle2" gutterBottom className={classes.textSubtitle}>
																{moment().format('MMMM yyyy')}
															</Typography>
														}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.pendaftaran_aog_bulanan.jumlah_pendaftaran_aog_bulanan} of ${countStatistic.pendaftaran_aog_bulanan.target_aog_bulanan}`} 
                                            nameCard='Registration AOG Target' 
                                            countPending={countStatistic.pendaftaran_aog_bulanan.persentase_pendaftaran_aog_bulanan} 
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.pendaftaran_mog_bulanan.jumlah_pendaftaran_mog_bulanan} of ${countStatistic.pendaftaran_mog_bulanan.target_mog_bulanan}`} 
                                            nameCard='Registration MOG Target' 
                                            countPending={countStatistic.pendaftaran_mog_bulanan.persentase_pendaftaran_mog_bulanan} 
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.upgrade_aog_bulanan.jumlah_upgrade_aog_bulanan} of ${countStatistic.upgrade_aog_bulanan.target_upgrade_aog_bulanan}`}  
                                            nameCard='Upgrade AOG Target' 
                                            countPending={countStatistic.upgrade_aog_bulanan.persentase_upgrade_aog_bulanan} 
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {loadingStatistic || countStatistic === null ? (
                                        <Skeleton></Skeleton>
                                    ):(
                                        <CardPercentage 
                                            target={`${countStatistic.upgrade_mog_bulanan.jumlah_upgrade_mog_bulanan} of ${countStatistic.upgrade_mog_bulanan.target_upgrade_mog_bulanan}`} 
                                            nameCard='Upgrade MOG Target' 
                                            countPending={countStatistic.upgrade_mog_bulanan.persentase_upgrade_mog_bulanan} 
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardContainer>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
            > 
                <Grid item lg={8} md={8} sm={8} xs={12}>
									{loadingGrafikYearlyStatistic || grafikYearlyStatistic === null ? (
										<Skeleton></Skeleton>
									):(
										<LineChart 
											yearYearly={yearYearly} 
											setYearYearly={setYearYearly} 
											chartName='Yearly Registration Transaction of AOG & MOG' 
											label={labelChartTargetYearly} 
											branchGrafikYearly={branchGrafikYearly}
											setBranchGrafikYearly={setBranchGrafikYearly}
											grafikYearlyStatistic={grafikYearlyStatistic}
										/>
									)}
                </Grid>
								<Grid item lg={4} md={4} sm={4} xs={12}>
									{loadingStatisticV3 || countStatisticV3 === null ? (
										<Skeleton></Skeleton>
									):(
                    <TableRanking topBranch={countStatisticV3.top_branch} />
									)}
                </Grid>
						</Grid>
						<Grid container spacing={2}>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									{ loadingGrafikYearlyStatistic || grafikYearlyStatistic === null ? (
										<Skeleton></Skeleton>
									):(
										<LineChartYearlyUpgrade
											yearYearly={yearYearly} 
											setYearYearly={setYearYearly} 
											chartName='Yearly Upgrade Transaction of AOG & MOG' 
											label={labelChartTargetYearly} 
											branchGrafikYearly={branchGrafikYearly}
											setBranchGrafikYearly={setBranchGrafikYearly}
											grafikYearlyStatistic={grafikYearlyStatistic}
										/>
									)}
                </Grid>
						</Grid>
						<Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
									{loadingGrafikMonthlyStatistic || grafikMonthlyStatistic === null ? (
										<Skeleton></Skeleton>
									):(
										<LineChartMonthly 
											month={month} 
											setMonth={setMonth} 
											year={year} 
											setYear={setYear} 
											chartName='Monthly Registration Transaction of AOG & MOG' 
											label={labelChartTargetMonthly} 
											branchGrafikMonthly={branchGrafikMonthly}
											setBranchGrafikMonthly={setBranchGrafikMonthly}
											grafikMonthlyStatistic={grafikMonthlyStatistic}
										/>
									)}
                </Grid>
						</Grid>
						<Grid container spacing={2}>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									{loadingGrafikMonthlyStatistic || grafikMonthlyStatistic === null ? (
										<Skeleton></Skeleton>
									):(
										<LineChartMonthlyUpgrade
											month={month} 
											setMonth={setMonth} 
											year={year} 
											setYear={setYear} 
											chartName='Monthly Upgrade Transaction of AOG & MOG' 
											label={labelChartTargetMonthly} 
											branchGrafikMonthly={branchGrafikMonthly}
											setBranchGrafikMonthly={setBranchGrafikMonthly}
											grafikMonthlyStatistic={grafikMonthlyStatistic}
										/>
									)}
                </Grid>
            </Grid>
        </div>
				<Dialog
					open={openDialogBranch}
					onClose={() => setOpenDialogBranch(false)}
				>
					<DialogContent>
						<TableBranch handleChangeBranch={handleChangeBranch} />
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenDialogBranch(false)}>
							Batal
						</Button>
					</DialogActions>
				</Dialog>
    </Fragment>
  )
};

const mapStateToProps = state => ({
    loadingStatistic: state.statisticMembership.loadingStatistic,
    countStatistic: state.statisticMembership.countStatistic,
    loadingStatisticV3: state.statisticMembership.loadingStatisticV3,
    countStatisticV3: state.statisticMembership.countStatisticV3,
    grafikMonthlyStatistic: state.statisticMembership.grafikMonthlyStatistic,
		loadingGrafikMonthlyStatistic: state.statisticMembership.loadingGrafikMonthlyStatistic,
		grafikYearlyStatistic: state.statisticMembership.grafikYearlyStatistic,
		loadingGrafikYearlyStatistic: state.statisticMembership.loadingGrafikYearlyStatistic,
		statisticPPA: state.statisticMembership.statisticPPA,
		loadingStatisticPPA: state.statisticMembership.loadingStatisticPPA,
		statisticBranch: state.statisticMembership.statisticBranch,
		loadingStatisticBranch: state.statisticMembership.loadingStatisticBranch
})

const mapDispatchToProps = dispatch => {
    return {
      onFetchStatisticMembership: () => dispatch(actions.fetchStatisticMembership()),
      onFetchStatisticV3Membership: (month, branch) => dispatch(actions.fetchStatisticV3Membership(month, branch)),
      onFetchGrafikMonthlyStatisticMembership: (month, year, branch) => dispatch(actions.fetchGrafikMonthlyStatisticMembership(month, year, branch)),
      onFetchGrafikYearlyStatisticMembership: (year, branch) => dispatch(actions.fetchGrafikYearlyStatisticMembership(year, branch)),
			onFetchStatisticPPA: () => dispatch(actions.fetchStatisticPPA()),
			onFetchStatisticBranch : (page) => dispatch(actions.fetchStatisticBranch(page))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticMember);
