import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";

const useStyles = makeStyles((theme) => ({
  type: {
    padding: theme.spacing(0.5, 1),
    borderRadius: "2px",
    width: "50%",
  },
  aog: {
    backgroundColor: "#46C057",
  },
  mog: {
    backgroundColor: "#56AFF6",
  },
  textType: {
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const TableComponent = ({ data, sort, setSort }) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>No ID</Typography>
              <Tooltip
                title={sort === "asc" ? "Ascending" : "Descending"}
                placement="right"
              >
                <IconButton
                  onClick={() => {
                    sort === "asc" ? setSort("desc") : setSort("asc");
                  }}
                >
                  <SwapVertIcon />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>
          <TableCell>Tipe</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.loading ? (
          <TableRow>
            <TableCell colSpan={3} align="center">
              <CircularProgress color="primary" />
            </TableCell>
          </TableRow>
        ) : (
          data.branch.data.map((value) => (
            <TableRow>
              <TableCell>{value.generate_id}</TableCell>
              <TableCell>
                <div
                  className={clsx(
                    classes.type,
                    value.type === "aog" && classes.aog,
                    value.type === "mog" && classes.mog
                  )}
                >
                  <Typography className={classes.textType}>
                    {value.type}
                  </Typography>
                </div>
              </TableCell>
              <TableCell>
                {value.status === "available" ? "Tersedia" : "Sudah digunakan"}
              </TableCell>
            </TableRow>
          ))
        )}
        {data.branch.data.length === 0 && !data.loading && (
          <TableRow>
            <TableCell colSpan={3} align="center">
              Data tidak tersedia
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableComponent;
