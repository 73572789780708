import * as types from '../actions/types';
import { updateObject } from '../shared/utility';

const initialState = {
  countStatistic: null,
  countStatisticV3: null,
  grafikMonthlyStatistic: null,
  grafikYearlyStatistic: null,
  inputTargetActive: null,
  inputTargetUpgradeActive: null,
  statisticPPA: null,
  statisticBranch: null,
  loadingStatisticBranch: false,
  loadingStatisticPPA: false,
  loadingInputTargetUpgradeActive: false,
  loadingInputTargetActive: false,
  loadingGrafikYearlyStatistic: false,
  loadingGrafikMonthlyStatistic: false,
  loadingStatisticV3: false,
  loadingStatistic: false,
  error: {}
};

const fetchStatisticMembershipStart = (state, action) => {
  return updateObject(state, { loadingStatistic: true })
}

const fetchStatisticMembership = (state, action) => {
  return updateObject(state, {
    countStatistic: action.countStatistic,
    loadingStatistic: false
  })
}

const fetchStatisticMembershipFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingStatistic: false
  })
}

// Get Branch Statistic
const fetchStatisticBranchStart = (state, action) => {
  return updateObject(state, { loadingStatisticBranch: true })
}

const fetchStatisticBranch = (state, action) => {
  return updateObject(state, {
    statisticBranch: action.statisticBranch,
    loadingStatisticBranch: false
  })
}

const fetchStatisticBranchFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingStatisticBranch: false
  })
}

// V3
const fetchStatisticV3MembershipStart = (state, action) => {
    return updateObject(state, { loadingStatisticV3: true })
}

const fetchStatisticV3Membership = (state, action) => {
  return updateObject(state, {
    countStatisticV3: action.countStatisticV3,
    loadingStatisticV3: false
  })
}

const fetchStatisticV3MembershipFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingStatisticV3: false
  })
}

// Grafik Monthly
const fetchGrafikStatisticMonthlyMembershipStart = (state, action) => {
  return updateObject(state, { loadingGrafikMonthlyStatistic: true })
}

const fetchGrafikStatisticMonthlyMembership = (state, action) => {
  return updateObject(state, {
    grafikMonthlyStatistic: action.grafikMonthlyStatistic,
    loadingGrafikMonthlyStatistic: false
  })
}

const fetchGrafikStatisticMonthlyMembershipFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingGrafikMonthlyStatistic: false
  })
}

// Grafik Yearly
const fetchGrafikStatisticYearlyMembershipStart = (state, action) => {
  return updateObject(state, { loadingGrafikYearlyStatistic: true })
}

const fetchGrafikStatisticYearlyMembership = (state, action) => {
  return updateObject(state, {
    grafikYearlyStatistic: action.grafikYearlyStatistic,
    loadingGrafikYearlyStatistic: false
  })
}

const fetchGrafikStatisticYearlyMembershipFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingGrafikYearlyStatistic: false
  })
}

// GET Input Target Registration Active
const fetchInputTargetActiveStart = (state, action) => {
  return updateObject(state, { loadingInputTargetActive: true })
}

const fetchInputTargetActive = (state, action) => {
  return updateObject(state, {
    inputTargetActive: action.inputTargetActive,
    loadingInputTargetActive: false
  })
}

const fetchInputTargetActiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingInputTargetActive: false
  })
}

// GET Input Target Upgrade Active
const fetchInputTargetUpgradeActiveStart = (state, action) => {
  return updateObject(state, { loadingInputTargetUpgradeActive: true })
}

const fetchInputTargetUpgradeActive = (state, action) => {
  return updateObject(state, {
    inputTargetUpgradeActive: action.inputTargetUpgradeActive,
    loadingInputTargetUpgradeActive: false
  })
}

const fetchInputTargetUpgradeActiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingInputTargetUpgradeActive: false
  })
}

// Adding Input Target
const addInputTargetActiveStart = (state, action) => {
  return updateObject(state, { loadingInputTargetActive: true })
}

const addInputTargetActive = (state, action) => {
  return updateObject(state, {
    inputTargetActive: action.inputTargetActive,
    loadingInputTargetActive: false
  })
}

const addInputTargetActiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingInputTargetActive: false
  })
}

// Adding Input Target Upgrade
const addInputTargetUpgradeActiveStart = (state, action) => {
  return updateObject(state, { loadingInputTargetUpgradeActive: true })
}

const addInputTargetUpgradeActive = (state, action) => {
  return updateObject(state, {
    inputTargetUpgradeActive: action.inputTargetUpgradeActive,
    loadingInputTargetUpgradeActive: false
  })
}

const addInputTargetUpgradeActiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingInputTargetUpgradeActive: false
  })
}

// GET Statistic PPA
const fetchStatisticPPAStart = (state, action) => {
  return updateObject(state, { loadingStatisticPPA: true })
}

const fetchStatisticPPA = (state, action) => {
  return updateObject(state, {
    statisticPPA: action.statisticPPA,
    loadingStatisticPPA: false
  })
}

const fetchStatisticPPAFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingStatisticPPA: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATISTIC_MEMBER_START: return fetchStatisticMembershipStart(state, action)
    case types.GET_STATISTIC_MEMBER: return fetchStatisticMembership(state, action)
    case types.GET_STATISTIC_MEMBER_FAIL: return fetchStatisticMembershipFail(state, action)
    case types.GET_STATISTIC_V3_MEMBER_START: return fetchStatisticV3MembershipStart(state, action)
    case types.GET_STATISTIC_V3_MEMBER: return fetchStatisticV3Membership(state, action)
    case types.GET_STATISTIC_V3_MEMBER_FAIL: return fetchStatisticV3MembershipFail(state, action)
    case types.GET_STATISTIC_GRAFIK_MONTHLY_START: return fetchGrafikStatisticMonthlyMembershipStart(state, action)
    case types.GET_STATISTIC_GRAFIK_MONTHLY_SUCCESS: return fetchGrafikStatisticMonthlyMembership(state, action)
    case types.GET_STATISTIC_GRAFIK_MONTHLY_FAIL: return fetchGrafikStatisticMonthlyMembershipFail(state, action)
    case types.GET_STATISTIC_GRAFIK_YEARLY_START: return fetchGrafikStatisticYearlyMembershipStart(state, action)
    case types.GET_STATISTIC_GRAFIK_YEARLY_SUCCESS: return fetchGrafikStatisticYearlyMembership(state, action)
    case types.GET_STATISTIC_GRAFIK_YEARLY_FAIL: return fetchGrafikStatisticYearlyMembershipFail(state, action)

    case types.GET_INPUT_TARGET_ACTIVE_START: return fetchInputTargetActiveStart(state, action)
    case types.GET_INPUT_TARGET_ACTIVE_SUCCESS: return fetchInputTargetActive(state, action)
    case types.GET_INPUT_TARGET_ACTIVE_FAIL: return fetchInputTargetActiveFail(state, action)

    case types.GET_INPUT_TARGET_UPGRADE_ACTIVE_START: return fetchInputTargetUpgradeActiveStart(state, action)
    case types.GET_INPUT_TARGET_UPGRADE_ACTIVE_SUCCESS: return fetchInputTargetUpgradeActive(state, action)
    case types.GET_INPUT_TARGET_UPGRADE_ACTIVE_FAIL: return fetchInputTargetUpgradeActiveFail(state, action)

    case types.ADD_INPUT_TARGET_ACTIVE_START: return addInputTargetActiveStart(state, action)
    case types.ADD_INPUT_TARGET_ACTIVE_SUCCESS: return addInputTargetActive(state, action)
    case types.ADD_INPUT_TARGET_ACTIVE_FAIL: return addInputTargetActiveFail(state, action)

    case types.ADD_INPUT_TARGET_UPGRADE_ACTIVE_START: return addInputTargetUpgradeActiveStart(state, action)
    case types.ADD_INPUT_TARGET_UPGRADE_ACTIVE_SUCCESS: return addInputTargetUpgradeActive(state, action)
    case types.ADD_INPUT_TARGET_UPGRADE_ACTIVE_FAIL: return addInputTargetUpgradeActiveFail(state, action)

    case types.GET_STATISTIC_PPA_START: return fetchStatisticPPAStart(state, action)
    case types.GET_STATISTIC_PPA_SUCCESS: return fetchStatisticPPA(state, action)
    case types.GET_STATISTIC_PPA_FAIL: return fetchStatisticPPAFail(state, action)

    case types.GET_STATISTIC_BRANCH_START: return fetchStatisticBranchStart(state, action)
    case types.GET_STATISTIC_BRANCH_SUCCESS: return fetchStatisticBranch(state, action)
    case types.GET_STATISTIC_BRANCH_FAIL: return fetchStatisticBranchFail(state, action)
    default: return state
  }
}

export default reducer