import React, { useEffect, useState, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
	Grid,
	Typography,
	Tooltip,
	IconButton,
	TablePagination,
	Select,
	InputLabel
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import DetailIcon from '@material-ui/icons/Search'
import { Link as RouterLink } from 'react-router-dom'
import SearchInput from "../../components/SearchInput";

import { connect } from 'react-redux'
import * as actions from '../../actions'

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		}
	},
  table: {
    minWidth: 650,
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <RouterLink {...props} />
    </div>
));

const InquiryData = props => {
  const classes = useStyles();
	const {
		loading,
		listAogMog,
		onFetchListAogMog
	} = props

	const [ page, setPage ] = useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("name");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

	// const handleSeact = (e) => {
  //   if (e.key === "Enter") {
  //     if (searchType === "name") {
  //       getSearchByName(search, sessionStorage.getItem("access_token"));
  //     } else {
  //       getSearchByID(search, sessionStorage.getItem("access_token"));
  //     }
  //   }
  // };

	useEffect(() => {
		onFetchListAogMog(page+1, search, searchType)
	}, [onFetchListAogMog, page, search, searchType])

	let no = 1
	if(!loading && listAogMog !== null){
		no = listAogMog.from
	}

  return (
		<div className={classes.root}>
			<Grid container spacing={2}>
				<Grid item>
					<Typography variant="h4">Inquiry Data</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={6} md={3}>
					<SearchInput
						placeholder="Cari Anggota"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						// onKeyUp={handleSeact}
					/>
				</Grid>
				<Grid item xs={6} md={3}>
					<InputLabel>Cari Berdasarkan</InputLabel>
					{/* <Paper className={classes.paperStyle}> */}
					<Select
						native
						fullWidth
						// size="small"
						value={searchType}
						onChange={(e) => setSearchType(e.target.value)}
					>
						<option value=""></option>
						<option value="no_id">No ID</option>
						<option value="name">Nama</option>
					</Select>
					{/* </Paper> */}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<TableContainer component={Paper}>
						<Table className={classes.table} size="small" aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>No</TableCell>
									<TableCell>Nama</TableCell>
									<TableCell>Status Anggota</TableCell>
									<TableCell>No ID</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>No WhatsApp</TableCell>
								</TableRow>
							</TableHead>
							{loading || listAogMog === null ? (
								<TableBody>
									<TableRow>
										<TableCell colSpan={6}>
											<Skeleton></Skeleton>
										</TableCell>
									</TableRow>
								</TableBody>
							):(
								<TableBody>
									{listAogMog.data.map((row) => (
										<TableRow key={row.id}>
											<TableCell>
												{no++}
											</TableCell>
											<TableCell>
												{row.nama_lengkap}
											</TableCell>
											<TableCell>{row.jenis_pendaftaran}</TableCell>
											<TableCell>
												{row.no_id}
											</TableCell>
											<TableCell>{row.email}</TableCell>
											<TableCell>{row.no_wa}</TableCell>
											<TableCell>
												<Tooltip arrow title="Edit">
													<IconButton component={CustomRouterLink} to={`/inquiry-data/edit/${row.id}`}>
														<DetailIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							)}
						</Table>
						{loading || listAogMog === null ? (
							<Skeleton></Skeleton>
						):(
							<TablePagination
								component="div"
								rowsPerPageOptions={[10]}
								count={listAogMog.total}
								page={page}
								onChangePage={handleChangePage}
								rowsPerPage={rowsPerPage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						)}
					</TableContainer>
				</Grid>
			</Grid>
		</div>
  );
}

const mapStateToProps = state => ({
	loading: state.inquiryData.loading,
	listAogMog: state.inquiryData.listAogMog
})

const mapDispatchToProps = dispatch => {
	return {
		onFetchListAogMog: (page, search, type) => dispatch(actions.fetchAOGMOGList(page, search, type))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiryData)