import {
  GENERATE_ID_LOAD,
  SET_LIST_GENERATE_ID,
  SET_LIST_ID_BRANCH,
  SET_GENERATE_ID,
  GENERATE_ID_FINISH,
} from "./types";
import axios from "../axios-orders";
import { setAlert } from "./alert";

const generateIDLoad = () => ({ type: GENERATE_ID_LOAD });

const generateIDFinish = () => ({ type: GENERATE_ID_FINISH });

// Get List ID Aog Mog untuk ESS
export const getListGenerateID =
  (
    type = "",
    status = "",
    page = 1,
    page_size = "",
    order_type = "asc",
    branchID = "",
    keyword = ""
  ) =>
  async (dispatch) => {
    if (branchID == null || branchID === "") {
      branchID = {
        id: "",
      };
    }
    dispatch(generateIDLoad());
    try {
      const response = await axios.get(
        `/user/register_aog_mog/generate-id/request?type=${type}&status=${status}&page=${page}&page_size=${page_size}&order_type=${order_type}&id_branch=${branchID.id}&order_column=generate_id&keyword=${keyword}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
          },
        }
      );
      dispatch(generateIDFinish());
      dispatch({
        type: SET_LIST_GENERATE_ID,
        payload: response.data.data,
        last_page: response.data.last_page,
        total: response.data.total_item,
        dataLength: response.data.data.length,
      });
    } catch (error) {
      dispatch(generateIDFinish());
      dispatch(setAlert("Something wen't wrong", "error"));
    }
  };

// Request ID Aog Mog (ESS)
export const requestGenerateId = (data, setState) => async (dispatch) => {
  try {
    const response = await axios.post(
      `/user/register_aog_mog/generate-id/request`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch(setAlert("ID berhasil di generate"));
    setState(response.data.data);
    dispatch(getListGenerateID());
  } catch (error) {
    dispatch(setAlert("Something wen't wrong", "error"));
  }
};

// Assign ID Aog Mog ke Cabang (ESS)
export const assignId = (data) => async (dispatch) => {
  try {
    await axios.patch(`/user/register_aog_mog/generate-id/request`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("ID berhasil di dikirim ke cabang terpilih"));
    dispatch(getListGenerateID());
  } catch (error) {
    dispatch(setAlert("Something wen't wrong", "error"));
  }
};

// Get list generate ID Aog Mog untuk form register (Cabang)
export const getGenerateID =
  (type, limit, keyword = "") =>
  async (dispatch) => {
    dispatch(generateIDLoad());
    try {
      const response = await axios.get(
        `/user/register_aog_mog/generate-id/dropdown?limit=${limit}&type=${type}&keyword=${keyword}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
          },
        }
      );
      dispatch({
        type: SET_GENERATE_ID,
        payload: response.data.data,
      });
      dispatch(generateIDFinish());
    } catch (error) {
      dispatch(generateIDFinish());
      dispatch(setAlert("ID AOG MOG tidak bisa di generate", "error"));
    }
  };

// Get List ID Aog Mog untuk Cabang
export const getListIDCabang =
  (
    type = "",
    status = "",
    page = 1,
    page_size = 20,
    order_type = "asc",
    keyword = ""
  ) =>
  async (dispatch) => {
    dispatch(generateIDLoad());
    try {
      const response = await axios.get(
        `/user/register_aog_mog/generate-id/request/branch?type=${type}&status=${status}&order_type=${order_type}&page=${page}&page_size=${page_size}&order_column=generate_id&keyword=${keyword}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
          },
        }
      );
      dispatch(generateIDFinish());
      dispatch({
        type: SET_LIST_ID_BRANCH,
        payload: response.data.data,
        last_page: response.data.last_page,
        total: response.data.total_item,
      });
    } catch (error) {
      dispatch(generateIDFinish());
      dispatch(setAlert("Something wen't wrong", "error"));
    }
  };
