/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import {
  Card,
  CardActions,
  CardContent,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { AccountImage } from "./components";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import { editProfile, getProfile } from "../../actions/profile";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Profile = ({ profile, getProfile, editProfile }) => {
  const classes = useStyles();
  const history = useHistory();
  const [base64, setBase64] = useState(null);

  const { register, handleSubmit } = useForm();

  const handleChangeBanner = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = function () {
      setBase64(reader.result);
    };
  };

  useEffect(() => {
    getProfile();
  }, []);

  const onSubmit = (e) => {
    if (base64 !== null) {
      const data = { ...e, image: base64 };
      editProfile(data, history);
    } else {
      editProfile(e, history);
    }
  };

  return profile.loadingGetProfile ? (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Fragment>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <AccountImage handleChangeBanner={handleChangeBanner} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Card>
                {!profile.loadingGetProfile ? (
                  <CardContent>
                    <TextField
                      fullWidth
                      name="name"
                      label="Nama Akun"
                      variant="outlined"
                      defaultValue={profile.profile.name}
                      inputRef={register}
                    />
                  </CardContent>
                ) : (
                  <Skeleton variant="rect"></Skeleton>
                )}
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Simpan
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { editProfile, getProfile })(Profile);
