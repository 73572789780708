import React from 'react'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 0,
  },
  root: {
    // minWidth: 275,
    borderRadius: 8,
    color: '#231E2E'
  },
  icon: {
    textAlign: 'right'
  },
  title: {
    fontSize: 15,
    paddingBottom: 15,
    fontFamily: 'Nunito Sans, Roboto, sans-serif'
  },
  value: {
    fontSize: 25,
    color: '#FF9300',
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
  },
  progressBar: {
    marginTop: theme.spacing(2)
  }
}))

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    // marginTop: theme.spacing(2),
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const CardComponent = props => {
  const { nameCard, countPending, target } = props;
  
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title} gutterBottom> {nameCard} </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography className={classes.value}>
                {countPending} %
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Typography variant='h5' style={{ color: '#FF9300' }}>
                {target}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {countPending >= 100 ? (
                <BorderLinearProgress className={classes.progressBar} variant="determinate" value={100} />
              ):(
                <BorderLinearProgress className={classes.progressBar} variant="determinate" value={countPending} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default CardComponent;
