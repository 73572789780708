import React, { forwardRef, Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Button,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Backdrop, 
    CircularProgress,
    Paper,
    IconButton,
    Divider,
    TextField,
    Snackbar,
    Tooltip,
    Card,
    CardContent
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import UploadImage from './UploadImage'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import momentTz from 'moment-timezone'

// Components
import RegistrationList from './RegistrationList'

import { connect } from 'react-redux'
import { uploadBukti, getLimitUploadBukti } from '../../actions/form'
import { getConfigTime } from '../../actions/config'
import NumberFormat from 'react-number-format'
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		}
    },
    // cardWarning: {

    // },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
    },
    searchRoot: {
		// padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
		width: '100%',
		marginTop: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 25,
        margin: 4,
    },
}))

const CustomRouterLink = forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <RouterLink {...props} />
    </div>
  ));

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const RegistrationForm = (props) => {
    const classes = useStyles()
    const { 
        uploadBukti, 
        getConfigTime,
        config: {
            configTime,
        },
        getLimitUploadBukti,
        form : { 
            loadingUpload,
            loadingCount,
            countUpload
        } 
    } = props
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const libur = false;

    const { handleSubmit } = useForm()
    const history = useHistory()

    const [banner, setBanner] = useState([]);
    const [base64, setB64] = useState(null);

    const [amount, setAmount] = useState(0)

    const [openDialog, setOpenDialog] = useState(false)

    const handleChangeBanner = event => {
        setBanner(event[0]);
        let reader = new FileReader();
        reader.readAsDataURL(event[0]);
        reader.onload = function(){
            setB64(reader.result);
        }
    };

    const handlePriceChange = event => {
        setAmount(event.target.value);
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
    };

    const onSubmit = e => {
		if(dateToUTC >= startTime && dateToUTC < endTime && getDays < 6){
			if(selected.length === 0 && base64 === null && amount === 0){
				return (
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
						open={openAlert}
						autoHideDuration={3000}
						onClose={handleCloseAlert}
					>
						<Alert severity="error" onClose={handleCloseAlert}>Foto atau Calon AOG/MOG atau Total Biaya belum diisi</Alert>
					</Snackbar>
				)
			}else{
				uploadBukti(base64, selected, amount, history)
				setAmount(0)
				setSelected([])
			}
			setOpen(false)
		}else{
			setOpen(false)
			setOpenDialog(true)
		}
    }

    const handleClickOpen = () => {
		getConfigTime(sessionStorage.getItem("access_token"));
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }
      const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    

    function isEmpty(obj) {
        for(var key in obj) {
          if(obj.hasOwnProperty(key))
            return false;
        }
        return true;
    }

    let startTime = 10;
    let endTime = 23;
    const currentTime = new Date();
    const dateToUTC = momentTz.tz(currentTime, "Asia/Jakarta").hour()

    const getDays = momentTz.tz(currentTime, "Asia/Jakarta").format('E')

    if(!isEmpty(configTime)){
        startTime = parseInt(configTime.open);
        endTime = parseInt(configTime.closed);
    }

    useEffect(() => {
        getLimitUploadBukti()
        getConfigTime(sessionStorage.getItem('access_token'))
    }, [getLimitUploadBukti, getConfigTime])

    return loadingUpload ? 
    <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
    </Backdrop>
    :
    <Fragment>
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
                justify="space-between"
            >
                <Grid item>  
                    <Typography variant="h4">Registration List</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                alignItems="flex-start" 
                justify="flex-end"
            >
                <Grid item>
                    <Button variant="contained" color="primary" size="small" component={CustomRouterLink} to="/upgrade-mog" disabled={libur}>
                        Upgrade
                    </Button>
                </Grid>
                <Grid 
                    item
                >
                    <a
                        href="https://eoatech-oss-1.oss-ap-southeast-5.aliyuncs.com/pos-cabang/asset/Form%20Pendaftaran%20AOG%20MOG.docx"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button variant="outlined" color="primary" size="small">
                            Download Form
                        </Button>
                    </a>
                </Grid>
                {/* <Grid 
                    item
                > */}
                    {/* {!loadingCount && (
                        <>
                            {countUpload.count < 2  ? (
                                <>
                                    {dateToUTC >= startTime && dateToUTC <= endTime && getDays < 6 ? (
                                        <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                                            Upload Bukti Bayar
                                        </Button>
                                    ):(
                                        <Tooltip title="Sudah melewati batas waktu" placement="bottom" onClick={handleOpenDialog} arrow>
                                            <Button variant="outlined" color="primary" size="small">
                                                Upload Bukti Bayar
                                            </Button>
                                        </Tooltip>
                                    )}
                                </>
                            ):(
                                <Tooltip title="Upload bukti pembayaran sudah mencapai limit" onClick={handleOpenDialog} placement="bottom" arrow>
                                    <Button variant="outlined" color="primary" size="small">
                                        Upload Bukti Bayar
                                    </Button>
                                </Tooltip>
                            )}
                        </>
                    )} */}
                {/* </Grid> */}
                <Grid 
                    item
                >  
                    {dateToUTC >= startTime && dateToUTC < endTime && getDays < 6 ? (
                        <Button variant="contained" size="small" color="primary" component={CustomRouterLink} to="/registration-form/pendaftaran" disabled={libur}>
                            + AOG/MOG Baru
                        </Button>
                    ):(
                        <Tooltip title="Sudah melewati batas waktu" onClick={handleOpenDialog} placement="bottom" arrow>
                            <Button variant="contained" color="primary" size="small" disabled={libur}>
                                + AOG/MOG Baru
                            </Button>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid 
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                >
                    <Card variant="outlined" style={{ borderColor: '#ffc400' }}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Peraturan Operasional Upload Bukti Bayar
                            </Typography>
                            <Typography style={{ color: 'red' }}>
                            1. Jam Operational adalah hari kerja Senin - Jum'at,  jam 10.00 WIB sampai dengan jam 16.00 WIB <br/>
                            2. Maksimal Upload Bukti Pembayaran dalam 1 hari adalah 2 kali <br/>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <RegistrationList 
                selected={selected} 
                setSelected={setSelected} 
                dateToUTC={dateToUTC}
                startTime={startTime}
                endTime={endTime}
                getDays={getDays}
                handleClickOpen={handleClickOpen}
                handleOpenDialog={handleOpenDialog}
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">
						Upload Bukti Bayar
						{dateToUTC < startTime && dateToUTC >= endTime && getDays >= 6 && (
							<Typography>Maaf waktu untuk upgrade data telah berakhir!</Typography>
						)}
					</DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item lg={12}>
                                <UploadImage value={banner} handleChangeBanner={handleChangeBanner} />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item lg={12}>
                                <Paper component="form" className={classes.searchRoot}>
                                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                        <Typography variant="subtitle2">Rp</Typography>
                                    </IconButton>
                                    <Divider className={classes.divider} orientation="vertical" />
                                    <NumberFormat
                                        {...props}
                                        value={amount || ''}
                                        name="harga"
                                        customInput={TextField}
                                        type="text"
                                        thousandSeparator
                                        onValueChange={({ value: v }) => handlePriceChange({ target : { name : 'harga', value: v} })}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {banner.length !== 0 ? (
                        <>
                        {dateToUTC >= startTime && dateToUTC < endTime && getDays < 6 ? (
                            <Button type="submit" color="primary">
                                Kirim
                            </Button>
                        ):(
                            <Button type="button" disabled color="primary">
                                Kirim
                            </Button>
                        )}
                        </>
                    ):(
                        <Button disabled color="primary">
                            Kirim
                        </Button>
                    )}
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Peringatan</DialogTitle>
                <DialogContent>
                    {!loadingCount && (
                        <div>
                            {startTime >= dateToUTC || dateToUTC < endTime || getDays < 6 ? (
                                <>
                                {countUpload.count < 2 ? (
                                    <Typography>
                                        Waktu Operasional Senin - Jumat Pukul 10.00 - 16.00 WIB
                                    </Typography>
                                ):(
                                    <Typography>
                                        Upload bukti pembayaran sudah mencapai limit (Maks 2 kali sehari)
                                    </Typography>
                                )}
                                </>
                            ):(
                                <Typography>
                                    Waktu Operasional Senin - Jumat Pukul 10.00 - 16.00 WIB
                                </Typography>
                            )}
                            
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Oke
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </Fragment>
    
}

const mapStateToProps = state => ({
    form: state.form,
    config: state.config
})

export default connect(mapStateToProps, { uploadBukti, getLimitUploadBukti, getConfigTime})(RegistrationForm)