import { Button, FormControl, Grid, InputLabel, makeStyles, Select } from '@material-ui/core';
import React, { Fragment, useState } from 'react';

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import * as actions from '../../../../actions/index';
import { connect } from 'react-redux';

const schema = yup.object().shape({
});

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonDelete: {
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(200, 0, 58)'
    },
    color: '#FFFFFF'
  }
}))

const EditPaymentReceipt = props => {
  const classes = useStyles();

  const { paymentReceipt, onUpdatePayementReceipt } =  props;

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const token = sessionStorage.getItem('access_token');
  
  const onSubmit = data => {
    onUpdatePayementReceipt(paymentReceipt.id, data, token);
    console.log(data);
  }

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()
    
    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: {
          value: event.target.checked
        }
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const [formState, setFormState] = useState({
    image: paymentReceipt.image,
    status: paymentReceipt.status,
    inv_name: paymentReceipt.inv_name,
  });
  
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <img src={paymentReceipt.image} alt="aset" width="100%" height="auto" />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {paymentReceipt.inv_name}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl
              // error={errors.status && true}
              variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
              <Select
                native
                value={formState.status}
                onChange={handleChange}
                label="Pilih Status"
                inputProps={{
                  name: 'status',
                  id: 'outlined-age-native-simple'
                }}
                name="status"
                inputRef={register}
              >
                <option aria-label="None" value="" />
                <option key={0} value={0}>Meninggu Konfirmasi</option>
                <option key={1} value={1}>Terkonfirmasi</option>
              </Select>
              {/* <FormHelperText>{errors.status && errors.status.message}</FormHelperText> */}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button variant="contained" fullWidth onClick={props.closedModalDialog} disableElevation>
                  Batal
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
                  Simpan
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdatePayementReceipt: (id, storeData, token) => dispatch(actions.updatePaymentReceipt(id, storeData, token))
  }
}

export default connect(null, mapDispatchToProps)(EditPaymentReceipt);