import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Box, Tabs, Tab, Grid } from "@material-ui/core";

import { sendMessageWa, getMessageWa } from "../../../../actions/settingWa";
import MessageContent from "./MessageContent";

function TabPanel(props) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={className}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  rootTabsStatus: {
    display: "flex",
    flexGrow: 1,
    marginTop: theme.spacing(2),
    width: "100%",
  },
  boxMessage: {
    padding: theme.spacing(2, 4),
  },
}));

const Message = ({ sendMessageWa, getMessageWa, settingWa }) => {
  const classes = useStyles();

  const [tabsHeader, setTabsHeader] = useState(0);
  const [tabsSubHeader, setTabsSubHeader] = useState(0);
  const [tabsStatus, setTabsStatus] = useState(0);

  const handleTabsHeader = (event, newValue) => {
    setTabsHeader(newValue);
  };

  const handleTabsSubHeader = (event, newValue) => {
    setTabsSubHeader(newValue);
  };

  const handleTabsStatus = (event, newValue) => {
    setTabsStatus(newValue);
  };

  useEffect(() => {
    getMessageWa("create", "branch", 0);
  }, [getMessageWa]);

  return (
    <Card>
      <div>
        <Tabs value={tabsHeader} onChange={handleTabsHeader}>
          <Tab label="+ Aog Mog" {...a11yProps(0)} />
          <Tab label="Upgrade Aog" {...a11yProps(1)} />
        </Tabs>
      </div>

      {/* Tabs SubHeaders */}
      <div>
        {/* Tabs + Aog Mog */}
        <TabPanel value={tabsHeader} index={0}>
          <Tabs value={tabsSubHeader} onChange={handleTabsSubHeader}>
            <Tab label="Cabang" {...a11yProps(0)} />
            <Tab label="Calon Aog Mog" {...a11yProps(1)} />
            <Tab label="Preferal" {...a11yProps(2)} />
          </Tabs>
          {/* Tabs + Aog Mog Cabang */}
          <TabPanel
            value={tabsSubHeader}
            index={0}
            className={classes.rootTabsStatus}
          >
            <Grid item xs={3}>
              <Tabs
                value={tabsStatus}
                orientation="vertical"
                className={classes.tabsVertical}
                onChange={handleTabsStatus}
              >
                <Tab label="Menunggu Konfirmasi" {...a11yProps(0)} />
                <Tab label="Terkonfirmasi" {...a11yProps(1)} />
                <Tab label="Tidak Disetujui" {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              {/* Pesan + Aog Mog | Cabang | Menunggu Konfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={0}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="branch"
                  status={"0"}
                  defaultValue={settingWa.data.create.branch.waiting}
                />
              </TabPanel>

              {/* Pesan + Aog Mog | Cabang | Terkonfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={1}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="branch"
                  status={"1"}
                  defaultValue={settingWa.data.create.branch.confirmed}
                />
              </TabPanel>

              {/* Pesan + Aog Mog | Cabang | Tidak Disetujui */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={2}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="branch"
                  status={"2"}
                  defaultValue={settingWa.data.create.branch.rejected}
                />
              </TabPanel>
            </Grid>
          </TabPanel>

          {/* Tabs + Aog Mog Calon */}
          <TabPanel
            value={tabsSubHeader}
            index={1}
            className={classes.rootTabsStatus}
          >
            <Grid item xs={3}>
              <Tabs
                value={tabsStatus}
                orientation="vertical"
                className={classes.tabsVertical}
                onChange={handleTabsStatus}
              >
                <Tab label="Menunggu Konfirmasi" {...a11yProps(0)} />
                <Tab label="Terkonfirmasi" {...a11yProps(1)} />
                <Tab label="Tidak Disetujui" {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              {/* Pesan + Aog Mog | Calon | Menunggu Konfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={0}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="user"
                  status={"0"}
                  defaultValue={settingWa.data.create.user.waiting}
                />
              </TabPanel>

              {/* Pesan + Aog Mog | Calon | Terkonfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={1}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="user"
                  status={"1"}
                  defaultValue={settingWa.data.create.user.confirmed}
                />
              </TabPanel>

              {/* Pesan + Aog Mog | Calon | Tidak Disetujui */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={2}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="user"
                  status={"2"}
                  defaultValue={settingWa.data.create.user.rejected}
                />
              </TabPanel>
            </Grid>
          </TabPanel>

          {/* Tabs + Aog Mog Preferal */}
          <TabPanel
            value={tabsSubHeader}
            index={2}
            className={classes.rootTabsStatus}
          >
            <Grid item xs={3}>
              <Tabs
                value={tabsStatus}
                orientation="vertical"
                className={classes.tabsVertical}
                onChange={handleTabsStatus}
              >
                <Tab label="Menunggu Konfirmasi" {...a11yProps(0)} />
                <Tab label="Terkonfirmasi" {...a11yProps(1)} />
                <Tab label="Tidak Disetujui" {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              {/* Pesan + Aog Mog | Preferal | Menunggu Konfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={0}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="preferal"
                  status={"0"}
                  defaultValue={settingWa.data.create.preferal.waiting}
                />
              </TabPanel>

              {/* Pesan + Aog Mog | Preferal | Terkonfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={1}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="preferal"
                  status={"1"}
                  defaultValue={settingWa.data.create.preferal.confirmed}
                />
              </TabPanel>

              {/* Pesan + Aog Mog | Preferal | Tidak Disetujui */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={2}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="create"
                  type="preferal"
                  status={"2"}
                  defaultValue={settingWa.data.create.preferal.rejected}
                />
              </TabPanel>
            </Grid>
          </TabPanel>
        </TabPanel>

        {/* Tabs Upgrade */}
        <TabPanel value={tabsHeader} index={1}>
          <Tabs value={tabsSubHeader} onChange={handleTabsSubHeader}>
            <Tab label="Cabang" {...a11yProps(3)} />
            <Tab label="Calon Aog Mog" {...a11yProps(4)} />
            <Tab label="Preferal" {...a11yProps(5)} />
          </Tabs>
          {/* Tabs Upgrade Cabang */}
          <TabPanel
            value={tabsSubHeader}
            index={0}
            className={classes.rootTabsStatus}
          >
            <Grid item xs={3}>
              <Tabs
                value={tabsStatus}
                orientation="vertical"
                className={classes.tabsVertical}
                onChange={handleTabsStatus}
              >
                <Tab label="Menunggu Konfirmasi" {...a11yProps(0)} />
                <Tab label="Terkonfirmasi" {...a11yProps(1)} />
                <Tab label="Tidak Disetujui" {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              {/* Pesan Upgrade | Cabang | Menunggu Konfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={0}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="branch"
                  status={"0"}
                  defaultValue={settingWa.data.upgrade.branch.waiting}
                />
              </TabPanel>

              {/* Pesan Upgrade | Cabang | Terkonfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={1}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="branch"
                  status={"1"}
                  defaultValue={settingWa.data.upgrade.branch.confirmed}
                />
              </TabPanel>

              {/* Pesan Upgrade | Cabang | Tidak Disetujui */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={2}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="branch"
                  status={"2"}
                  defaultValue={settingWa.data.upgrade.branch.rejected}
                />
              </TabPanel>
            </Grid>
          </TabPanel>

          {/* Tabs Upgrade Calon */}
          <TabPanel
            value={tabsSubHeader}
            index={1}
            className={classes.rootTabsStatus}
          >
            <Grid item xs={3}>
              <Tabs
                value={tabsStatus}
                orientation="vertical"
                className={classes.tabsVertical}
                onChange={handleTabsStatus}
              >
                <Tab label="Menunggu Konfirmasi" {...a11yProps(0)} />
                <Tab label="Terkonfirmasi" {...a11yProps(1)} />
                <Tab label="Tidak Disetujui" {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={0}
              >
                {/* Pesan Upgrade | Calon | Menunggu Konfirmasi */}
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="user"
                  status={"0"}
                  defaultValue={settingWa.data.upgrade.user.waiting}
                />
              </TabPanel>

              {/* Pesan Upgrade | Calon | Terkonfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={1}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="user"
                  status={"1"}
                  defaultValue={settingWa.data.upgrade.user.confirmed}
                />
              </TabPanel>

              {/* Pesan Upgrade | Calon | Tidak Disetujui */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={2}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="user"
                  status={"2"}
                  defaultValue={settingWa.data.upgrade.user.rejected}
                />
              </TabPanel>
            </Grid>
          </TabPanel>

          {/* Tabs Upgrade Preferal */}
          <TabPanel
            value={tabsSubHeader}
            index={2}
            className={classes.rootTabsStatus}
          >
            <Grid item xs={3}>
              <Tabs
                value={tabsStatus}
                orientation="vertical"
                className={classes.tabsVertical}
                onChange={handleTabsStatus}
              >
                <Tab label="Menunggu Konfirmasi" {...a11yProps(0)} />
                <Tab label="Terkonfirmasi" {...a11yProps(1)} />
                <Tab label="Tidak Disetujui" {...a11yProps(2)} />
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              {/* Pesan Upgrade | Preferal | Menunggu Konfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={0}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="preferal"
                  status={"0"}
                  defaultValue={settingWa.data.upgrade.preferal.waiting}
                />
              </TabPanel>

              {/* Pesan Upgrade | Preferal | Terkonfirmasi */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={1}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="preferal"
                  status={"1"}
                  defaultValue={settingWa.data.upgrade.preferal.confirmed}
                />
              </TabPanel>

              {/* Pesan Upgrade | Preferal | Tidak Disetujui */}
              <TabPanel
                className={classes.boxMessage}
                value={tabsStatus}
                index={2}
              >
                <MessageContent
                  action={sendMessageWa}
                  action_on="upgrade"
                  type="preferal"
                  status={"2"}
                  defaultValue={settingWa.data.upgrade.preferal.rejected}
                />
              </TabPanel>
            </Grid>
          </TabPanel>
        </TabPanel>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  settingWa: state.settingWa,
});

export default connect(mapStateToProps, { sendMessageWa, getMessageWa })(
  Message
);
