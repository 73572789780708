import * as types from './types';
import axios from '../axios-orders';

export const getConfigTimeStart = () => {
  return {
    type: types.GET_CONFIG_TIME_START
  }
}

export const getConfigTimeSuccess = (configTime) => {
  return {
    type: types.GET_CONFIG_TIME_SUCCESS,
    configTime: configTime
  }
}

export const getConfigTimeFail = (error) => {
  return {
    type: types.GET_CONFIG_TIME_FAIL,
    error: error
  }
}

export const getConfigTime = (token) => {
  return dispatch => {
    dispatch(getConfigTimeStart())
    axios.get('user/time_open_closed', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        dispatch(getConfigTimeSuccess(res.data))
      })
      .catch(err => {
        dispatch(getConfigTimeFail(err))
      })
  }
}