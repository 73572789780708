import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Grid, 
  Typography,
} from '@material-ui/core';

import {
  ListPaymentReceipts,
  EditPaymentReceipt,
  ShowPaymentReceipt
} from './Components'

import Modal from '../../components/UI/Modal/ScrollDialog/ScrollDialog';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		}
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute',
    // zIndex: 0
  },
  cardMobile: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  executiveSummary: {
    marginBottom: theme.spacing(2)
  },
  btn: {
    backgroundColor: '#FF9300',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFA938',
      opacity: 1,
    },
  },
}));

const PaymentReceipt = props => {
  const classes = useStyles();

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });

  const { changing } = props;
  useEffect(()=> {  
    closedModalDialog();
  }, [changing])

  const edit = (paymentReceiptUpgrade) => {
    console.log(paymentReceiptUpgrade)
    // setCurrentPage(assets.current_page);
    setModalState({
      open: true,
      title: 'Konfirmasi Pembayaran',
      maxWidth: 'lg'
    });
    setForm(<EditPaymentReceipt paymentReceiptUpgrade={paymentReceiptUpgrade}  closedModalDialog={() => closedModalDialog()}/>)
  }

  const show = (paymentReceipt) => {
    // setCurrentPage(assets.current_page);
    setModalState({
      open: true,
      title: 'Detail Invoice ',
      maxWidth: 'lg'
    });
    setForm(<ShowPaymentReceipt paymentReceiptShow={paymentReceipt}  closedModalDialog={() => closedModalDialog()}/>)
  }

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };
  
  return (
    <div className={classes.root}>
      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
        contentModal={Form}
      />

      <div className={classes.bgColor}></div>
      <Grid
        container
        spacing={3}
        justify="space-between"
      >
        <Grid item>  
          <Typography variant="h4">Payment Receipt Upgrade</Typography>
        </Grid>
      </Grid><br/><hr/><br/>

      <ListPaymentReceipts edit={(asset) => edit(asset)} show={(paymentReceipt) => show(paymentReceipt)}/>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    changing: state.paymentReceiptUpgrade.changing,
    loading: state.paymentReceiptUpgrade.loading
  }
}

export default connect(mapStateToProps)(PaymentReceipt);