import {
    GET_BRANCH
} from '../actions/types'

const initialState = {
    branch: null,
    loadingBranch: true,
    error: {}
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_BRANCH:
            return {
                ...state,
                branch: payload,
                loadingBranch: false
            }
        default:
            return state
    }
}

export default reducer;