/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Button,
  Typography,
  Switch,
  InputLabel,
  FormControl,
  FormHelperText,
  Select as MaterialSelect,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Backdrop,
  MenuItem,
  Table,
  TableContainer,
  TableCell,
  TableRow
  // Tooltip
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers";
import SchemaValidation from "./validation";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import momentTz from 'moment-timezone'

import { AlamatDomisili, Pereferal } from "./components";

// Redux
import { connect } from "react-redux";
import {
  getProvince,
  getCities,
  getDistrict,
  getVillage,
} from "../../../actions/province";
// Validation
import { fetchAOGMOGDetail, patchData } from '../../../actions'
import { Skeleton } from "@material-ui/lab";
// import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  textInput: {
    width: "100%",
  },
  card: {
    marginTop: theme.spacing(2),
    overflow: "visible",
  },
  searchRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "auto",
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  select: {
    minHeight: 40,
  },
  typography: {
    fontFamily: "Nunito",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Form = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(SchemaValidation),
  });
  const {
    patchData,
    getProvince,
    getCities,
    getDistrict,
    getVillage,
    province: {
      province,
      city,
      district,
      village,
      loadingProvince,
    },
    fetchAOGMOGDetail,
    loadingDetail,
    loading,
    detailAogMog
  } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      nama_lengkap: "",
      jenis_pendaftaran: "",
      no_wa: "",
      tanggal_lahir: ""
    },
    errors: {},
  });

  const handleChangeJenis = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));

  };

  const handleChangePC = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  const handleChangeChecked = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));

    if (event.target.checked) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          alamat_domisili: formState.values.alamat_ktp,
          kode_pos_domisili: formState.values.kode_pos_ktp,
        },
      }));
    }
  };

  const handleChangeBiaya = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const onSubmitValidasi = (e) => {
    setOpenDialog(true);
  };

  const onSubmit = (e) => {
    setOpenDialog(false)
    patchData(params.id, formState.values, history);
  };

  const onProvinceChange = (event) => {
    console.log(event)
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
    getCities(event.target.value.id);
  };

  const onCityChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
    getDistrict(event.target.value.id);
  };

  const onSelectSearchChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const onDistrictChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
    getVillage(event.target.value.id);
  };

  const onVillageChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value.name,
      },
    }));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const optionsLoading = [{ value: "loading", label: "Loading" }];
  const provinceOptions = [];
  if (province !== null) {
    for (let i = 0; i < province.length; i++) {
      provinceOptions.push({
        value: province[i],
        label: province[i].name,
        name: "provinsi_ktp",
      });
    }
  }

  const cityOptions = [];
  if (city !== null) {
    for (let i = 0; i < city.length; i++) {
      cityOptions.push({
        value: city[i],
        label: city[i].name,
        name: "kota_ktp",
      });
    }
  }

  const districtOptions = [];
  if (district !== null) {
    for (let i = 0; i < district.length; i++) {
      districtOptions.push({
        value: district[i],
        label: district[i].name,
        name: "kecamatan_ktp",
      });
    }
  }

  const villageOptions = [];
  if (village !== null) {
    for (let i = 0; i < village.length; i++) {
      villageOptions.push({
        value: village[i],
        label: village[i].name,
        name: "kelurahan_ktp",
      });
    }
  }

  // const submitDefault = moment(formState.values.tanggal_lahir).format("YYYY-MM-DD");
  const handleStartDate = (date) => {
    const changeDate = moment(date).format("YYYY-MM-DD");
    setFormState((formState) => ({
      ...formState,
      values: {
        tanggal_lahir: changeDate
      }
    }))
  };

  useEffect(() => {
    fetchAOGMOGDetail(params.id, setFormState)
  }, [fetchAOGMOGDetail, params, setFormState])

  useEffect(() => {
    getProvince();
  }, [getProvince, loadingProvince]);

  return loadingDetail || loading || detailAogMog === null ? (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Fragment>
      <div className={classes.root}>
        <Card>
          <CardHeader title="Preview" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TableContainer>
                  <Table>
                    <TableRow>
                      <TableCell>
                        Nama Lengkap
                      </TableCell>
                      <TableCell>
                        : {formState.values.nama_lengkap}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Status Anggota
                      </TableCell>
                      <TableCell>
                        : {formState.values.jenis_pendaftaran}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        No ID
                      </TableCell>
                      <TableCell>
                        : {formState.values.no_id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Email
                      </TableCell>
                      <TableCell>
                        : {formState.values.email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        No WhatsApp
                      </TableCell>
                      <TableCell>
                        : {formState.values.no_wa}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TableContainer>
                  <Table>
                    <TableRow>
                      <TableCell>
                        Created By
                      </TableCell>
                      <TableCell>
                        : {formState.values.name_user}, pada {momentTz.tz(formState.values.created_at, "Asia/Jakarta").format('DD MMMM YYYY HH:mm')} WIB
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Last Updated by
                      </TableCell>
                      <TableCell>
                        {detailAogMog.log_update_register_aog_mog_last !== null ? (
                          <>
                          : {detailAogMog.log_update_register_aog_mog_last.name_editor}, pada {momentTz.tz(detailAogMog.log_update_register_aog_mog_last.updated_at, "Asia/Jakarta").format('DD MMMM YYYY HH:mm')} WIB
                          </>
                        ):(
                          <>
                          -
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <form onSubmit={handleSubmit(onSubmitValidasi)}>
          <Card className={classes.card}>
            <CardHeader title="Biodata Pendaftar" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.jenis_pendaftaran ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Jenis Pendaftaran
                    </InputLabel>
                    <MaterialSelect
                      native
                      disabled
                      label="Jenis Pendaftaran"
                      name="jenis_pendaftaran"
                      className={classes.textInput}
                      onChange={handleChangeJenis}
                      value={formState.values.jenis_pendaftaran}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="AOG" value="AOG">
                        Agent Of Gold
                      </option>
                      <option key="MOG" value="MOG">
                        Member Of Gold
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.jenis_pendaftaran &&
                        errors.jenis_pendaftaran.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    disabled
                    label="Nomor ID"
                    className={classes.textInput}
                    name="no_id"
                    value={formState.values.no_id || ''}
                    onChange={handleChange}
                    helperText={errors.no_id && errors.no_id.message}
                    error={errors.no_id && true}
                    inputRef={register}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    disabled
                    label="Nama Lengkap"
                    className={classes.textInput}
                    name="nama_lengkap"
                    value={formState.values.nama_lengkap}
                    onChange={handleChange}
                    helperText={
                      errors.nama_lengkap && errors.nama_lengkap.message
                    }
                    error={errors.nama_lengkap && true}
                    inputRef={register}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Tempat Lahir"
                    className={classes.textInput}
                    name="tempat_lahir"
                    value={formState.values.tempat_lahir || ''}
                    onChange={handleChange}
                    helperText={
                      errors.tempat_lahir && errors.tempat_lahir.message
                    }
                    error={errors.tempat_lahir && true}
                    inputRef={register}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Tanggal Lahir
                  </InputLabel>
                  <div className={classes.searchRoot}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        fullWidth
                        disableFuture
                        ampm={false}
                        variant="outlined"
                        name="start_date"
                        format="dd MMMM yyyy"
                        // value={startDate.view.view}
                        value={formState.values.tanggal_lahir || ''}
                        onChange={handleStartDate}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.jenis_kelamin ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Jenis Kelamin
                    </InputLabel>
                    <MaterialSelect
                      native
                      label="Jenis Kelamin"
                      name="jenis_kelamin"
                      value={formState.values.jenis_kelamin || ''}
                      className={classes.textInput}
                      onChange={handleChange}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="Laki-laki" value="Laki-laki">
                        Laki-laki
                      </option>
                      <option key="Perempuan" value="Perempuan">
                        Perempuan
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.jenis_kelamin && errors.jenis_kelamin.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Nomor KTP"
                    className={classes.textInput}
                    name="no_ktp"
                    value={formState.values.no_ktp || ''}
                    onChange={handleChange}
                    helperText={errors.no_ktp && errors.no_ktp.message}
                    error={errors.no_ktp && true}
                    inputRef={register}
                  />
                </Grid>
                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Nomor ID AOG/MOG"
                    className={classes.textInput}
                    name="no_id"
                    onChange={handleChange}
                    helperText={errors.no_id && errors.no_id.message}
                    error={errors.no_id && true}
                    inputRef={register}
                  />
                </Grid> */}
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Email AOG/MOG"
                    className={classes.textInput}
                    name="email"
                    value={formState.values.email || ''}
                    onChange={handleChange}
                    helperText={errors.email && errors.email.message}
                    error={errors.email && true}
                    inputRef={register}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Nomor Whatsapp AOG/MOG"
                    name="no_wa"
                    inputRef={register}
                    className={classes.textInput}
                    error={errors.no_wa && true}
                    helperText={errors.no_wa && errors.no_wa.message}
                    fullWidth
                    value={formState.values.no_wa || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Nomor Rekening"
                    className={classes.textInput}
                    name="nomor_rekening"
                    value={formState.values.nomor_rekening || ''}
                    onChange={handleChange}
                    helperText={
                      errors.nomor_rekening && errors.nomor_rekening.message
                    }
                    error={errors.nomor_rekening && true}
                    inputRef={register}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Nama Bank"
                    className={classes.textInput}
                    name="nama_bank"
                    value={formState.values.nama_bank || ''}
                    onChange={handleChange}
                    helperText={errors.nama_bank && errors.nama_bank.message}
                    error={errors.nama_bank && true}
                    inputRef={register}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.pendidikan_terakhir ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Pendidikan Terakhir
                    </InputLabel>
                    <MaterialSelect
                      native
                      label="Pendidikan Terakhir"
                      name="pendidikan_terakhir"
                      value={formState.values.pendidikan_terakhir || ''}
                      className={classes.textInput}
                      onChange={handleChange}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="SD" value="SD">
                        SD
                      </option>
                      <option key="SLTP" value="SLTP">
                        SLTP/SMP
                      </option>
                      <option key="SMA" value="SMA">
                        SMA/SMK/Sederajat
                      </option>
                      <option key="D1" value="D1">
                        D1
                      </option>
                      <option key="D2" value="D2">
                        D2
                      </option>
                      <option key="D3" value="D3">
                        D3
                      </option>
                      <option key="S1" value="S1">
                        S1
                      </option>
                      <option key="S2" value="S2">
                        S2
                      </option>
                      <option key="S3" value="S3">
                        S3
                      </option>
                      <option key="Tidak Sekolah" value="Tidak Sekolah">
                        Tidak Sekolah
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.pendidikan_terakhir &&
                        errors.pendidikan_terakhir.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.status_pernikahan ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Status Pernikahan
                    </InputLabel>
                    <MaterialSelect
                      native
                      label="Status Pernikahan"
                      name="status_pernikahan"
                      value={formState.values.status_pernikahan || ''}
                      className={classes.textInput}
                      onChange={handleChange}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="Menikah" value="Menikah">
                        Menikah
                      </option>
                      <option key="Belum Menikah" value="Belum Menikah">
                        Belum Menikah
                      </option>
                      <option key="Cerai Hidup" value="Cerai Hidup">
                        Cerai Hidup
                      </option>
                      <option key="Cerai Mati" value="Cerai Mati">
                        Cerai Mati
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.status_pernikahan &&
                        errors.status_pernikahan.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Pekerjaan"
                    name="pekerjaan"
                    value={formState.values.pekerjaan || ''}
                    className={classes.textInput}
                    onChange={handleChange}
                    helperText={errors.pekerjaan && errors.pekerjaan.message}
                    error={errors.pekerjaan && true}
                    inputRef={register}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.status_kepemilikan_rumah ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Status Kepemilikan Rumah
                    </InputLabel>
                    <MaterialSelect
                      native
                      label="Status Kepemilikan Rumah"
                      name="status_kepemilikan_rumah"
                      value={formState.values.status_kepemilikan_rumah || ''}
                      className={classes.textInput}
                      onChange={handleChange}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="Milik Sendiri" value="Milik Sendiri">
                        Milik Sendiri
                      </option>
                      <option key="Sewa" value="Sewa">
                        Sewa
                      </option>
                      <option key="Menumpang" value="Menumpang">
                        Menumpang
                      </option>
                      <option key="Ikut Orang Tua" value="Ikut Orang Tua">
                        Ikut Orang Tua
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.status_kepemilikan_rumah &&
                        errors.status_kepemilikan_rumah.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.kepemilikan_npwp ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Kepemilikan NPWP
                    </InputLabel>
                    <MaterialSelect
                      native
                      label="Kepemilikan NPWP"
                      name="kepemilikan_npwp"
                      value={formState.values.kepemilikan_npwp || ''}
                      className={classes.textInput}
                      onChange={handleChange}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="Tidak" value="Tidak">
                        Tidak
                      </option>
                      <option key="Ya" value="Ya">
                        Ya
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.kepemilikan_npwp &&
                        errors.kepemilikan_npwp.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {formState.values.kepemilikan_npwp && (
                  <>
                    {formState.values.kepemilikan_npwp === "Ya" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          label="Nomor NPWP"
                          name="no_npwp"
                          value={formState.values.no_npwp || ''}
                          className={classes.textInput}
                          onChange={handleChange}
                          helperText={errors.no_npwp && errors.no_npwp.message}
                          error={errors.no_npwp && true}
                          inputRef={register}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>

              <Grid container spacing={2}>
                {formState.values.jenis_pendaftaran === "AOG" ||
                formState.values.jenis_pendaftaran === "MOG" ? (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        error={errors.apakah_sudah_mengikuti_private_class ? true : false}
                        className={classes.formControl}
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Apakah Sudah mengikuti Private Class?
                        </InputLabel>
                        <MaterialSelect
                          native
                          label="Apakah Sudah Mengikuti Private Class ?"
                          name="apakah_sudah_mengikuti_private_class"
                          value={
                            formState.values.apakah_sudah_mengikuti_private_class || ''
                          }
                          className={classes.textInput}
                          onChange={handleChangePC}
                          inputRef={register}
                        >
                          <option aria-label="None" value="" />
                          <option key="sudah" value="sudah">
                            Sudah
                          </option>
                          <option key="belum" value="belum">
                            Belum
                          </option>
                        </MaterialSelect>
                        <FormHelperText>
                          {errors.apakah_sudah_mengikuti_private_class &&
                            errors.apakah_sudah_mengikuti_private_class.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {formState.values.apakah_sudah_mengikuti_private_class ===
                      "sudah" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          label="Nomor Alumni"
                          name="id_alumni"
                          value={formState.values.id_alumni || ''}
                          className={classes.textInput}
                          onChange={handleChange}
                          helperText={
                            errors.id_alumni && errors.id_alumni.message
                          }
                          error={errors.id_alumni && true}
                          inputRef={register}
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.apakah_anggota_koperasi ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Apakah Anggota Koperasi EOA Club?
                    </InputLabel>
                    <MaterialSelect
                      native
                      label="Apakah Anggota Koperasi EOA Club?"
                      name="apakah_anggota_koperasi"
                      value={formState.values.apakah_anggota_koperasi || ''}
                      className={classes.textInput}
                      onChange={handleChange}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="Tidak" value="Tidak">
                        Tidak
                      </option>
                      <option key="Ya" value="Ya">
                        Ya
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.apakah_anggota_koperasi &&
                        errors.apakah_anggota_koperasi.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <FormControl
                    error={errors.agama ? true : false}
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Agama
                    </InputLabel>
                    <MaterialSelect
                      native
                      label="Agama"
                      name="agama"
                      value={formState.values.agama || ''}
                      className={classes.textInput}
                      onChange={handleChange}
                      inputRef={register}
                    >
                      <option aria-label="None" value="" />
                      <option key="Islam" value="Islam">
                        Islam
                      </option>
                      <option key="Katolik" value="Katolik">
                        Katolik
                      </option>
                      <option key="Protestan" value="Protestan">
                        Protestan
                      </option>
                      <option key="Hindu" value="Hindu">
                        Hindu
                      </option>
                      <option key="Buddha" value="Buddha">
                        Buddha
                      </option>
                      <option key="Lainnya" value="Lainnya">
                        Lainnya
                      </option>
                    </MaterialSelect>
                    <FormHelperText>
                      {errors.agama && errors.agama.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardHeader title="Alamat KTP" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Alamat Lengkap (Sesuai KTP)"
                    className={classes.textInput}
                    name="alamat_ktp"
                    value={formState.values.alamat_ktp || ''}
                    onChange={handleChange}
                    helperText={errors.alamat_ktp && errors.alamat_ktp.message}
                    error={errors.alamat_ktp && true}
                    inputRef={register}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  {formState.values.provinsi_ktp ? (
                    <TextField
                      select
                      fullWidth
                      className={classes.select}
                      label="Pilih Provinsi"
                      name="provinsi_ktp"
                      onChange={onProvinceChange}
                      value={formState.values.provinsi_ktp || ''}
                      error={!!errors.provinsi_ktp}
                      helperText={!!errors.provinsi_ktp && errors.provinsi_ktp.message}
                    >
                      <MenuItem value=""></MenuItem>
                      {formState.values.provinsi_ktp && (
                        <MenuItem
                          value={formState.values.provinsi_ktp}
                          disabled
                        >
                          {formState.values.provinsi_ktp}
                        </MenuItem>
                      )}
                      {provinceOptions.map((value) => (
                        <MenuItem key={value.value.id} value={value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Skeleton variant="rect"></Skeleton>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  {formState.values.kota_ktp ? (
                    <TextField
                      select
                      fullWidth
                      className={classes.select}
                      label="Pilih Kota/Kabupaten"
                      name="kota_ktp"
                      onChange={onCityChange}
                      value={formState.values.kota_ktp || ''}
                      error={!!errors.kota_ktp}
                      helperText={!!errors.kota_ktp && errors.kota_ktp.message}
                    >
                      <MenuItem value=""></MenuItem>
                      {formState.values.kota_ktp && (
                        <MenuItem
                          value={formState.values.kota_ktp}
                          disabled
                        >
                          {formState.values.kota_ktp}
                        </MenuItem>
                      )}
                      {cityOptions.map((value) => (
                        <MenuItem key={value.value.id} value={value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Select
                      disabled
                      className={classes.select}
                      options={optionsLoading}
                      onChange={onCityChange}
                      placeholder="Pilih Kota/Kabupaten"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  {formState.values.kecamatan_ktp ? (
                    <TextField
                      select
                      fullWidth
                      className={classes.select}
                      label="Pilih Kecamatan"
                      name="kecamatan_ktp"
                      onChange={onDistrictChange}
                      value={formState.values.kecamatan_ktp || ''}
                      error={!!errors.kecamatan_ktp}
                      helperText={!!errors.kecamatan_ktp && errors.kecamatan_ktp.message}
                    >
                      <MenuItem value=""></MenuItem>
                      {formState.values.kecamatan_ktp && (
                        <MenuItem
                          value={formState.values.kecamatan_ktp}
                          disabled
                        >
                          {formState.values.kecamatan_ktp}
                        </MenuItem>
                      )}
                      {districtOptions.map((value) => (
                        <MenuItem key={value.value.id} value={value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Select
                      disabled
                      className={classes.select}
                      options={optionsLoading}
                      onChange={onDistrictChange}
                      placeholder="Pilih Kecamatan"
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  {formState.values.kelurahan_ktp ? (
                    <TextField
                      select
                      fullWidth
                      className={classes.select}
                      label="Pilih Kelurahan"
                      name="kelurahan_ktp"
                      onChange={onVillageChange}
                      value={formState.values.kelurahan_ktp || ''}
                      error={!!errors.kelurahan_ktp}
                      helperText={!!errors.kelurahan_ktp && errors.kelurahan_ktp.message}
                    >
                      <MenuItem value=""></MenuItem>
                      {formState.values.kelurahan_ktp && (
                        <MenuItem
                          value={formState.values.kelurahan_ktp}
                          disabled
                        >
                          {formState.values.kelurahan_ktp}
                        </MenuItem>
                      )}
                      {villageOptions.map((value) => (
                        <MenuItem key={value.value.id} value={value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Select
                      disabled
                      className={classes.select}
                      options={optionsLoading}
                      onChange={onVillageChange}
                      placeholder="Pilih Kelurahan"
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Kode Pos"
                    className={classes.textInput}
                    name="kode_pos_ktp"
                    value={formState.values.kode_pos_ktp || ''}
                    onChange={handleChange}
                    helperText={
                      errors.kode_pos_ktp && errors.kode_pos_ktp.message
                    }
                    error={errors.kode_pos_ktp && true}
                    inputRef={register}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Typography className={classes.card}>
            Apakah Alamat sekarang sama dengan alamat KTP?
            <Switch
              checked={formState.values.checked}
              onChange={handleChangeChecked}
              name="checked"
              color="primary"
              inputRef={register}
            />
          </Typography>
          {!formState.values.checked && (
            <AlamatDomisili
              formState={formState}
              setFormState={setFormState}
              handleChange={handleChange}
              errors={errors}
              register={register}
            />
          )}

          <Pereferal
            register={register}
            formState={formState}
            errors={errors}
            onSelectSearchChange={onSelectSearchChange}
            handleChangeBiaya={handleChangeBiaya}
            handleChange={handleChange}
          />

          <Button variant="contained" type="submit" color="primary" fullWidth>
            Perbaharui Data
          </Button>
          
        </form>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Konfirmasi Form</DialogTitle>
          <DialogContent>
            <Typography>
              Apakah anda yakin ingin meneruskan Form ini?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Batal
            </Button>
            <Button onClick={onSubmit} color="primary">
              Simpan
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  province: state.province,
  loadingDetail : state.inquiryData.loadingDetail,
  detailAogMog: state.inquiryData.detailAogMog,
  loading: state.inquiryData.loading
});

export default connect(mapStateToProps, {
  getProvince,
  getCities,
  getDistrict,
  getVillage,
  patchData,
  fetchAOGMOGDetail
})(Form);
