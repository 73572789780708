import React from 'react'
import {
    Card,
    CardHeader,
    CardContent,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Typography,
    // IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
    root: {
      maxHeight: '440px',
      overflow: 'auto',
      marginBottom: theme.spacing(1)
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    text: {
        fontFamily: 'Nunito',
        fontSize: '8',
    },
    textNumber: {
        fontFamily: 'Nunito',
        fontSize: '8',
        color: '#66bb6a'
    },
    textSubtitle: {
        color: '#757575',
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));

const TopBranch = props => {
    const classes = useStyles()
    const {
        topBranch
    } = props

    let no = 1

    return (
        <Card className={classes.root}>
            <CardHeader 
                title="Top 10 Branch EOA Gold"
                action={
                    <Typography variant="subtitle2" gutterBottom className={classes.textSubtitle}>
                        Transaction
                    </Typography>
                }
            />
            <CardContent>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Nama Cabang</TableCell>
                                <TableCell>AOG</TableCell>
                                <TableCell>MOG</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {topBranch.map((row) => (
                            <TableRow key={row.id_branch}>
                                <TableCell>
                                    {no++}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.nama_branch}
                                </TableCell>
                                <TableCell>{row.AOG}</TableCell>
                                <TableCell>{row.MOG}</TableCell>
                                <TableCell>{row.jumlah}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <List>
                    {topBranch.map(item => (
                        <>
                        <ListItem>
                            <Typography className={classes.text}>
                                {item.nama_branch}
                            </Typography>
                        </ListItem>
                        <Divider />
                        </>
                    ))}
                </List> */}
            </CardContent>
        </Card>
    )
}

export default TopBranch