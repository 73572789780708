import * as types from './types';
import axios from '../axios-orders';
import { setAlert } from "./alert";

export const fetchAOGMOGListStart = () => {
  return {
    type: types.GET_LIST_AOG_MOG_START
  }
}

export const fetchAOGMOGListSuccess = (data) => {
  return {
    type: types.GET_LIST_AOG_MOG_SUCCESS,
    listAogMog: data
  }
}

export const fetchAOGMOGListFail = (error) => {
  return {
    type: types.GET_LIST_AOG_MOG_FAIL,
    error: error
  }
}

export const fetchAOGMOGList = (page, search, type) => {
  let param = ''
  console.log(type)
  if(type === 'name'){
    param = 'nama_lengkap='+search
  }else{
    param = 'no_id='+search
  }

  return dispatch => {
    dispatch(fetchAOGMOGListStart())
    axios.get(`user/sbs/search_register_aog_mog?${param}&page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        const data = res.data
        dispatch(fetchAOGMOGListSuccess(data))
      })
      .catch(err => {
        dispatch(fetchAOGMOGListFail(err))
      })
  }
}

// Detail AOG MOG
export const fetchAOGMOGDetailStart = () => {
  return {
    type: types.GET_DETAIL_AOG_MOG_START
  }
}

export const fetchAOGMOGDetailSuccess = (data) => {
  return {
    type: types.GET_DETAIL_AOG_MOG_SUCCESS,
    detailAogMog: data
  }
}

export const fetchAOGMOGDetailFail = (error) => {
  return {
    type: types.GET_DETAIL_AOG_MOG_FAIL,
    error: error
  }
}

export const fetchAOGMOGDetail = (id, setFormState) => {

  return dispatch => {
    dispatch(fetchAOGMOGDetailStart())
    axios.get(`user/sbs/register_aog_mog/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        const formData = res.data
        setFormState({
          values: {
            alamat_domisili: formData.alamat_domisili,
            alamat_ktp: formData.alamat_ktp,
            apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
            apakah_sudah_mengikuti_private_class:
            formData.apakah_sudah_mengikuti_private_class,
            biaya_total: 0,
            cabang_pembina: formData.cabang_pembina,
            email: formData.email,
            id_alumni: formData.id_alumni,
            jenis_kelamin: formData.jenis_kelamin,
            jenis_pendaftaran: formData.jenis_pendaftaran,
            kecamatan_domisili: formData.kecamatan_domisili,
            kecamatan_ktp: formData.kecamatan_ktp,
            kelurahan_ktp: formData.kelurahan_ktp,
            kelurahan_domisili: formData.kelurahan_domisili,
            kepemilikan_npwp: formData.kepemilikan_npwp,
            kode_pos_domisili: formData.kode_pos_domisili,
            kode_pos_ktp: formData.kode_pos_ktp,
            kota_domisili: formData.kota_domisili,
            kota_ktp: formData.kota_ktp,
            nama_bank: formData.nama_bank,
            nama_bank_pereferal: formData.nama_bank_pereferal,
            nama_lengkap: formData.nama_lengkap,
            nama_pereferal: formData.nama_pereferal,
            negara_domisili: "Indonesia",
            negara_ktp: "Indonesia",
            no_ba: formData.no_ba,
            no_id: formData.no_id,
            no_id_pereferal: formData.no_id_pereferal,
            no_ktp: formData.no_ktp,
            no_npwp: formData.no_npwp,
            no_rek_pereferal: formData.no_rek_pereferal,
            no_wa: formData.no_wa,
            no_wa_pereferal: formData.no_wa_pereferal,
            nomor_rekening: formData.nomor_rekening,
            pekerjaan: formData.pekerjaan,
            pendidikan_terakhir: formData.pendidikan_terakhir,
            pengiriman_paket: formData.pengiriman_paket,
            provinsi_domisili: formData.provinsi_domisili,
            provinsi_ktp: formData.provinsi_ktp,
            status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
            status_pereferal: formData.status_pereferal,
            status_pernikahan: formData.status_pernikahan,
            tanggal_lahir: formData.tanggal_lahir,
            tempat_lahir: formData.tempat_lahir,
            agama: formData.agama,
            no_id_lama: formData.no_id_lama,
            no_id_baru: formData.no_id,
            jenis_daftar_lama: "mog",
            jenis_daftar_baru: formData.jenis_pendaftaran,
            biaya_upgrade: formData.biaya_total,
            name_user: formData.name_user,
            created_at: formData.created_at
          }
        })
        dispatch(fetchAOGMOGDetailSuccess(formData))
      })
      .catch(err => {
        dispatch(fetchAOGMOGDetailFail(err))
      })
  }
}

// Patch AOG MOG
export const patchData = (id, formData, history) => async (
  dispatch
) => {
  dispatch({ type: types.PATCH_AOG_MOG_START });

  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/sbs/register_aog_mog/${id}`;

  var myData = null;

  if (!formData.checked) {
    myData = {
      alamat_domisili: formData.alamat_domisili,
      alamat_ktp: formData.alamat_ktp,
      apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
      apakah_sudah_mengikuti_private_class:
      formData.apakah_sudah_mengikuti_private_class,
      cabang_pembina: formData.cabang_pembina,
      email: formData.email,
      id_alumni: formData.id_alumni,
      jenis_kelamin: formData.jenis_kelamin,
      jenis_pendaftaran: formData.jenis_pendaftaran,
      kecamatan_domisili: formData.kecamatan_domisili,
      kecamatan_ktp: formData.kecamatan_ktp,
      kelurahan_ktp: formData.kelurahan_ktp,
      kelurahan_domisili: formData.kelurahan_domisili,
      kepemilikan_npwp: formData.kepemilikan_npwp,
      kode_pos_domisili: formData.kode_pos_domisili,
      kode_pos_ktp: formData.kode_pos_ktp,
      kota_domisili: formData.kota_domisili,
      kota_ktp: formData.kota_ktp,
      nama_bank: formData.nama_bank,
      nama_bank_pereferal: formData.nama_bank_pereferal,
      nama_pereferal: formData.nama_pereferal,
      negara_domisili: "Indonesia",
      negara_ktp: "Indonesia",
      no_ba: formData.no_ba,
      no_id_pereferal: formData.no_id_pereferal,
      no_ktp: formData.no_ktp,
      no_npwp: formData.no_npwp,
      no_rek_pereferal: formData.no_rek_pereferal,
      no_wa: formData.no_wa,
      no_wa_pereferal: formData.no_wa_pereferal,
      nomor_rekening: formData.nomor_rekening,
      pekerjaan: formData.pekerjaan,
      pendidikan_terakhir: formData.pendidikan_terakhir,
      pengiriman_paket: formData.pengiriman_paket,
      provinsi_domisili: formData.provinsi_domisili,
      provinsi_ktp: formData.provinsi_ktp,
      status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
      status_pereferal: formData.status_pereferal,
      status_pernikahan: formData.status_pernikahan,
      tanggal_lahir: formData.tanggal_lahir,
      tempat_lahir: formData.tempat_lahir,
      agama: formData.agama,
    };
  } else {
    myData = {
      alamat_domisili: formData.alamat_ktp,
      alamat_ktp: formData.alamat_ktp,
      apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
      apakah_sudah_mengikuti_private_class:
      formData.apakah_sudah_mengikuti_private_class,
      cabang_pembina: formData.cabang_pembina,
      email: formData.email,
      id_alumni: formData.id_alumni,
      jenis_kelamin: formData.jenis_kelamin,
      jenis_pendaftaran: formData.jenis_pendaftaran,
      kecamatan_domisili: formData.kecamatan_ktp,
      kecamatan_ktp: formData.kecamatan_ktp,
      kelurahan_ktp: formData.kelurahan_ktp,
      kelurahan_domisili: formData.kelurahan_ktp,
      kepemilikan_npwp: formData.kepemilikan_npwp,
      kode_pos_domisili: formData.kode_pos_ktp,
      kode_pos_ktp: formData.kode_pos_ktp,
      kota_domisili: formData.kota_ktp,
      kota_ktp: formData.kota_ktp,
      nama_bank: formData.nama_bank,
      nama_bank_pereferal: formData.nama_bank_pereferal,
      nama_pereferal: formData.nama_pereferal,
      negara_domisili: "Indonesia",
      negara_ktp: "Indonesia",
      no_ba: formData.no_ba,
      no_id_pereferal: formData.no_id_pereferal,
      no_ktp: formData.no_ktp,
      no_npwp: formData.no_npwp,
      no_rek_pereferal: formData.no_rek_pereferal,
      no_wa: formData.no_wa,
      no_wa_pereferal: formData.no_wa_pereferal,
      nomor_rekening: formData.nomor_rekening,
      pekerjaan: formData.pekerjaan,
      pendidikan_terakhir: formData.pendidikan_terakhir,
      pengiriman_paket: formData.pengiriman_paket,
      provinsi_domisili: formData.provinsi_ktp,
      provinsi_ktp: formData.provinsi_ktp,
      status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
      status_pereferal: formData.status_pereferal,
      status_pernikahan: formData.status_pernikahan,
      tanggal_lahir: formData.tanggal_lahir,
      tempat_lahir: formData.tempat_lahir,
      agama: formData.agama,
    };
  }

  try {
    const res = await axios({
      url: endpoint,
      method: "PATCH",
      data: myData,
      loading: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: types.PATCH_AOG_MOG_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert("Data Upgraded", "success"));
    history.push(`/inquiry-data`);
  } catch (error) {
    dispatch(setAlert("Something Went Wrong", "error"));
    dispatch({ type: types.PATCH_AOG_MOG_FAIL });
    console.log(error);
  }
};