import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Login, ResetPasswordLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Profile as ProfileView,
  DashboardCabang as DashboardCabangView,
  PaymentReceipt as PaymentReceiptView,
  PaymentHistory as PaymentHistoryView,
  RegistrationForm as RegistrationFormView,
  Branch as BranchView,
  Form as FormView,
  AogMog as AogMogView,
  PaymentReceiptShow as PaymentReceiptShowView,
  PaymentHistoryShow as PaymentHistoryShowView,
  FormChangePassword as FormChangePasswordView,
  UpdateForm as UpdateFormView,
  Help as HelpView,
  UpgradeMog as UpgradeMogView,
  FormUpgradeMog as FormUpgradeMogView,
  FormUpgradeMogAutoFill as FormUpgradeMogAutoFillView,
  PaymentHistoryUpgrade as PaymentHistoryUpgradeView,
  PaymentHistoryUpgradeShow as PaymentHistoryUpgradeShowView,
  PaymentReceiptUpgrade as PaymentReceiptUpgradeView,
  PaymentReceiptUpgradeShow as PaymentReceiptUpgradeShowView,
  ResetPassword as ResetPasswordView,
  LandingPageResetPassword as LandingPageResetPasswordView,
  StatisticMember as StatisticMemberView,
  CreateTarget as CreateTargetView,
  HistoryTarget as HistoryTargetView,
  InquiryData as InquiryDataView,
  FormInquiryData as FormInquiryDataView,
  GenerateID as GenerateIDView,
  IdAogMog as IdAogMogView,
  SettingWA as SettingWAView
} from './views';

import * as actions from './actions';
import { connect } from 'react-redux';

const Routes = props => {
  const {onCheckAuth, role} = props;
  useEffect(() => {
    onCheckAuth()
  }, [onCheckAuth]);

  function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  let routes = (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/sign-in"
      />
      <Route
        component={ResetPasswordView}
        exact
        layout={ResetPasswordLayout}
        path="/reset-password"
      />

      <Route
        component={LandingPageResetPasswordView}
        exact
        layout={ResetPasswordLayout}
        path="/reset-password/landing"
      />
      <Route
        component={Login}
        exact
        // layout={Login}
        path="/sign-in"
      />
      <Redirect to="/sign-in" />
    </Switch>
  )

  if(!isEmpty(role)){
    if(role.group_user.name === 'CABANG'){
      routes = (
        <Switch>
          
          <Redirect
              exact
              from="/"
              to="/dashboard"
            />
            <Route
              component={ResetPasswordView}
              exact
              layout={ResetPasswordLayout}
              path="/reset-password"
            />

            <Route
              component={LandingPageResetPasswordView}
              exact
              layout={ResetPasswordLayout}
              path="/reset-password/landing"
            />
            <RouteWithLayout
              component={DashboardCabangView}
              exact
              layout={MainLayout}
              path="/dashboard"
            />

            <RouteWithLayout
              component={IdAogMogView}
              exact
              layout={MainLayout}
              path="/id-aog-mog"
            />

            <RouteWithLayout
              component={ProfileView}
              exact
              layout={MainLayout}
              path="/profile"
            />

            <RouteWithLayout 
              component={UpgradeMogView}
              exact
              layout={MainLayout}
              path="/upgrade-mog"
            />

            <RouteWithLayout 
              component={FormUpgradeMogView}
              exact
              layout={MainLayout}
              path="/registrasion-form/upgrade-mog"
            />

            <RouteWithLayout 
              component={FormUpgradeMogAutoFillView}
              exact
              layout={MainLayout}
              path="/registrasion-form/upgrade-mog/:IDmog"
            />

            <RouteWithLayout
              component={FormChangePasswordView}
              exact
              layout={MainLayout}
              path="/change-password"
            />

            <RouteWithLayout
              component={FormView}
              exact
              layout={MainLayout}
              path="/registration-form/pendaftaran"
            />

            <RouteWithLayout
              component={RegistrationFormView}
              exact
              layout={MainLayout}
              path="/registration-form"
            />

            <RouteWithLayout
              component={UpdateFormView}
              exact
              layout={MainLayout}
              path="/registration-form-show"
            />

            <RouteWithLayout
              component={PaymentHistoryShowView}
              exact
              layout={MainLayout}
              path="/payment-history/:id"
            />

            <RouteWithLayout
              component={PaymentHistoryView}
              exact
              layout={MainLayout}
              path="/payment-history"
            />

            <RouteWithLayout
              component={PaymentHistoryUpgradeView}
              exact
              layout={MainLayout}
              path="/payment-history-upgrade"
            />

            <RouteWithLayout
              component={PaymentHistoryUpgradeShowView}
              exact
              layout={MainLayout}
              path="/payment-history-upgrade/:id"
            />

            <RouteWithLayout
              component={HelpView}
              exact
              layout={MainLayout}
              path="/help-center"
            />

            <Route
              component={Login}
              exact
              // layout={Login}
              path="/sign-in"
            />
            <Redirect to="/not-found" />
        </Switch>
      )
    }

    else if(role.group_user.name === 'SBS' ){
      routes = (
      
        <Switch>
          <Redirect
            exact
            from="/"
            to="/dashboard"
          />
          <Route
            component={ResetPasswordView}
            exact
            layout={ResetPasswordLayout}
            path="/reset-password"
          />

          <Route
            component={LandingPageResetPasswordView}
            exact
            layout={ResetPasswordLayout}
            path="/reset-password/landing"
          />
          <RouteWithLayout
            component={DashboardView}
            exact
            layout={MainLayout}
            path="/dashboard"
          />

          <RouteWithLayout
            component={StatisticMemberView}
            exact
            layout={MainLayout}
            path="/statistic-member"
          />

          <RouteWithLayout
            component={CreateTargetView}
            exact
            layout={MainLayout}
            path="/statistic-member/create-target"
          />

          <RouteWithLayout
            component={HistoryTargetView}
            exact
            layout={MainLayout}
            path="/statistic-member/history-target"
          />

          <RouteWithLayout
            component={ProfileView}
            exact
            layout={MainLayout}
            path="/profile"
          />

          <RouteWithLayout
            component={FormChangePasswordView}
            exact
            layout={MainLayout}
            path="/change-password"
          />

          <RouteWithLayout
            component={PaymentReceiptShowView}
            exact
            layout={MainLayout}
            path="/payment-receipt/:id"
          />

          <RouteWithLayout
            component={PaymentReceiptView}
            exact
            layout={MainLayout}
            path="/payment-receipt"
          />

          <RouteWithLayout
            component={PaymentReceiptUpgradeView}
            exact
            layout={MainLayout}
            path="/payment-receipt-upgrade"
          />

          <RouteWithLayout
            component={PaymentReceiptUpgradeShowView}
            exact
            layout={MainLayout}
            path="/payment-receipt-upgrade/:id"
          />

          <RouteWithLayout
            component={SettingWAView}
            exact
            layout={MainLayout}
            path="/setting-wa"
          />

          <RouteWithLayout
            component={GenerateIDView}
            layout={MainLayout}
            path="/generate-id"
            exact
          />

          <RouteWithLayout
            component={AogMogView}
            exact
            layout={MainLayout}
            path="/aog-mog"
          />

          <RouteWithLayout
            component={InquiryDataView}
            exact
            layout={MainLayout}
            path="/inquiry-data"
          />

          <RouteWithLayout
            component={FormInquiryDataView}
            exact
            layout={MainLayout}
            path="/inquiry-data/edit/:id"
          />

          <RouteWithLayout
            component={HelpView}
            exact
            layout={MainLayout}
            path="/help-center"
          />

          <Route
            component={Login}
            exact
            // layout={Login}
            path="/sign-in"
          />
          <Redirect to="/not-found" />
        </Switch>
        )
    }

    else if(role.group_user.name === 'RND'){
      routes = (
        <Switch>
          <Redirect
            exact
            from="/"
            to="/dashboard"
          />

          <Route
            component={ResetPasswordView}
            exact
            layout={ResetPasswordLayout}
            path="/reset-password"
          />

          <Route
            component={LandingPageResetPasswordView}
            exact
            layout={ResetPasswordLayout}
            path="/reset-password/landing"
          />

          <RouteWithLayout
            component={DashboardView}
            exact
            layout={MainLayout}
            path="/dashboard"
          />

          <RouteWithLayout
            component={ProfileView}
            exact
            layout={MainLayout}
            path="/profile"
          />

          <RouteWithLayout
            component={FormChangePasswordView}
            exact
            layout={MainLayout}
            path="/change-password"
          />

          <RouteWithLayout
            component={StatisticMemberView}
            exact
            layout={MainLayout}
            path="/statistic-member"
          />

          <RouteWithLayout
            component={HistoryTargetView}
            exact
            layout={MainLayout}
            path="/statistic-member/history-target"
          />

          <RouteWithLayout
            component={HelpView}
            exact
            layout={MainLayout}
            path="/help-center"
          />

          <Route
            component={Login}
            exact
            // layout={Login}
            path="/sign-in"
          />
          <Redirect to="/not-found" />
        </Switch>
      )
    }

    else if(role.group_user.name === 'ESS'){
      routes = (
        <Switch>
          <Redirect
              exact
              from="/"
              to="/dashboard"
            />
            <Route
              component={ResetPasswordView}
              exact
              layout={ResetPasswordLayout}
              path="/reset-password"
            />

            <Route
              component={LandingPageResetPasswordView}
              exact
              layout={ResetPasswordLayout}
              path="/reset-password/landing"
            />
            <RouteWithLayout
              component={DashboardView}
              exact
              layout={MainLayout}
              path="/dashboard"
            />

            <RouteWithLayout
              component={FormChangePasswordView}
              exact
              layout={MainLayout}
              path="/change-password"
            />

            <RouteWithLayout
              component={ProfileView}
              exact
              layout={MainLayout}
              path="/profile"
            />

            <RouteWithLayout
              component={AogMogView}
              exact
              layout={MainLayout}
              path="/aog-mog"
            />

            <RouteWithLayout
              component={GenerateIDView}
              layout={MainLayout}
              path="/generate-id"
              exact
            />

            <RouteWithLayout
              component={HelpView}
              exact
              layout={MainLayout}
              path="/help-center"
            />
            
            <Route
              component={Login}
              exact
              // layout={Login}
              path="/sign-in"
            />
            <Redirect to="/not-found" />
        </Switch>
      )
    }

    else if(role.group_user.name === 'CLUB'){
      routes = (
        <Switch>
          <Redirect
              exact
              from="/"
              to="/dashboard"
            />
            <Route
              component={ResetPasswordView}
              exact
              layout={ResetPasswordLayout}
              path="/reset-password"
            />

            <Route
              component={LandingPageResetPasswordView}
              exact
              layout={ResetPasswordLayout}
              path="/reset-password/landing"
            />
            <RouteWithLayout
              component={DashboardView}
              exact
              layout={MainLayout}
              path="/dashboard"
            />

            <RouteWithLayout
              component={BranchView}
              exact
              layout={MainLayout}
              path="/branch"
            />

            <RouteWithLayout
              component={FormChangePasswordView}
              exact
              layout={MainLayout}
              path="/change-password"
            />

            <RouteWithLayout
              component={ProfileView}
              exact
              layout={MainLayout}
              path="/profile"
            />

            <RouteWithLayout
              component={AogMogView}
              exact
              layout={MainLayout}
              path="/aog-mog"
            />

            <Route
              component={Login}
              exact
              // layout={Login}
              path="/sign-in"
            />
            <Redirect to="/not-found" />
        </Switch>
      )
    }
  }

  return routes;
};

const mapStateToProps = state => {
  return {
    role: state.login.userData,
    // isAuthenticated: state.login.userData !== 
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCheckAuth: () => dispatch(actions.authCheck())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
