import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_SEARCH_MOG_START,
  GET_SEARCH_MOG_SUCCESS,
  GET_SEARCH_MOG_FAIL,
  POST_FORM_UPGRADE_START,
  POST_FORM_UPGRADE_SUCCESS,
  POST_FORM_UPGRADE_FAIL,
  CLEAR_SEARCH_MOG,
  SET_UPGRADE_LIST,
} from "./types";

export const getSearchMogStart = () => {
  return {
    type: GET_SEARCH_MOG_START,
  };
};

export const getSearchMogFail = () => {
  return {
    type: GET_SEARCH_MOG_FAIL,
  };
};

export const getSearchMogSuccess = (data, total) => {
  return {
    type: GET_SEARCH_MOG_SUCCESS,
    data: data,
    total: total,
  };
};

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH_MOG,
  }
}

export const getSearchByName = (name, token) => async (dispatch) => {
  // let params;
  // if (isNaN(id)) {
  //   params = `nama_lengkap=${id}`;
  // } else {
  //   params = `no_id=${id}`;
  // }
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/register_aog_mog/search?nama_lengkap=${name}`;

  dispatch(getSearchMogStart());
  try {
    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getSearchMogSuccess(response.data));
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    dispatch(getSearchMogFail());
    console.log(error);
  }
};

export const getSearchByID = (id, token) => async (dispatch) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/register_aog_mog/search?no_id=${id}`;

  dispatch(getSearchMogStart());
  try {
    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getSearchMogSuccess(response.data));
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    dispatch(getSearchMogFail());
    console.log(error);
  }
};

export const sendForm = (formData, startDate, biayaTotal, history) => async (
  dispatch
) => {
  dispatch({ type: POST_FORM_UPGRADE_START });

  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/upgrade_aog_mog`;

  var myData = null;

  if (!formData.checked) {
    myData = {
      alamat_domisili: formData.alamat_domisili,
      alamat_ktp: formData.alamat_ktp,
      apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
      apakah_sudah_mengikuti_private_class:
        formData.apakah_sudah_mengikuti_private_class,
      biaya_total: 0,
      cabang_pembina: formData.cabang_pembina,
      email: formData.email,
      id_alumni: formData.id_alumni,
      jenis_kelamin: formData.jenis_kelamin,
      jenis_pendaftaran: formData.jenis_pendaftaran,
      kecamatan_domisili: formData.kecamatan_domisili,
      kecamatan_ktp: formData.kecamatan_ktp,
      kelurahan_ktp: formData.kelurahan_ktp,
      kelurahan_domisili: formData.kelurahan_domisili,
      kepemilikan_npwp: formData.kepemilikan_npwp,
      kode_pos_domisili: formData.kode_pos_domisili,
      kode_pos_ktp: formData.kode_pos_ktp,
      kota_domisili: formData.kota_domisili,
      kota_ktp: formData.kota_ktp,
      nama_bank: formData.nama_bank,
      nama_bank_pereferal: formData.nama_bank_pereferal,
      nama_lengkap: formData.nama_lengkap,
      nama_pereferal: formData.nama_pereferal,
      negara_domisili: "Indonesia",
      negara_ktp: "Indonesia",
      no_ba: formData.no_ba,
      no_id: formData.no_id,
      no_id_pereferal: formData.no_id_pereferal,
      no_ktp: formData.no_ktp,
      no_npwp: formData.no_npwp,
      no_rek_pereferal: formData.no_rek_pereferal,
      no_wa: formData.no_wa,
      no_wa_pereferal: formData.no_wa_pereferal,
      nomor_rekening: formData.nomor_rekening,
      pekerjaan: formData.pekerjaan,
      pendidikan_terakhir: formData.pendidikan_terakhir,
      pengiriman_paket: formData.pengiriman_paket,
      provinsi_domisili: formData.provinsi_domisili,
      provinsi_ktp: formData.provinsi_ktp,
      status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
      status_pereferal: formData.status_pereferal,
      status_pernikahan: formData.status_pernikahan,
      tanggal_lahir: startDate.submit.submit,
      tempat_lahir: formData.tempat_lahir,
      agama: formData.agama,
      no_id_lama: formData.no_id_lama,
      no_id_baru: formData.no_id,
      jenis_daftar_lama: "mog",
      jenis_daftar_baru: formData.jenis_pendaftaran,
      biaya_upgrade: biayaTotal,
    };
  } else {
    myData = {
      alamat_domisili: formData.alamat_ktp,
      alamat_ktp: formData.alamat_ktp,
      apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
      apakah_sudah_mengikuti_private_class:
        formData.apakah_sudah_mengikuti_private_class,
      biaya_total: 0,
      cabang_pembina: formData.cabang_pembina,
      email: formData.email,
      id_alumni: formData.id_alumni,
      jenis_kelamin: formData.jenis_kelamin,
      jenis_pendaftaran: formData.jenis_pendaftaran,
      kecamatan_domisili: formData.kecamatan_ktp,
      kecamatan_ktp: formData.kecamatan_ktp,
      kelurahan_ktp: formData.kelurahan_ktp,
      kelurahan_domisili: formData.kelurahan_ktp,
      kepemilikan_npwp: formData.kepemilikan_npwp,
      kode_pos_domisili: formData.kode_pos_ktp,
      kode_pos_ktp: formData.kode_pos_ktp,
      kota_domisili: formData.kota_ktp,
      kota_ktp: formData.kota_ktp,
      nama_bank: formData.nama_bank,
      nama_bank_pereferal: formData.nama_bank_pereferal,
      nama_lengkap: formData.nama_lengkap,
      nama_pereferal: formData.nama_pereferal,
      negara_domisili: "Indonesia",
      negara_ktp: "Indonesia",
      no_ba: formData.no_ba,
      no_id: formData.no_id,
      no_id_pereferal: formData.no_id_pereferal,
      no_ktp: formData.no_ktp,
      no_npwp: formData.no_npwp,
      no_rek_pereferal: formData.no_rek_pereferal,
      no_wa: formData.no_wa,
      no_wa_pereferal: formData.no_wa_pereferal,
      nomor_rekening: formData.nomor_rekening,
      pekerjaan: formData.pekerjaan,
      pendidikan_terakhir: formData.pendidikan_terakhir,
      pengiriman_paket: formData.pengiriman_paket,
      provinsi_domisili: formData.provinsi_ktp,
      provinsi_ktp: formData.provinsi_ktp,
      status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
      status_pereferal: formData.status_pereferal,
      status_pernikahan: formData.status_pernikahan,
      tanggal_lahir: startDate.submit.submit,
      tempat_lahir: formData.tempat_lahir,
      agama: formData.agama,
      no_id_lama: formData.no_id_lama,
      no_id_baru: formData.no_id,
      jenis_daftar_lama: "mog",
      jenis_daftar_baru: formData.jenis_pendaftaran,
      biaya_upgrade: biayaTotal,
    };
  }

  try {
    const res = await axios({
      url: endpoint,
      method: "POST",
      data: myData,
      loading: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    dispatch(clearSearch())
    dispatch({
      type: POST_FORM_UPGRADE_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert("MOG Upgraded", "success"));
    history.push(`/upgrade-mog`);
  } catch (error) {
    dispatch(setAlert("Something Went Wrong", "error"));
    dispatch({ type: POST_FORM_UPGRADE_FAIL });
    console.log(error);
  }
};

export const sendImage = (base64, item, amount, history) => async (
  dispatch
) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/upgrade_aog_mog/upload_bukti_pembayaran`;

  const id = item.map((value) => ({ id: value }));
  const myData = {
    image: base64,
    id_form: id,
    amount: amount,
  };

  dispatch({
    type: GET_SEARCH_MOG_START,
  });

  try {
    const res = await axios({
      url: endpoint,
      method: "POST",
      data: myData,
      loading: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: GET_SEARCH_MOG_FAIL,
      payload: res.data
    });
    dispatch({ type: CLEAR_SEARCH_MOG });
    dispatch(getRegister())

    dispatch(setAlert("Berhasil Upload Bukti Bayar", "success"));
    history.push(`/upgrade-mog`);
  } catch (error) {
    dispatch(
      setAlert(
        error.response.data.message,
        "error"
      )
    );
    dispatch({
      type: GET_SEARCH_MOG_FAIL,
    });
  }
};

export const patchUpgradeData = (
  formData,
  startDate,
  biayaTotal,
  history
) => async (dispatch) => {
  dispatch({ type: POST_FORM_UPGRADE_START });

  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/upgrade_aog_mog/${formData.id}`;

  var myData = null;

  if (!formData.checked) {
    myData = {
      alamat_domisili: formData.alamat_domisili,
      alamat_ktp: formData.alamat_ktp,
      apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
      apakah_sudah_mengikuti_private_class:
        formData.apakah_sudah_mengikuti_private_class,
      biaya_total: biayaTotal,
      cabang_pembina: formData.cabang_pembina,
      email: formData.email,
      id_alumni: formData.id_alumni,
      jenis_kelamin: formData.jenis_kelamin,
      jenis_pendaftaran: formData.jenis_pendaftaran,
      kecamatan_domisili: formData.kecamatan_domisili,
      kecamatan_ktp: formData.kecamatan_ktp,
      kelurahan_ktp: formData.kelurahan_ktp,
      kelurahan_domisili: formData.kelurahan_domisili,
      kepemilikan_npwp: formData.kepemilikan_npwp,
      kode_pos_domisili: formData.kode_pos_domisili,
      kode_pos_ktp: formData.kode_pos_ktp,
      kota_domisili: formData.kota_domisili,
      kota_ktp: formData.kota_ktp,
      nama_bank: formData.nama_bank,
      nama_bank_pereferal: formData.nama_bank_pereferal,
      nama_lengkap: formData.nama_lengkap,
      nama_pereferal: formData.nama_pereferal,
      negara_domisili: "Indonesia",
      negara_ktp: "Indonesia",
      no_ba: formData.no_ba,
      no_id: formData.no_id,
      no_id_pereferal: formData.no_id_pereferal,
      no_ktp: formData.no_ktp,
      no_npwp: formData.no_npwp,
      no_rek_pereferal: formData.no_rek_pereferal,
      no_wa: formData.no_wa,
      no_wa_pereferal: formData.no_wa_pereferal,
      nomor_rekening: formData.nomor_rekening,
      pekerjaan: formData.pekerjaan,
      pendidikan_terakhir: formData.pendidikan_terakhir,
      pengiriman_paket: formData.pengiriman_paket,
      provinsi_domisili: formData.provinsi_domisili,
      provinsi_ktp: formData.provinsi_ktp,
      status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
      status_pereferal: formData.status_pereferal,
      status_pernikahan: formData.status_pernikahan,
      tanggal_lahir: startDate.submit.submit,
      tempat_lahir: formData.tempat_lahir,
      agama: formData.agama,
      no_id_lama: formData.no_id_lama,
      no_id_baru: formData.no_id,
      jenis_daftar_lama: "mog",
      jenis_daftar_baru: formData.jenis_pendaftaran,
      biaya_upgrade: biayaTotal,
    };
  } else {
    myData = {
      alamat_domisili: formData.alamat_ktp,
      alamat_ktp: formData.alamat_ktp,
      apakah_anggota_koperasi: formData.apakah_anggota_koperasi,
      apakah_sudah_mengikuti_private_class:
        formData.apakah_sudah_mengikuti_private_class,
      biaya_total: biayaTotal,
      cabang_pembina: formData.cabang_pembina,
      email: formData.email,
      id_alumni: formData.id_alumni,
      jenis_kelamin: formData.jenis_kelamin,
      jenis_pendaftaran: formData.jenis_pendaftaran,
      kecamatan_domisili: formData.kecamatan_ktp,
      kecamatan_ktp: formData.kecamatan_ktp,
      kelurahan_ktp: formData.kelurahan_ktp,
      kelurahan_domisili: formData.kelurahan_ktp,
      kepemilikan_npwp: formData.kepemilikan_npwp,
      kode_pos_domisili: formData.kode_pos_ktp,
      kode_pos_ktp: formData.kode_pos_ktp,
      kota_domisili: formData.kota_ktp,
      kota_ktp: formData.kota_ktp,
      nama_bank: formData.nama_bank,
      nama_bank_pereferal: formData.nama_bank_pereferal,
      nama_lengkap: formData.nama_lengkap,
      nama_pereferal: formData.nama_pereferal,
      negara_domisili: "Indonesia",
      negara_ktp: "Indonesia",
      no_ba: formData.no_ba,
      no_id: formData.no_id,
      no_id_pereferal: formData.no_id_pereferal,
      no_ktp: formData.no_ktp,
      no_npwp: formData.no_npwp,
      no_rek_pereferal: formData.no_rek_pereferal,
      no_wa: formData.no_wa,
      no_wa_pereferal: formData.no_wa_pereferal,
      nomor_rekening: formData.nomor_rekening,
      pekerjaan: formData.pekerjaan,
      pendidikan_terakhir: formData.pendidikan_terakhir,
      pengiriman_paket: formData.pengiriman_paket,
      provinsi_domisili: formData.provinsi_ktp,
      provinsi_ktp: formData.provinsi_ktp,
      status_kepemilikan_rumah: formData.status_kepemilikan_rumah,
      status_pereferal: formData.status_pereferal,
      status_pernikahan: formData.status_pernikahan,
      tanggal_lahir: startDate.submit.submit,
      tempat_lahir: formData.tempat_lahir,
      agama: formData.agama,
      no_id_lama: formData.no_id_lama,
      no_id_baru: formData.no_id,
      jenis_daftar_lama: "mog",
      jenis_daftar_baru: formData.jenis_pendaftaran,
      biaya_upgrade: biayaTotal,
    };
  }

  try {
    const res = await axios({
      url: endpoint,
      method: "PATCH",
      data: myData,
      loading: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    dispatch(clearSearch())
    dispatch({
      type: POST_FORM_UPGRADE_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert("MOG Upgraded", "success"));
    history.push(`/upgrade-mog`);
  } catch (error) {
    dispatch(setAlert("Something Went Wrong", "error"));
    dispatch({ type: POST_FORM_UPGRADE_FAIL });
    console.log(error);
  }
};

export const setRegister = (data) => {
  return {
    type: SET_UPGRADE_LIST,
    payload: data,
  };
};

export const getRegister = () => async (dispatch) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/upgrade_aog_mog/index`;

  dispatch(getSearchMogStart());
  try {
    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
      },
    });

    dispatch(setRegister(response.data));
  } catch (error) {
    dispatch(getSearchMogFail());
  }
};
