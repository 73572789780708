import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
    //   flexGrow: 1,
      width: '100%',
    },
    card: {
        width: '100%'
    },
    appBar: {
        backgroundColor: '#FFFFFF',
        boxShadow: 'none'
    },
    text: {
      fontFamily: 'Nunito'
    },
    cardContent: {
      backgroundColor: '#F7F9FC'
    }
}));

const General = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Card>
                <CardHeader title="General" />
                <CardContent className={classes.cardContent}>

                </CardContent>
            </Card>
        </div>
    )
}

export default General