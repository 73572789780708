import * as types from './types';
import axios from '../axios-orders';
import { setAlert } from './alert';
import fileDownload from 'js-file-download';

export const exportAogMogRegisStart = () => {
  return {
    type: types.EXPORT_AOG_MOG_START
  }
}

export const exportAogMogRegisSuccess = (file) => {
  return {
    type: types.EXPORT_AOG_MOG_SUCCESS,
    file: file
  }
}

export const exportAogMogRegisFail = (error) => {
  return {
    type: types.EXPORT_AOG_MOG_FAIL,
    error: error
  }
}

export const exportAogMogRegis = (token, formSearch) => {
  let param = '?start_date='+formSearch.start_date+'&end_date='+formSearch.end_date+'&status='+formSearch.status;

  return dispatch => {
    dispatch(exportAogMogRegisStart())
    axios.get('/user/register_aog_mog/export_excel' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(res => {
        fileDownload(res.data, 'RegisterAogMog.xlsx')
        dispatch(exportAogMogRegisSuccess(res.data))
        dispatch(setAlert('download success', 'success'))
      })
      .catch(err => {
        dispatch(setAlert('download gagal', 'error'))
        dispatch(exportAogMogRegisFail(err))
      })
  }
}

export const fetchAogMogRegisStart = () => {
  return {
    type: types.FETCH_AOG_MOG_START
  }
}

export const fetchAogMogRegisSuccess = (aogMogRegis) => {
  return {
    type: types.FETCH_AOG_MOG_SUCCESS,
    aogMogRegis: aogMogRegis
  }
}

export const fetchAogMogRegisFail = (error) => {
  return {
    type: types.FETCH_AOG_MOG_FAIL,
    error: error
  }
}

export const fetchAogMogRegis = (token, formSearch, page) => {
  let param = '?start_date='+formSearch.start_date+'&end_date='+formSearch.end_date+'&status='+formSearch.status+'&page='+page;
  console.log(param)

  return dispatch => {
    dispatch(fetchAogMogRegisStart())
    axios.get('/user/register_aog_mog/search_by_date_v2' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        dispatch(fetchAogMogRegisSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchAogMogRegisFail(err))
      })
  }
}