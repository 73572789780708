import * as yup from "yup";
const phoneRegExp = /(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/
const schema = yup.object().shape({
	checked: yup.boolean(),
	nama_lengkap: yup.string().required("Nama tidak boleh kosong"),
	jenis_pendaftaran: yup.string().required("Jenis Pendaftaran tidak boleh kosong"),
	tempat_lahir: yup.string().required("Tempat Lahir tidak boleh kosong"),
	jenis_kelamin: yup.string().required("Jenis Kelamin tidak boleh kosong"),
	no_ktp: yup.string().required("Nomor KTP tidak boleh kosong"),
	no_id_lama: yup.string().required("Nomor ID AOG/MOG Lama tidak boleh kosong"),
	no_id: yup.string().required("Nomor ID AOG/MOG tidak boleh kosong"),
	email: yup.string().email("Format Email salah").required("Email tidak boleh kosong"),
	nomor_rekening: yup.string().required("Nomor Rekening tidak boleh kosong"),
	nama_bank: yup.string().required("Nama Bank tidak boleh kosong"),
	no_wa: yup
		.string()
		.required("Nomor Whatsapp tidak boleh kosong")
		.matches(phoneRegExp, 'Format salah'),
	no_wa_pereferal: yup
		.string()
		.required("Nomor Whatsapp tidak boleh kosong")
		.matches(phoneRegExp, 'Format salah'),
	pendidikan_terakhir: yup.string().required("Pendidikan Terakhir tidak boleh kosong"),
	status_pernikahan: yup.string().required("Status Pernikahan tidak boleh kosong"),
	pekerjaan: yup.string().required("Pekerjaan tidak boleh kosong"),
	status_kepemilikan_rumah: yup.string().required("Status Kepemilikan Rumah tidak boleh kosong"),
	kepemilikan_npwp: yup.string().required("Kepemilikan NPWP tidak boleh kosong"),
	apakah_anggota_koperasi: yup.string().required("Apakah Anggota Koperasi tidak boleh kosong"),
	agama: yup.string().required("Agama tidak boleh kosong"),
	alamat_ktp: yup.string().required("Alamat KTP tidak boleh kosong"),
	apakah_sudah_mengikuti_private_class: yup
		.string()
		.when("jenis_pendaftaran", {
			is: 'AOG',
			then: yup.string().required("Apakah sudah mengikuti private class tidak boleh kosong"),
		}),
	alamat_domisili: yup
		.string()
		.when("checked", {
			is: false,
			then: yup.string().required("Alamat Domisili tidak boleh kosong"),
		}),
	kode_pos_ktp: yup.string().required("Kode Pos KTP tidak boleh kosong"),
	kode_pos_domisili: yup
		.string()
		.when("checked", {
			is: false,
			then: yup.string().required("Kode Pos Domisili tidak boleh kosong"),
		}),
	// provinsi_ktp: yup.string().required("Provinsi KTP tidak boleh kosong"),
	nama_pereferal: yup.string().required("Nama Pereferal tidak boleh kosong"),
	no_id_pereferal: yup.string().required("No ID AOG/Cabang Pereferal tidak boleh kosong"),
	no_rek_pereferal: yup.string().required("Nomor Rekening Pereferal tidak boleh kosong"),
	nama_bank_pereferal: yup.string().required("Nama Bank Pereferal tidak boleh kosong"),
	// status: yup.string().required("Tipe Customer tidak boleh kosong"),
	// is_active: yup.string().required("Status Customer tidak boleh kosong"),
});

export default schema;