import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  type: {
    padding: theme.spacing(0.5, 1),
    borderRadius: "2px",
    width: "50%",
  },
  aog: {
    backgroundColor: "#46C057",
  },
  mog: {
    backgroundColor: "#56AFF6",
  },
  textType: {
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const TableComponent = ({ data, selected, setSelected, sort, setSort }) => {
  const classes = useStyles();

  const handleSelectAllClick = (event) => {
    if (selected.length === 0) {
      const newSelecteds = data.list.data.map((n) =>
        n.branch === null ? n.generate_id : null
      );
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={
                selected.length > 0 && selected.length < data.list.data.length
              }
              checked={
                data.list.data.length > 0 &&
                selected.length === data.list.data.length
              }
              onChange={handleSelectAllClick}
              disabled={data.list.data.length === 0}
            />
          </TableCell>
          <TableCell>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>No ID</Typography>
              <Tooltip
                title={sort === "asc" ? "Ascending" : "Descending"}
                placement="right"
              >
                <IconButton
                  onClick={() => {
                    sort === "asc" ? setSort("desc") : setSort("asc");
                  }}
                >
                  <SwapVertIcon />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Cabang</TableCell>
          <TableCell>Tipe</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.loading ? (
          <TableRow>
            <TableCell colSpan={5} align="center">
              <CircularProgress color="primary" />
            </TableCell>
          </TableRow>
        ) : (
          data.list.data.map((value) => {
            const isItemSelected = isSelected(value.generate_id);

            return (
              <TableRow key={value.generate_id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    disabled={value.branch !== null}
                    onClick={(event) => handleClick(event, value.generate_id)}
                  />
                </TableCell>
                <TableCell>{value.generate_id}</TableCell>
                <TableCell>
                  {value.branch === null ? "Tersedia" : "Sudah digunakan"}
                </TableCell>
                <TableCell>{value.branch?.name || "Belum diassign"}</TableCell>
                <TableCell>
                  <div
                    className={clsx(
                      classes.type,
                      value.type === "aog" && classes.aog,
                      value.type === "mog" && classes.mog
                    )}
                  >
                    <Typography className={classes.textType}>
                      {value.type}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        )}
        {data.list.data.length === 0 && !data.loading && (
          <TableRow>
            <TableCell colSpan={5} align="center">
              Data tidak tersedia
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableComponent;
