import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import {
    TextField,
    Grid,
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Select from 'react-select';

import { connect } from 'react-redux'
import { getProvinceDomisili, getCitiesDomisili, getDistrictDomisili } from '../../../../../actions/province'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0)
    },
    textInput: {
        width: '100%'
    },
    card: {
        marginTop: theme.spacing(2),
        overflow: 'visible'
    },
}))

const DomisiliAddress = (props) => {
    const classes = useStyles()
    const { 
        register, 
        handleChange, 
        formState, 
        setFormState,
        errors,
        getProvinceDomisili, 
        getCitiesDomisili, 
        getDistrictDomisili,
        province : { 
            provinceDomisili, 
            cityDomisili, 
            districtDomisili, 
            loadingProvinceDomisili, 
            loadingCityDomisili, 
            loadingDistrictDomisili 
        } 
    } = props

    const onProvinceChange = (event) => {
        setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [event.name]: event.value.name
            }
        }));
        getCitiesDomisili(event.value.id)
    }

    const onCityChange = event => {
        setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [event.name]: event.value.name
            }
        }));
        getDistrictDomisili(event.value.id)
    }

    const onDistrictChange = event => {
        setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [event.name]: event.value.name
            }
        }));
    }

    const optionsLoading = [{ 'value' : 'loading', 'label' : 'Loading'}];
    const provinceDomisiliOptions = [];
    if(provinceDomisili != null){
        for (let i = 0; i < provinceDomisili.length; i++) {
            provinceDomisiliOptions.push({'value' : provinceDomisili[i], 'label' : provinceDomisili[i].name, 'name' : 'provinsi_domisili'});
        }
    }

    const cityDomisiliOptions = [];
    if(cityDomisili != null){
        for (let i = 0; i < cityDomisili.length; i++) {
            cityDomisiliOptions.push({'value' : cityDomisili[i], 'label' : cityDomisili[i].name, 'name' : 'kota_domisili'});
        }
    }

    const districtDomisiliOptions = [];
    if(districtDomisili != null){
        for (let i = 0; i < districtDomisili.length; i++) {
            districtDomisiliOptions.push({'value' : districtDomisili[i], 'label' : districtDomisili[i].name, 'name' : 'kecamatan_domisili'});
        }
    }

    useEffect(() => {
        getProvinceDomisili()
    }, [getProvinceDomisili, loadingProvinceDomisili])

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardHeader title="Alamat Domisili" />
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid 
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <TextField 
                                label="Alamat Lengkap (Alamat Saat ini)"
                                className={classes.textInput}
                                name="alamat_domisili"
                                defaultValue={formState.values.alamat_domisili || ''}
                                onChange={handleChange}
                                variant="filled"
                                helperText={
                                    errors.alamat_domisili && errors.alamat_domisili.message
                                }
                                error={errors.alamat_domisili && true}
                                inputRef={register}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid 
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            {!loadingProvinceDomisili || provinceDomisili != null ? (
                                <Select 
                                    className={classes.select} 
                                    options={provinceDomisiliOptions} 
                                    onChange={onProvinceChange} 
                                    placeholder="Pilih Provinsi"
                                />
                            ):(
                                <Skeleton variant="rect"></Skeleton>
                            )}
                        </Grid>
                        <Grid 
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            {!loadingCityDomisili || cityDomisili != null ? (
                                <Select 
                                    className={classes.select} 
                                    options={cityDomisiliOptions} 
                                    onChange={onCityChange} 
                                    placeholder="Pilih Kota/Kabupaten"
                                />
                            ):(
                                <Select 
                                    disabled
                                    className={classes.select} 
                                    options={optionsLoading} 
                                    onChange={onCityChange} 
                                    placeholder="Pilih Kota/Kabupaten"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid 
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            {!loadingDistrictDomisili || districtDomisili != null ? (
                                <Select 
                                    className={classes.select} 
                                    options={districtDomisiliOptions} 
                                    onChange={onDistrictChange} 
                                    placeholder="Pilih Kecamatan"
                                />
                            ):(
                                <Select 
                                    disabled
                                    className={classes.select} 
                                    options={optionsLoading} 
                                    onChange={onDistrictChange} 
                                    placeholder="Pilih Kecamatan"
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <TextField 
                                label="Kode Pos"
                                className={classes.textInput}
                                name="kode_pos_domisili"
                                defaultValue={formState.values.kode_pos_domisili || ''}
                                onChange={handleChange}
                                variant="filled"
                                helperText={
                                    errors.kode_pos_domisili && errors.kode_pos_domisili.message
                                }
                                error={errors.alamat && true}
                                inputRef={register}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2}>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                        >
                            <TextField 
                                label="Negara"
                                className={classes.textInput}
                                name="negara_domisili"
                                defaultValue={formState.values.negara_domisili || ''}
                                onChange={handleChange}
                                variant="filled"
                                helperText={
                                    errors.negara_domisili && errors.negara_domisili.message
                                }
                                error={errors.alamat && true}
                                inputRef={register}
                                select
                            >
                                <MenuItem value="indonesia">
                                    Indonesia
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid> */}
                </CardContent>
            </Card>
        </div>
    )
}

const mapStateToProps = state => ({
    province: state.province
})

export default connect(mapStateToProps, { getProvinceDomisili, getCitiesDomisili, getDistrictDomisili })(DomisiliAddress)