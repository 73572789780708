import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { Skeleton } from '@material-ui/lab'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import {
  Button,
  Tooltip
} from '@material-ui/core'

import { connect } from 'react-redux'
import { getForm } from '../../../actions/form'
import { Grid } from '@material-ui/core';
import { getLimitUploadBukti } from '../../../actions/form'

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'tanggal_pendaftaran', numeric: false, disablePadding: true, label: 'Tanggal Pendaftaran' },
  { id: 'nama', numeric: true, disablePadding: false, label: 'Nama Lengkap' },
  { id: 'jenis', numeric: true, disablePadding: false, label: 'Jenis Pendaftaran' },
  { id: 'cabang', numeric: true, disablePadding: false, label: 'Cabang Pembina' },
  { id: 'nama_pereferal', numeric: true, disablePadding: false, label: 'Nama Pereferal' },
  { id: 'biaya_Total', numeric: true, disablePadding: false, label: 'Total Biaya' },
  // { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    // flex: '1 1 100%',
    display : 'block'
  },
  subtitle: {
    // flex: '1 1 100%',
    color: 'red',
    fontSize: '12px'
  },
  button: {
    marginRight: theme.spacing(2)
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { 
    numSelected, 
    loadingCount, 
    countUpload,
    dateToUTC,
    startTime,
    endTime,
    getDays,
    handleClickOpen,
    handleOpenDialog
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {/* {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <> */}
          <Grid container spacing={2} justify="space-between">
            <Grid item>
              <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                List Registration
              </Typography>
            </Grid>
            <Grid>
              {!loadingCount && (
                  <>
                    {numSelected > 0 ? (
                      <>
                        {countUpload.count < 2  ? (
                            <>
                                {dateToUTC >= startTime && dateToUTC < endTime && getDays < 6 ? (
                                    <Button className={classes.button} variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                                        Upload Bukti Bayar
                                    </Button>
                                ):(
                                    <Tooltip title="Sudah melewati batas waktu" placement="bottom" onClick={handleOpenDialog} arrow>
                                        <Button className={classes.button} variant="outlined" color="primary" size="small">
                                            Upload Bukti Bayar
                                        </Button>
                                    </Tooltip>
                                )}
                            </>
                        ):(
                            <Tooltip title="Upload bukti pembayaran sudah mencapai limit" onClick={handleOpenDialog} placement="bottom" arrow>
                                <Button className={classes.button} variant="outlined" color="primary" size="small">
                                    Upload Bukti Bayar
                                </Button>
                            </Tooltip>
                        )}
                      </>
                    ):(
                      <Tooltip
                        title="Silahkan Checklist Data dibawah terlebih dahulu"
                        onClick={handleOpenDialog}
                        placement="bottom"
                        arrow
                      >
                        <Button variant="outlined" disabled color="primary" size="small">
                          Upload Bukti Bayar
                        </Button>
                      </Tooltip>
                    )}
                  </>
              )}
            </Grid>
          </Grid>
{/*           
        </>
      )} */}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 10
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const RegistrationList = props => {
  const classes = useStyles();
  const { 
    getForm, 
    getLimitUploadBukti,
    form : { 
      formList, 
      loadingFormList,
      loadingCount,
      countUpload
    }, 
    selected, 
    setSelected,
    dateToUTC,
    startTime,
    endTime,
    getDays,
    handleClickOpen,
    handleOpenDialog
  } = props
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalArray, setTotalArray] = React.useState([{id: -1, total: 0}])
  const [total, setTotal] = React.useState(0)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && formList !== null && !loadingFormList && formList.register.length !== 0) {
      const newSelecteds = formList.register.map((n) => n.id);
      const newArrayTotal = formList.register.map((x) => ({id: x.id, total: parseInt(x.biaya_total)}))
      const totalSum = newArrayTotal.map(value => value.total).reduce((a,b) => a + b)
        setSelected(newSelecteds);
        setTotal(totalSum)
        setTotalArray(newArrayTotal)
      return;
    }
    setSelected([]);
    setTotal(0)
    setTotalArray([{id: -1, total: 0}])
  };

  useEffect(() => {
    getForm()
    getLimitUploadBukti()
  }, [getForm, loadingFormList, getLimitUploadBukti])

  const handleClick = (event, name, biaya_total) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const totalIndexSelected = totalArray.findIndex(x => x.id === name);
    let newSelectedTotal = [];

    if (totalIndexSelected === -1) {
      newSelectedTotal = newSelectedTotal.concat(totalArray, {id: name, total: parseInt(biaya_total)});
    } else if (totalIndexSelected === 0) {
      newSelectedTotal = newSelectedTotal.concat(totalArray.slice(1));
    } else if (totalIndexSelected === totalArray.length - 1) {
      newSelectedTotal = newSelectedTotal.concat(totalArray.slice(0, -1));
    } else if (totalIndexSelected > 0) {
      newSelectedTotal = newSelectedTotal.concat(
        totalArray.slice(0, totalIndexSelected),
        totalArray.slice(totalIndexSelected + 1),
      );
    }

    let totalSum = 0
    if (newSelectedTotal.length !== 0) {
      totalSum = newSelectedTotal.map(value => value.total).reduce((a,b) => a + b)
    }

    setTotal(totalSum)
    setTotalArray(newSelectedTotal)
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar 
          numSelected={selected.length} 
          loadingCount={loadingCount}
          countUpload={countUpload}
          dateToUTC={dateToUTC}
          startTime={startTime}
          endTime={endTime}
          getDays={getDays}
          handleClickOpen={handleClickOpen}
          handleOpenDialog={handleOpenDialog}
        />
        {formList !== null || !loadingFormList ? (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                
                <>
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={formList.register.length}
                    />
                    <TableBody>
                      {stableSort(formList.register, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.id, row.biaya_total)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                {moment(row.created_at).format('DD MMMM YYYY HH:mm')}
                              </TableCell>
                              <TableCell align="right">{row.nama_lengkap}</TableCell>
                              <TableCell align="right">{row.jenis_pendaftaran}</TableCell>
                              <TableCell align="right">{row.cabang_pembina}</TableCell>
                              <TableCell align="right">{row.nama_pereferal}</TableCell>
                              <TableCell align="right">
                                <NumberFormat value={row.biaya_total} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell colsPan={5}>
                          <Typography variant="h4">Total</Typography>
                        </TableCell>
                        <TableCell colsPan={2}>
                          <Typography variant="h4" alrigh="right">
                            <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                </>
                
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={formList.register.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />  
          </>
        ):(
          <Skeleton variant="rect"></Skeleton>
        )}
      </Paper>
    </div>
  );
}

const mapStateToProps = state => ({
  form : state.form
})

export default connect(mapStateToProps, { getForm, getLimitUploadBukti })(RegistrationList)