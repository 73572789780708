import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Info as InfoIcon } from '@material-ui/icons'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  content: {
    paddingTop: 20,
  },
  root: {
    // minWidth: 275,
    borderRadius: 8,
    color: '#231E2E'
  },
  icon: {
    textAlign: 'right'
  },
  title: {
    fontSize: 15,
    paddingBottom: 15,
    fontFamily: 'Nunito Sans, Roboto, sans-serif'
  },
  value: {
    fontSize: 25,
    color: '#FF9300'
  }
})

const CardComponent = props => {
  const { nameCard, countPending } = props;
  
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container>
            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography className={classes.title} gutterBottom> {nameCard} </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <InfoIcon fontSize="small" />
            </Grid>
          </Grid>
          <Typography className={classes.value}>
            {countPending}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default CardComponent;
