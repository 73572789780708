import { combineReducers } from 'redux'
import alert from './alerts'
import login from './login'
import dashboard from './dashboard'
import profile from './profile'
import paymentReceipt from './paymentReceipt'
import paymentReceiptUpgrade from './paymentReceiptUpgrade'
import paymentHistory from './paymentHistory'
import paymentHistoryUpgrade from './paymentHistoryUpgrade'
import province from './province'
import branch from './branch'
import form from './form'
import aogMogRegis from './aogMogRegis'
import config from './config'
import upgradeMog from './updateMog'
import statisticMembership from './statisticMembership'
import inquiryData from './inquiryData'
import generateID from './generateID'
import settingWa from "./settingWa"

export default combineReducers({
    alert,
    login,
    dashboard,
    profile,
    paymentReceipt,
    province,
    branch,
    form,
    aogMogRegis,
    paymentHistory,
    config,
    upgradeMog,
    paymentHistoryUpgrade,
    paymentReceiptUpgrade,
    statisticMembership,
    inquiryData,
    generateID,
    settingWa
})