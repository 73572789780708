import * as types from '../actions/types';
import { updateObject } from '../shared/utility';

const initialState = {
  counts: {},
  countsCabang: {},
  loading: false,
  error: {}
};

const fetchDashboardStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchDashboardSuccess = (state, action) => {
  return updateObject(state, {
    counts: action.counts,
    loading: false
  })
}

const fetchDashboardFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const fetchDashboardCabangStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchDashboardCabangSuccess = (state, action) => {
  return updateObject(state, {
    countsCabang: action.countsCabang,
    loading: false
  })
}

const fetchDashboardCabangFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_START: return fetchDashboardStart(state, action)
    case types.FETCH_DASHBOARD_SUCCESS: return fetchDashboardSuccess(state, action)
    case types.FETCH_DASHBOARD_FAIL: return fetchDashboardFail(state, action)
    case types.FETCH_DASHBOARD_CABANG_START: return fetchDashboardCabangStart(state, action)
    case types.FETCH_DASHBOARD_CABANG_SUCCESS: return fetchDashboardCabangSuccess(state, action)
    case types.FETCH_DASHBOARD_CABANG_FAIL: return fetchDashboardCabangFail(state, action)
    default: return state
  }
}

export default reducer