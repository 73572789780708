import React, { Fragment, forwardRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Typography,
    Grid,
    AppBar,
    Toolbar,
    Button,
    Card,
    CardContent
} from '@material-ui/core'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
	  height: '100vh'
    },
    rootAppbar: {
        flexGrow: 1,
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
    appBarBackground: {
        backgroundColor: '#FFFFFF'
    },
	image: {
	  backgroundColor: '#011747',
	},
	paper: {
	  margin: theme.spacing(8, 4),
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center'
	},
	textBody: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
	},
	form: {
	  width: '100%', // Fix IE 11 issue.
	  marginTop: theme.spacing(1),
	  paddingTop: 30
	},
	textField: {
	  [`& fieldset`]: {
		borderRadius: 100,
	  },
	},
	button: {
	  margin: theme.spacing(3, 0, 2),
	  backgroundColor: '#FF9300',
	  '&:hover': {
		backgroundColor: '#ef8b03'
	  },
	  color: '#FFFFFF',
    borderRadius: 100,
    width: '100%'
  },
	contentName: {
	  // padding: '200px 20px 0px',
	  padding: theme.spacing(25, 6)
	},
	center: {
	  color: '#FFFFFF',
	  fontSize: 50,
	  paddingBottom: 15
	},
	center2: {
	  color: '#FFFFFF',
	  fontSize: 60
    },
    title: {
        flexGrow: 1,
    },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <Link {...props} />
  </div>
));

const LandingPage = props =>  {
    const classes = useStyles()
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    
    return (
    <Fragment>
        <div className={classes.rootAppbar}>
            <AppBar position="fixed" className={classes.appBarBackground}>
                <Toolbar>
                    <div className={classes.title}>
                        <img src={`${process.env.PUBLIC_URL}/images/logo/logo_eoa.png`} alt="logo-gold" width="5%" height="auto" />
                    </div>
                    <Link to='/sign-in'>
                        <Typography variant="h4">
                            Login
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
        </div>
        <div className={classes.root}>
            <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} elevation={6} square>
                  {status === 'success' ? (
                    <Card>
                      <CardContent>
                        <div className={classes.paper}>
                          <div container>
                              <div item lg={12} sm={12} md={12} xs={12}>
                                  <center>
                                      <img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="logo-gold" width="50%" height="auto" />
                                  </center>
                              </div>
                          </div>
                          <Typography variant="h3" className={classes.textBody}>
                            Ubah Kata Sandi Berhasil
                          </Typography>
                          <Typography variant="h5" className={classes.textBody}>
                            Kata sandi berhasil diubah. <br/>
                            Cek email untuk melihat kata sandi baru Anda.
                          </Typography>
                          <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                component={CustomRouterLink}
                                className={classes.button}
                                to='/sign-in'
                              >
                                Oke
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </CardContent>
                    </Card>
                  ):(
                    <Card>
                      <CardContent>
                        <div className={classes.paper}>
                          <div container>
                              <div item lg={12} sm={12} md={12} xs={12}>
                                  <center>
                                      <img src={`${process.env.PUBLIC_URL}/images/fail.png`} alt="logo-gold" width="50%" height="auto" />
                                  </center>
                              </div>
                          </div>
                          <Typography variant="h3" className={classes.textBody}>
                            Gagal Ubah Kata Sandi
                          </Typography>
                          <Typography variant="h5" className={classes.textBody}>
                            Link pengaturan ulang kata sandi tidak valid, <br/>
                            dikarenakan sudah pernah digunakan. <br/>
                            Harap mengulang dengan kata sandi baru.
                          </Typography>
                          <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                component={CustomRouterLink}
                                className={classes.button}
                                to='/sign-in'
                              >
                                Oke
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
            </Grid>
        </div>
    </Fragment>
    )
}

export default LandingPage