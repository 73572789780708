import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import RegisterAogMog from './RegisterAogMog/RegisterAogMog';
import TablePaginationsArrayActions from '../../../../../../components/UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  bgColor: {
    backgroundColor: '#BCE0FD',
    height: '312px',
    position: 'absolute',
    // zIndex: 0
  },
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  searchSelectRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    borderRadius: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}))

const columns = [
  { id: 'tanggal_pendaftaran', label: 'Tanggal Pendaftaran' },
  { id: 'nama_lengkap', label: 'Nama Lengkap' },
  { id: 'jenis_pendaftaran', label: 'Jenis Pendaftaran' },
  { id: 'cabang_pembina', label: 'Cabang Pembina' },
  { id: 'nama_pereferal', label: 'Nama Pereferal' },
  { id: 'total_biaya', label: 'Total Biaya' },
]

const ShowPaymentReceipt = props => {
  const { paymentReceiptShow } = props;
  const classes = useStyles();
  
  function isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  

  let tableBody = '';
  if(!isEmpty(paymentReceiptShow)){
    console.log(paymentReceiptShow.history_upgrade.length)
    const countRows = paymentReceiptShow.history_upgrade.length;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage);
    tableBody = (   
      <Fragment>
        <TableBody>
          {paymentReceiptShow.history_upgrade.map((registerAogMog, index) => (
            <RegisterAogMog
              registerAogMog={registerAogMog}
              key={registerAogMog.id}
              index={index}
            />
          )).reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          }
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={5}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationsArrayActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }
  
  return (
    <Fragment>
      {/* <div className={classes.root}> */}
      <div className={classes.bgColor}></div>
      
      <Grid container spacing={2}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Grid>
      {/* </div> */}
    </Fragment>
  );
};

export default ShowPaymentReceipt;