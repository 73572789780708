import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Detail = props => {
  const { paymentReceipt } = props;

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Link to={{pathname: paymentReceipt.image}} target="_blank">
              <img src={paymentReceipt.image} alt="aset" width="100%" height="auto"/>
              </Link>
              
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              {paymentReceipt.inv_name}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Detail;