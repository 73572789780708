import React, { Fragment } from 'react'
import { Chip, IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { Edit as EditIcon, Search as SearchIcon } from '@material-ui/icons'
import palette from '../../../../../theme/palette';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  chipError: {
    color : palette.error.main,
    border: '1px solid'+palette.error.main,
    width: 150
  },
  chipSuccess: {
    color : palette.success.main,
    border: '1px solid'+palette.success.main,
    width: 150
  },
  chipWarning: {
    color : palette.warning.main,
    border: '1px solid'+palette.warning.main,
    width: 150
  },
  chipBlur: {
    color : '#707070',
    border: '1px solid #707070',
    width: 150
  },
  chipBlue: {
    color : '#0277BD',
    border: '1px solid #0277BD',
    width: 150
  },
  chipBlack: {
    color: '#231E2E',
    border: '1px solid #231E2E',
    width: 150
  },
  manajemenAset: {
    color: theme.palette.warning.light,
  }, 
  btnEdit: {
    color: theme.palette.success.main,
  }
}))

const PaymentReceipt = props => {
  const { paymentReceipt, edited } = props;

  const classes = useStyles();
  let status = '-';
  if(paymentReceipt.status === '0') {
    status = <Chip variant="outlined" size="medium" className={classes.chipBlur} label="Menunggu Konfirmasi" />;
  }else if(paymentReceipt.status === '1'){
    status = <Chip variant="outlined" size="medium" className={classes.chipSuccess} label="Terkonfirmasi" />;
  }else if(paymentReceipt.status === '2'){
    status = <Chip variant="outlined" size="medium" className={classes.chipWarning} label="Tidak Disetujui" />;
  }else{
    status = '-';
  }

  return (
    <Fragment>
      <TableRow key={paymentReceipt.id}>
        <TableCell>
          {paymentReceipt.inv_name}
        </TableCell>
        <TableCell>
          {status}
        </TableCell>
        <TableCell>
          {paymentReceipt.count_aog}
        </TableCell>
        <TableCell>
          {paymentReceipt.count_mog}
        </TableCell>
        <TableCell>
          {moment(paymentReceipt.created_at).parseZone().format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>
          <NumberFormat thousandSeparator={'.'} prefix={'Rp.'} decimalSeparator={','} displayType={'text'} value={paymentReceipt.total_biaya}/>
        </TableCell>
        <TableCell>
          <Tooltip title="Konfirmasi Payment Receipt">
            <IconButton aria-label="edit" onClick={edited}>
              <EditIcon className={classes.btnEdit}/>
            </IconButton>
          </Tooltip>

          <Tooltip title="Detail Payment Receipt">
            <NavLink to={'/payment-receipt-upgrade/' + paymentReceipt.id}>
              <IconButton aria-label="show">
                <SearchIcon className={classes.btnEdit} />
              </IconButton>
            </NavLink>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default PaymentReceipt;
