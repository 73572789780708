import * as types from '../actions/types';
import { updateObject } from '../shared/utility';

const initialState = {
  configTime: {
    open: "10",
    closed: "16"
  },
  loading: false,
  error: {},
}

const getConfigTimeStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const getConfigTimeSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    configTime: action.configTime
  })
}

const getConfigTimeFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONFIG_TIME_START: return getConfigTimeStart(state, action)
    case types.GET_CONFIG_TIME_SUCCESS: return getConfigTimeSuccess(state, action)
    case types.GET_CONFIG_TIME_FAIL: return getConfigTimeFail(state, action)
    default: return state
  }
}

export default reducer