import { SET_MESSAGE, SETTING_WA_LOAD, SETTING_WA_FINISH } from "./types";
import axios from "../axios-orders";
import { setAlert } from "./alert";

export const sendMessageWa = (data) => async (dispatch) => {
  try {
    await axios.post("/user/message/notification", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("Message notifikasi berhasil di set", "success"));
    dispatch(getMessageWa(data.action_on, data.type, data.status));
  } catch (error) {
    dispatch(setAlert("something wen't wrong", "error"));
  }
};

export const getMessageWa = (action_on, type, status) => async (dispatch) => {
  dispatch({ type: SETTING_WA_LOAD });
  try {
    const response = await axios.get(
      `/user/message/notification?status=${status}&type=${type}&action_on=${action_on}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
        },
      }
    );
    if (response.data.data !== null) {
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          action_on: action_on,
          type: type,
          status: status,
          message: "",
          auto_send: false,
        },
      });
    }
    dispatch({ type: SETTING_WA_FINISH });
  } catch (error) {
    dispatch(setAlert("something wen't wrong", "error"));
    dispatch({ type: SETTING_WA_FINISH });
  }
};
