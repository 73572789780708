import React, { Fragment, useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import * as actions from '../../../../actions';
import { connect } from 'react-redux';
// import RegisterAogMog from './RegisterAogMog/RegisterAogMog';
import Loading from '../../../../components/UI/Loading/Loading';
import AogMog from './AogMog/AogMog';
import TablePaginationsArrayActions from '../../../../components/UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  searchSelectRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    borderRadius: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}))

const columns = [
  { id: 'tanggal_pendaftaran', label: 'Tanggal Pendaftaran' },
  { id: 'tanggal_upgrade', label: 'Tanggal Upgrade' },
  { id: 'nama_lengkap', label: 'Nama Lengkap' },
  { id: 'jenis_pendaftaran', label: 'Jenis Pendaftaran' },
  { id: 'proses', label: 'Proses Pendaftaran' },
  { id: 'ppa', label: 'Sudah mengikuti PPA?' },
  { id: 'cabang_pembina', label: 'Cabang Pembina' },
  { id: 'action', label: 'Nama Pereferal' },
]

const ListAogMog = props => {

  const { formSearch, aogMog, onFetchAogMog, loading } = props;
  const classes = useStyles();

  const token = sessionStorage.getItem('access_token');

  function isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const countRows = aogMog.total;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage);

  useEffect(() => {
    onFetchAogMog(token, formSearch, page+1)
  }, [token, formSearch, onFetchAogMog, page])

  let tableBody = '';
  if(!isEmpty(aogMog)){
    tableBody = (   
      <Fragment>
        <TableBody>
          {aogMog.data.map((registerAogMog, index) => (
            <AogMog
              registerAogMog={registerAogMog}
              key={registerAogMog.id}
              index={index}
            />
          )).reverse()
          }
          {emptyRows > 0 && (
            <TableRow>
              <TableCell colSpan={8} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={5}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationsArrayActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (loading?
    <Loading/>
    :
    <Fragment>
      <Grid container spacing={2}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    aogMog: state.aogMogRegis.aogMogRegis,
    loading: state.aogMogRegis.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchAogMog: (token, formSearch, page) => dispatch(actions.fetchAogMogRegis(token, formSearch, page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListAogMog);