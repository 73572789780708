import {
    GET_PROVINCE, 
    GET_CITY, 
    GET_DISTRICT,
    GET_PROVINCE_DOMISILI, 
    GET_CITY_DOMISILI, 
    GET_DISTRICT_DOMISILI,
    GET_VILLAGE,
    GET_VILLAGE_DOMISILI
} from '../actions/types'

const initialState = {
    province : null,
    city : null,
    district : null,
    village: null,
    province_domisili : null,
    cityDomisili : null,
    districtDomisili : null,
    villageDomisili: null,
    loadingProvinceDomisili: true,
    loadingCityDomisili: true,
    loadingDistrictDomisili: true,
    loadingProvince: true,
    loadingCity: true,
    loadingDistrict: true,
    loadingVillage: true,
    loadingVillageDomisili: true,
    error: {}
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PROVINCE:
            return {
                ...state,
                province: payload,
                loadingProvince: false
            }
        case GET_CITY:
            return {
                ...state,
                city: payload,
                loadingCity: false
            }
        case GET_DISTRICT:
            return {
                ...state,
                district: payload,
                loadingDistrict: false
            }
        case GET_PROVINCE_DOMISILI:
            return {
                ...state,
                provinceDomisili: payload,
                loadingProvinceDomisili: false
            }
        case GET_CITY_DOMISILI:
            return {
                ...state,
                cityDomisili: payload,
                loadingCityDomisili: false
            }
        case GET_DISTRICT_DOMISILI:
            return {
                ...state,
                districtDomisili: payload,
                loadingDistrictDomisili: false
            }
        case GET_VILLAGE_DOMISILI:
            return {
                ...state,
                villageDomisili: payload,
                loadingVillageDomisili: false
            }
        case GET_VILLAGE:
            return {
                ...state,
                village: payload,
                loadingVillage: false
            }
        default:
            return state
    }
}

export default reducer;