import { FormControl, Grid, InputLabel, makeStyles, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../actions';
import TablePaginationActions from '../../../../components/UI/Table/TablePagination/TablePaginationActions';
import PaymentReceipt from './PaymentReceipt/PaymentReceipt';
import Loading from '../../../../components/UI/Loading/Loading';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 500
  },
  topTable: {
    padding: 16,
    backgroundColor: '#FAFAFA'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
    // marginTop: theme.spacing(2)
  },
  searchSelectRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#FF9300',
    '&:hover': {
      backgroundColor: '#FF9300'
    },
    color: '#FFFFFF',
    borderRadius: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  formRoot: {
    // border: '1px groove #000',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 3)
  }
}))

const columns = [
  { id: 'inv_name', label: 'No Invoice' },
  { id: 'status', label: 'Status' },
  { id: 'jumlah_aog', label: 'Jumlah Aog' },
  { id: 'jumlah mog', label: 'Jumlah Mog' },
  { id: 'tgl_upload', label: 'Tanggal Upload' },
  { id: 'total_biaya', label: 'Total Pembayaran' },
  { id: 'action', label: 'Action' }
]

const ListPaymentReceipts = props => {
  const classes = useStyles();

  const [formSearch, setFormSearch] = useState({
    status: 0,
    date: '',
  })

  const access_token = sessionStorage.getItem('access_token');

  const { onFecthPaymentReceipt, paymentReceipts, changing, loading } = props;

  useEffect(() => {
    onFecthPaymentReceipt(1, access_token, formSearch);
  }, [onFecthPaymentReceipt, access_token, formSearch, changing]);

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  const handleChangePage = (event, newPage) => {
    onFecthPaymentReceipt(newPage, access_token, formSearch)
  }

  const handleSearch = event => {
    const target = event.target.name;
    event.persist()
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }))
  }

  let tableBody = '';

  if (!isEmpty(paymentReceipts)) {
    const page = paymentReceipts.current_page - 1
    const rowsPerPage = paymentReceipts.per_page
    const countRows = paymentReceipts.total
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)

    tableBody = (
      <Fragment>
        <TableBody>
          {paymentReceipts.paymentReceipts.map((paymentReceipt) => (
            <PaymentReceipt
              paymentReceipt={paymentReceipt}
              key={paymentReceipt.id}
              detailed={() => props.show(paymentReceipt.id)}
              edited={() => props.edit(paymentReceipt)}
            // deleted={() => {
            //   props.onCloseAlert()
            //   props.onDialogBox('Yakin ingin menghapus data Aset? ', asset, asset.uuid, actions.deleteAsetWakaf)
            // }}
            />
          ))}

          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[paymentReceipts.per_page]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (loading ?
    <Loading />
    :
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Typography variant="h6">Filter</Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper component="form" className={classes.searchSelectRoot}>
                <FormControl
                  // error={errors.status && true}
                  variant="outlined" className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                  <Select
                    native
                    value={formSearch.status}
                    onChange={handleSearch}
                    label="Pilih Status"
                    inputProps={{
                      name: 'status',
                      id: 'outlined-age-native-simple'
                    }}
                    name="status"
                  >
                    <option key={0} value=' '>Lihat Semua</option>
                    <option key={1} value={0}>Menunggu Konfirmasi</option>
                    <option key={2} value={1}>Terkonfirmasi</option>
                    <option key={3} value={2}>Tidak Disetujui</option>
                  </Select>
                  {/* <FormHelperText>{errors.status && errors.status.message}</FormHelperText> */}
                </FormControl>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <TableContainer component={Paper}>
          <div className={classes.topTable}>
            <Typography variant="h4">List Payment</Typography>
          </div>
          <hr />
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    paymentReceipts: state.paymentHistory.paymentReceipts,
    changing: state.paymentHistory.changing,
    loading: state.paymentHistory.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFecthPaymentReceipt: (page, token, formSearch) => dispatch(actions.fetchPaymentHistory(page, token, formSearch)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPaymentReceipts);