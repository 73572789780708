import * as types from './types';
import axios from '../axios-orders';
// import { setAlert } from './alert';

export const fetchDashboardStart = () => {
  return {
    type: types.FETCH_DASHBOARD_START
  }
}

export const fetchDashboardSuccess = (counts) => {
  return {
    type: types.FETCH_DASHBOARD_SUCCESS,
    counts: counts
  }
}

export const fetchDashboardFail = (error) => {
  return {
    type: types.FETCH_DASHBOARD_FAIL,
    error: error
  }
}

export const fetchDashboard = (token, formSearch) => {
  let param = 'start_date='+formSearch.start_date+'&end_date='+formSearch.end_date;

  return dispatch => {
    dispatch(fetchDashboardStart())
    axios.get('user/dashboard/count?' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        dispatch(fetchDashboardSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchDashboardFail(err))
      })
  }
}

export const fetchDashboardCabangStart = () => {
  return {
    type: types.FETCH_DASHBOARD_CABANG_START
  }
}

export const fetchDashboardCabangSuccess = (countsCabang) => {
  return {
    type: types.FETCH_DASHBOARD_CABANG_SUCCESS,
    countsCabang: countsCabang
  }
}

export const fetchDashboardCabangFail = (error) => {
  return {
    type: types.FETCH_DASHBOARD_CABANG_FAIL,
    error: error
  }
}

export const fetchDashboardCabang = (token, formSearch) => {
  let param = 'start_date='+formSearch.start_date+'&end_date='+formSearch.end_date;

  return dispatch => {
    dispatch(fetchDashboardCabangStart())
    axios.get('user/dashboard/count_cabang?' + param, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        dispatch(fetchDashboardCabangSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchDashboardCabangFail(err))
      })
  }
}