import React, { Fragment } from 'react'
import { TableCell, TableRow } from '@material-ui/core'


const AogMog = props => {
  const { registerAogMog } = props
  return (
    <Fragment>
      <TableRow key={registerAogMog.id}>
        <TableCell>
          {registerAogMog.tanggal_pendaftaran}
        </TableCell>
        <TableCell>
          {registerAogMog.history_upgrade !== null && (
            registerAogMog.history_upgrade.tanggal_upgrade
          )}
        </TableCell>
        <TableCell>
          {registerAogMog.nama_lengkap}
        </TableCell>
        <TableCell>
          {registerAogMog.jenis_pendaftaran}
        </TableCell>
        <TableCell>
          {registerAogMog.proses}
        </TableCell>
        <TableCell>
          {registerAogMog.apakah_sudah_mengikuti_private_class}
        </TableCell>
        <TableCell>
          {registerAogMog.cabang_pembina}
        </TableCell>
        <TableCell>
          {registerAogMog.nama_pereferal}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default AogMog;
