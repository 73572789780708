import * as types from '../actions/types';
import { updateObject } from '../shared/utility';

const initialState = {
  aogMogRegis: [],
  file: '',
  loading: false,
  error: {},
}

const exportAogMogStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const exportAogMogSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    file: action.file
  })
}

const exportAogMogFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const fetchAogMogStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const fetchAogMogSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    aogMogRegis: action.aogMogRegis
  })
}

const fetchAogMogFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EXPORT_AOG_MOG_START: return exportAogMogStart(state, action)
    case types.EXPORT_AOG_MOG_SUCCESS: return exportAogMogSuccess(state, action)
    case types.EXPORT_AOG_MOG_FAIL: return exportAogMogFail(state, action)
    case types.FETCH_AOG_MOG_START: return fetchAogMogStart(state, action)
    case types.FETCH_AOG_MOG_SUCCESS: return fetchAogMogSuccess(state, action)
    case types.FETCH_AOG_MOG_FAIL: return fetchAogMogFail(state, action)
    default: return state
  }
}

export default reducer